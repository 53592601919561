import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';
import { fg } from '@atlassian/jira-feature-gating';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { shouldHandleTheError } from '@atlassian/jira-business-error-handling/src/utils/partial-data/index.tsx';
import {
	SUBTASKS_TYPE,
	ISSUE_LINKS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { CARD_COVER, PAGES } from '../../common/constants.tsx';
import type {
	/* eslint-disable-next-line camelcase */
	JIS2JWMBoardItems_jira_jwmViewItems_JiraWorkManagementViewItemConnection_edges_node_fieldsByIdOrAlias,
	JIS2JWMBoardItems,
	/* eslint-disable-next-line camelcase */
	JIS2JWMBoardItems_jira_jwmViewItems_JiraWorkManagementViewItemConnection_edges_node,
	JIS2JWMBoardItemsVariables,
} from './__generated_apollo__/JIS2JWMBoardItems';
import { JIS2_JWMBoardItemsQuery } from './gql.tsx';

const ITEMS_PER_PAGE = 100;

export type IssueDetailsJIS2 =
	/* eslint-disable-next-line camelcase */
	JIS2JWMBoardItems_jira_jwmViewItems_JiraWorkManagementViewItemConnection_edges_node;

export type IssueDetailFieldJIS2 =
	/* eslint-disable-next-line camelcase */
	JIS2JWMBoardItems_jira_jwmViewItems_JiraWorkManagementViewItemConnection_edges_node_fieldsByIdOrAlias;

type FetchArguments = {
	jql: string;
	fieldIds: string[];
};

// throwaway code - delete when `board_issues_error_details` is cleaned up
const getErrorMessages = (errors: readonly GraphQLError[] | undefined) =>
	(errors || [])
		.map((error) => error.message)
		.filter(Boolean)
		.join(', ');

// throwaway code - delete when `board_issues_error_details` is cleaned up
const getErrorTypes = (errors: readonly GraphQLError[] | undefined) =>
	(errors || [])
		.map((error) => error.extensions?.errorType)
		.filter(Boolean)
		.join(',');

export const useFetchBoardIssues = () => {
	const client = useApolloClient();
	const cloudId = useCloudId();

	return useCallback(
		async ({ jql, fieldIds: fieldIdsInput }: FetchArguments): Promise<IssueDetailsJIS2[]> => {
			const fieldIds = new Set(fieldIdsInput);

			const withChildrenCount = fieldIds.has(SUBTASKS_TYPE);
			fieldIds.delete(SUBTASKS_TYPE);

			const withIssueLinks = fieldIds.has(ISSUE_LINKS_TYPE);
			fieldIds.delete(ISSUE_LINKS_TYPE);

			const withCoverMedia = fieldIds.has(CARD_COVER);
			fieldIds.delete(CARD_COVER);

			const withConfluenceLinks = fieldIds.has(PAGES);
			fieldIds.delete(PAGES);

			const variables: JIS2JWMBoardItemsVariables = {
				fieldIds: Array.from(fieldIds),
				first: ITEMS_PER_PAGE,
				jql,
				withConfluenceLinks,
				withCoverMedia,
				cloudId,
				withIssueLinks,
				withChildrenCount,
			};

			const response = await client.query<JIS2JWMBoardItems, JIS2JWMBoardItemsVariables>({
				query: JIS2_JWMBoardItemsQuery,
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
				variables,
			});

			if (!response) {
				throw new Error('No issue response');
			}

			if (response.errors) {
				const apolloError = new ApolloError({
					graphQLErrors: response.errors,
				});

				if (shouldHandleTheError(apolloError)) {
					throw apolloError;
				}
			}

			const responseData = response.data?.jira?.jwmViewItems;
			if (!responseData && fg('board_issues_error_details')) {
				log.safeErrorWithoutCustomerData(
					'board-issues.fetch-board-issues',
					`Error fetching board-issues (nullable jwmViewItems). Error details: ${getErrorMessages(response.errors)}`,
				);
				throw new Error(
					`Invalid issue response (nullable jwmViewItems). Error types (if any): ${getErrorTypes(response.errors)}`,
				);
			}

			if (
				responseData?.__typename === 'JiraWorkManagementViewItemConnection' &&
				responseData.edges != null
			) {
				return responseData.edges
					.map((edge) => edge?.node)
					.filter((node): node is IssueDetailsJIS2 => node != null);
			}

			if (fg('board_issues_error_details')) {
				if (responseData?.__typename !== 'JiraWorkManagementViewItemConnection') {
					log.safeErrorWithoutCustomerData(
						'board-issues.fetch-board-issues',
						`Error fetching board-issues (typename ${responseData?.__typename}). Error details: ${getErrorMessages(response.errors)}`,
					);
					throw new Error(`Invalid issue response (typename): ${responseData?.__typename}`);
				}
				if (responseData.edges == null) {
					log.safeErrorWithoutCustomerData(
						'board-issues.fetch-board-issues',
						`Error fetching board-issues (nullable edges). Error details: ${getErrorMessages(response.errors)}`,
					);
					throw new Error('Invalid issue response (nullable edges)');
				}
			}
			throw new Error('Invalid issue response');
		},
		[client, cloudId],
	);
};
