/**
 * @generated SignedSource<<5cfc4150a619253bbb8386ed3449782c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_project$data = {
  readonly projectId: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_project">;
  readonly " $fragmentType": "BoardContent_project";
};
export type BoardContent_project$key = {
  readonly " $data"?: BoardContent_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardContent_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_Inner_project"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "fdbab977793ec3ae94c294396e28e4e6";

export default node;
