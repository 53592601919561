/**
 * @generated SignedSource<<93744eeaec51d00e261ffce5324710a4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_project$data = {
  readonly canCreateIssues: {
    readonly canPerform: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_project" | "StatusColumnHeader_project">;
  readonly " $fragmentType": "BoardColumn_project";
};
export type BoardColumn_project$key = {
  readonly " $data"?: BoardColumn_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumn_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "canCreateIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "CREATE_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "canPerform"
            },
            "action": "THROW",
            "path": "canCreateIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"CREATE_ISSUES\")"
      },
      "action": "THROW",
      "path": "canCreateIssues"
    },
    {
      "kind": "FragmentSpread",
      "name": "StatusColumnHeader_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "d0866ea5902e81ead6917f3eba007c22";

export default node;
