import React from 'react';
import noop from 'lodash/noop';
import { Flex, xcss } from '@atlaskit/primitives';
import AssigneePicker from '@atlassian/jira-issue-field-assignee/src/ui/popover/edit-view/main.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ConfigureSource } from '@atlassian/jira-issue-view-common-types/src/inline-create-fields-type.tsx';

export const InlineAssigneePicker = ({
	projectKey,
	onAssigneeUpdate,
	defaultAssignee = null,
	shouldShowNameLabel = false,
	source,
	actionSubject = 'inlineAssignee',
	onToggleAssigneePicker = noop,
	strategy = 'fixed',
}: {
	projectKey: string;
	defaultAssignee?: UserValue;
	onAssigneeUpdate?: (updatedValue: UserValue) => void;
	shouldShowNameLabel?: boolean;
	source?: ConfigureSource;
	actionSubject?: string;
	onToggleAssigneePicker?: () => void;
	strategy?: 'absolute' | 'fixed';
}) => {
	const SEARCH_ASSIGNEE_ENDPOINT = `/rest/api/2/user/assignable/search?project=${projectKey}&query=`;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnChange = (updatedValue: UserValue) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'confirmed',
			actionSubject: { actionSubject },
		});

		onAssigneeUpdate?.(updatedValue);
		fireUIAnalytics(analyticsEvent, { calledFrom: source });
	};

	const handleOnOpen = (event?: React.MouseEvent<HTMLButtonElement>) => {
		// Prevent default is needed to prevent the form from being submitted when the user clicks on the assignee picker
		event?.preventDefault();
		onToggleAssigneePicker?.();
	};

	return (
		<Flex xcss={assigneeWrapperStyles}>
			<AssigneePicker
				autoCompleteUrl={SEARCH_ASSIGNEE_ENDPOINT}
				value={defaultAssignee}
				onChange={handleOnChange}
				popupPlacement="bottom-start"
				shouldShowNameLabel={shouldShowNameLabel}
				actionSubject={actionSubject}
				onOpen={handleOnOpen}
				onClose={onToggleAssigneePicker}
				strategy={strategy}
			/>
		</Flex>
	);
};

const assigneeWrapperStyles = xcss({
	alignItems: 'center',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});
