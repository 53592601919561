/**
 * @generated SignedSource<<482ad52789d7b307983ef09376cc911c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardHeaderDropdown_view$data = {
  readonly columns: {
    readonly totalCount: number;
  };
  readonly id: string;
  readonly " $fragmentType": "BoardHeaderDropdown_view";
};
export type BoardHeaderDropdown_view$key = {
  readonly " $data"?: BoardHeaderDropdown_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardHeaderDropdown_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraBoardViewColumnConnection",
        "kind": "LinkedField",
        "name": "columns",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "totalCount"
            },
            "action": "THROW",
            "path": "columns.totalCount"
          }
        ]
      },
      "action": "THROW",
      "path": "columns"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "7ac8289807bb5de01b64dfc566b21cb1";

export default node;
