import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setFiltersFailedTitle: {
		id: 'work-management-board.controllers.board-filters.set-filters-failed-title',
		defaultMessage: 'Unable to apply your filters',
		description: 'Error title informing the user applying board filters failed.',
	},
	setFiltersFailedMessage: {
		id: 'work-management-board.controllers.board-filters.set-filters-failed-message',
		defaultMessage: "We couldn't apply your filters, try refreshing the page or try again later.",
		description: 'Error message informing the user applying board filters failed.',
	},
	setFiltersValidationErrorTitle: {
		id: 'work-management-board.controllers.board-filters.set-filters-validation-error-title',
		defaultMessage: 'You cannot apply this filter',
		description:
			'Error title informing the user they cannot apply a filter due to a validation error.',
	},
});
