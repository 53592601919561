import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareTitle: {
		id: 'work-management-board.header.share-title',
		defaultMessage: 'Board',
		description:
			'Name of the entity type that is going to be shared to others, this will be used as email title for the share recipients',
	},
	searchBoardLabel: {
		id: 'work-management-board.header.search-board-label',
		defaultMessage: 'Search board',
		description:
			'Placeholder label for the search bar in the Board view used to search tasks via free text search',
	},
	viewSettingsButton: {
		id: 'work-management-board.header.view-settings-button',
		defaultMessage: 'View settings',
		description: 'View settings menu options tooltip text',
	},
	moreButtonOld: {
		id: 'work-management-board.header.more-button-old',
		defaultMessage: 'More',
		description: 'Label of the view settings menu trigger button',
	},
});
