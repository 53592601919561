/* eslint-disable jira/integration/test-id-by-folder-structure */
import React, { memo, useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { format } from 'date-fns';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import InlineCreateComponent, {
	type Props as InlineCreateProps,
} from '@atlassian/jira-business-issue-create/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import InlineCreateForm, {
	CARD_APPEARANCE,
} from '@atlassian/jira-business-issue-create/src/ui/inline-create-form/index.tsx';
import {
	ASSIGNEE_TYPE,
	SUMMARY_TYPE,
	STATUS_TYPE,
	PRIORITY_TYPE,
	ISSUE_TYPE,
	ISSUE_KEY_TYPE,
	DUE_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import InlineCreateTrigger from '@atlassian/jira-business-inline-create-trigger/src/ui/index.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import type { BoardInlineCreate_column$key } from '@atlassian/jira-relay/src/__generated__/BoardInlineCreate_column.graphql';
import { useIntl } from '@atlassian/jira-intl';
import type { AdditionalCreateIssueContext } from '@atlassian/jira-business-issue-create/src/types.tsx';
import {
	ASSIGNEE_ID,
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_DATE,
	BOARD_FIELD_ISSUE_TYPE,
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_STATUS,
	BOARD_FIELD_TEXT,
	BOARD_FIELD_USER,
	CATEGORY_ID,
	DUE_DATE_ID,
	ISSUE_KEY_ID,
	ISSUE_TYPE_ID,
	PRIORITY_ID,
	RANK_ID,
	STATUS_ID,
	SUMMARY_ID,
} from '../../../../common/constants.tsx';
import type { BoardIssue } from '../../../../common/types.tsx';
import { useTransformIssues } from '../../../../utils/issue-transformer/index.tsx';
import { useIssueStoreActions } from '../../../../controllers/board-issue-store/index.tsx';
import { useWorkflowStoreState } from '../../../../controllers/workflow-store/index.tsx';
import { useFieldIds } from '../../../../controllers/board-field-ids/BoardFieldIdsProvider.tsx';
import type { JWMBoardInlineCreateIssue_createJiraBusinessIssue_item as CreatedBoardItem } from '../../../../services/create-issue/__generated_apollo__/JWMBoardInlineCreateIssue.ts';
import messages from './messages.tsx';

const INLINE_CREATE_TRIGGER_OFFSET = 12;

const inlineCreateStyles: InlineCreateProps<CreatedBoardItem>['inlineCreateStyles'] = {
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
};

type InlineSiblingCreateProps = {
	isTopMost: boolean;
	isSiblingCreateFormOpen: boolean;
	issue: BoardIssue;
	toggleSiblingCreateForm: (issueId: number | null) => void;
};

export type Props = {
	columnFragment: BoardInlineCreate_column$key;
	scrollToLastElement?: () => void;
	inlineSiblingCreateProps?: InlineSiblingCreateProps;
	isInlineSiblingCreate?: boolean;
};

const BoardInlineCreate = ({
	columnFragment,
	scrollToLastElement,
	inlineSiblingCreateProps,
	isInlineSiblingCreate = false,
}: Props) => {
	const column = useFragment(
		graphql`
			fragment BoardInlineCreate_column on JiraBoardViewColumn {
				__typename @required(action: THROW)
				... on JiraBoardViewStatusColumn {
					statuses @required(action: THROW) {
						name @required(action: THROW)
						statusId @required(action: THROW)
						statusCategory @required(action: THROW) {
							statusCategoryId @required(action: THROW)
						}
					}
				}
				... on JiraBoardViewPriorityColumn {
					priority @required(action: THROW) {
						name @required(action: THROW)
						priorityId @required(action: THROW)
						iconUrl
					}
				}
				... on JiraBoardViewAssigneeColumn {
					user {
						name @required(action: THROW)
						accountId @required(action: THROW)
						picture
					}
				}
				... on JiraBoardViewCategoryColumn {
					category {
						value @required(action: THROW)
						optionId @required(action: THROW)
						color {
							colorKey
						}
					}
				}
			}
		`,
		columnFragment,
	);

	const { formatMessage } = useIntl();
	const { data: categoryField } = useCategoryField();
	const categoryFieldId = categoryField?.id;
	const { updateIssues, removeIssues } = useIssueStoreActions();
	const fieldIdsToReturn = useFieldIds();
	const transformToIssues = useTransformIssues();
	const { issueTypes } = useWorkflowStoreState();

	const onAddOptimisticIssue = useCallback(() => {
		// Only attempt to scroll to the last element after the optimistic issue is created
		scrollToLastElement && requestAnimationFrame(scrollToLastElement);
	}, [scrollToLastElement]);

	const allowedIssueTypeIds = useMemo(
		() => issueTypes.map((issueType) => issueType.id),
		[issueTypes],
	);

	const {
		isTopMost = false,
		isSiblingCreateFormOpen = false,
		issue,
		toggleSiblingCreateForm,
	} = inlineSiblingCreateProps ?? {};

	const prepareIssueContext = useCallback(() => {
		const issueContext: AdditionalCreateIssueContext = {};

		if (issue) {
			issueContext.rankBefore = {
				issueId: String(issue.id),
				rank: issue.fields[RANK_ID].value,
			};
		}

		switch (column.__typename) {
			case 'JiraBoardViewAssigneeColumn':
				if (!column.user) {
					return issueContext;
				}
				issueContext.assignee = {
					accountId: column.user.accountId,
					avatarUrl: column.user.picture,
					displayName: column.user.name,
				};
				break;
			case 'JiraBoardViewPriorityColumn':
				issueContext.priority = {
					iconUrl: column.priority.iconUrl ?? '',
					name: column.priority.name,
					priorityId: column.priority.priorityId,
				};
				break;
			case 'JiraBoardViewCategoryColumn':
				if (!column.category) {
					return issueContext;
				}
				issueContext.category = {
					color: column.category.color?.colorKey || '',
					optionId: column.category.optionId,
					value: column.category.value,
				};
				break;
			case 'JiraBoardViewStatusColumn':
				issueContext.status = {
					name: column.statuses[0]?.name ?? '',
					statusCategoryId: Number(column.statuses[0]?.statusCategory.statusCategoryId),
					statusId: column.statuses[0]?.statusId ?? '',
				};
				break;
			default:
				break;
		}

		return issueContext;
	}, [column, issue]);

	const handleSubmit: InlineCreateProps<CreatedBoardItem>['onSubmit'] = useCallback(
		// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
		// eslint-disable-next-line complexity
		async ({ optimisticIssuePayload, promise }) => {
			const optimisticIssue: BoardIssue = {
				id: optimisticIssuePayload.issueId,
				coverMedia: null,
				fields: {
					[ISSUE_TYPE_ID]: {
						id: optimisticIssuePayload.issueType.id,
						fieldId: ISSUE_TYPE,
						type: BOARD_FIELD_ISSUE_TYPE,
						issueType: {
							id: optimisticIssuePayload.issueType.id,
							name: optimisticIssuePayload.issueType.name,
							iconUrl: optimisticIssuePayload.issueType.avatarUrl ?? '',
							hierarchyLevel: optimisticIssuePayload.issueType.hierarchyLevel,
						},
					},
					[ISSUE_KEY_ID]: {
						fieldId: ISSUE_KEY_TYPE,
						type: BOARD_FIELD_TEXT,
						value: '',
					},
					[STATUS_ID]: {
						fieldId: STATUS_TYPE,
						status: {
							id: optimisticIssuePayload.status?.statusId ?? '',
							name: optimisticIssuePayload.status?.name ?? '',
							statusCategoryId: optimisticIssuePayload.status?.statusCategoryId ?? 2,
						},
						type: BOARD_FIELD_STATUS,
						value: optimisticIssuePayload.status?.statusId ?? '0',
					},
					[SUMMARY_ID]: {
						fieldId: SUMMARY_TYPE,
						type: BOARD_FIELD_TEXT,
						value: optimisticIssuePayload.summary,
					},
					[RANK_ID]: {
						fieldId: '',
						type: BOARD_FIELD_TEXT,
						value: optimisticIssuePayload.rank ?? 'z',
					},
				},
				subtaskMetadata: {
					done: 0,
					total: 0,
				},
			};

			if (optimisticIssuePayload.assignee) {
				optimisticIssue.fields[ASSIGNEE_ID] = {
					fieldId: ASSIGNEE_TYPE,
					type: BOARD_FIELD_USER,
					value: optimisticIssuePayload.assignee.accountId,
					user: {
						accountId: optimisticIssuePayload.assignee.accountId,
						avatarURL: optimisticIssuePayload.assignee.avatarUrl,
						name: optimisticIssuePayload.assignee.displayName,
					},
				};
			}

			if (optimisticIssuePayload.priority) {
				optimisticIssue.fields[PRIORITY_ID] = {
					fieldId: PRIORITY_TYPE,
					type: BOARD_FIELD_PRIORITY,
					value: optimisticIssuePayload.priority.priorityId,
					priority: {
						id: optimisticIssuePayload.priority.priorityId,
						iconUrl: optimisticIssuePayload.priority.iconUrl,
						name: optimisticIssuePayload.priority.name,
					},
				};
			}

			if (
				optimisticIssuePayload.category &&
				categoryFieldId &&
				column.__typename === 'JiraBoardViewCategoryColumn'
			) {
				optimisticIssue.fields[CATEGORY_ID] = {
					fieldId: categoryFieldId,
					type: BOARD_FIELD_CATEGORY,
					value: optimisticIssuePayload.category.optionId,
					category: {
						color: optimisticIssuePayload.category.color,
						name: optimisticIssuePayload.category.value,
					},
				};
			}

			if (
				optimisticIssuePayload.dueDate &&
				expVal('jira_powerful_inline_create_for_business_board', 'isEnabled', false)
			) {
				optimisticIssue.fields[DUE_DATE_ID] = {
					fieldId: DUE_DATE_TYPE,
					type: BOARD_FIELD_DATE,
					value: format(optimisticIssuePayload.dueDate, 'yyyy-MM-dd'),
				};
			}

			updateIssues([optimisticIssue]);

			onAddOptimisticIssue?.();

			let createdBoardItem: CreatedBoardItem | null | undefined;

			try {
				createdBoardItem = await promise;
			} catch (e) {
				// swallow error
			}

			if (createdBoardItem) {
				const issues = transformToIssues([createdBoardItem]);

				updateIssues(issues);
			}

			removeIssues([optimisticIssue.id]);
		},
		[categoryFieldId, updateIssues, onAddOptimisticIssue, removeIssues, column, transformToIssues],
	);

	const handleToggleForm = useCallback(
		(isFormVisible: boolean) => {
			if (isFormVisible) {
				scrollToLastElement?.();
			}
		},
		[scrollToLastElement],
	);

	// prevents onBlur from being called before the click event is finished
	const handleTriggerMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
	}, []);

	const openSiblingCreate = useCallback(
		() => issue?.id && toggleSiblingCreateForm?.(issue.id),
		[issue?.id, toggleSiblingCreateForm],
	);

	const closeSiblingCreate = useCallback(
		() => toggleSiblingCreateForm?.(null),
		[toggleSiblingCreateForm],
	);

	const shouldShowAdditionalFields = useCallback(
		() => expVal('jira_powerful_inline_create_for_business_board', 'isEnabled', false),
		[],
	);
	const groupedByContext = useMemo(() => {
		if (column.__typename !== 'JiraBoardViewAssigneeColumn' || !column.user) {
			return null;
		}
		return {
			fieldId: ASSIGNEE_TYPE,
			value: {
				accountId: column.user.accountId,
				avatarUrl: column.user.picture,
				displayName: column.user.name,
			},
		};
	}, [column]);

	if (isInlineSiblingCreate) {
		return isSiblingCreateFormOpen ? (
			<Box xcss={inlineFormWrapperStyles}>
				<InlineCreateForm
					allowedIssueTypeIds={allowedIssueTypeIds}
					appearance={CARD_APPEARANCE}
					fieldIdsToReturn={fieldIdsToReturn}
					onPrepareIssueContext={prepareIssueContext}
					onSubmit={handleSubmit}
					location="boardSibling"
					onBlur={closeSiblingCreate}
					shouldShowAdditionalFields={shouldShowAdditionalFields}
					group={groupedByContext}
				/>
			</Box>
		) : (
			<InlineCreateTrigger
				direction="row"
				onClick={openSiblingCreate}
				onMouseDown={handleTriggerMouseDown}
				label={formatMessage(messages.createSiblingIssue)}
				offset={INLINE_CREATE_TRIGGER_OFFSET}
				position={isTopMost ? 1 : -2}
			/>
		);
	}

	return (
		<Box xcss={wrapperStyles} testId="work-management-board.ui.board.column.footer">
			<InlineCreateComponent
				allowedIssueTypeIds={allowedIssueTypeIds}
				appearance={CARD_APPEARANCE}
				fieldIdsToReturn={fieldIdsToReturn}
				onPrepareIssueContext={prepareIssueContext}
				onSubmit={handleSubmit}
				location="boardFooter"
				inlineCreateStyles={inlineCreateStyles}
				onToggleForm={handleToggleForm}
				shouldShowAdditionalFields={shouldShowAdditionalFields}
				group={groupedByContext}
			/>
		</Box>
	);
};

const wrapperStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	paddingBottom: 'space.050',
});

const inlineFormWrapperStyles = xcss({
	marginBottom: 'space.050',
});

export default memo(BoardInlineCreate);
