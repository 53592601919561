/* eslint-disable @atlassian/relay/query-restriction */
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ReactNode,
} from 'react';
import xor from 'lodash/xor';
import { graphql, useFragment } from 'react-relay';
import {
	fetchWorkflowIssues,
	Unchanged,
	type WorkflowIssuesData,
} from '@atlassian/jira-business-board-workflow-issues/src/index.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import type { Workflow } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService, type FlagId } from '@atlassian/jira-flags';
import { useWorkflowIssues } from '@atlassian/jira-router-resources-business-board-workflow-issues/src/index.tsx';
import type { BoardDataProvider_view$key } from '@atlassian/jira-relay/src/__generated__/BoardDataProvider_view.graphql';
import type { BoardDataProvider_project$key } from '@atlassian/jira-relay/src/__generated__/BoardDataProvider_project.graphql';
import { ISSUE_TYPE_ID } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';
import { useFilter, useFilterStatus } from '../board-filters/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import { useIssues } from '../board-issue-store/selectors/index.tsx';
import { mapWorkflowIssuesToBoardIssues, sortIssuesBy } from '../board-issue-store/utils.tsx';
import { useRefetchIssuesWithThrow } from '../refetch-issues/index.tsx';
import { useWorkflowStoreState, useWorkflowStoreActions } from '../workflow-store/index.tsx';
import { useFieldIds } from '../board-field-ids/BoardFieldIdsProvider.tsx';
import messages from './messages.tsx';

export const useViewItemId = createRouterSelector(
	(state) => state.match?.params?.itemId ?? undefined,
);

const FETCH_FAILED_FLAG_ID: FlagId = 'FETCH_BOARD_DATA_FAILED';

const getActiveWorkflow = (workflows: Workflow[], activeWorkflowId?: string): Workflow => {
	const activeWorkflow =
		workflows.find((workflow) => workflow.workflowId === activeWorkflowId) ?? workflows[0];

	return activeWorkflow;
};

type RefetchFunction = () => Promise<void>;
type ResetFunction = () => void;
type Context = {
	filteredIssues: BoardIssue[];
	refetch: RefetchFunction;
	reset: ResetFunction;
	loading: boolean;
	error?: Error | null;
};

const Context = createContext<Context | null>(null);

export const BoardDataProvider = ({
	projectFragment,
	viewFragment,
	children,
}: {
	projectFragment: BoardDataProvider_project$key;
	viewFragment: BoardDataProvider_view$key;
	children: ReactNode;
}) => {
	const { data, loading: isWorkflowLoading, error } = useWorkflowIssues();
	const itemId = useViewItemId();

	const project = useFragment(
		graphql`
			fragment BoardDataProvider_project on JiraProject {
				key @required(action: THROW)
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BoardDataProvider_view on JiraBoardView {
				selectedWorkflowId @required(action: THROW)
			}
		`,
		viewFragment,
	);

	const activeWorkflowId = view?.selectedWorkflowId;
	const activeWorkflowIdRef = useRef<string>(activeWorkflowId);

	const { workflowIssuesLastChangedTime } = useWorkflowStoreState();
	const { setFetchResponse } = useWorkflowStoreActions();
	const { showFlag, dismissFlag } = useFlagsService();
	const { updateIssues, reset: resetIssueStore } = useIssueStoreActions();
	const refetchIssuesWithThrow = useRefetchIssuesWithThrow();
	const [initialized, setInitialized] = useState(false);
	const fetchedFields = useRef<string[]>([]);
	const filterIssues = useFilter();
	const { isLoading: isLoadingFilters } = useFilterStatus();

	const allIssues = useIssues();
	const fieldIds = useFieldIds();

	const filteredIssues = useMemo(() => {
		const withoutSubtasks = Array.from(allIssues.values()).filter(
			(issue) => issue.fields[ISSUE_TYPE_ID].issueType.hierarchyLevel >= 0,
		);
		const issues = filterIssues(withoutSubtasks);

		return sortIssuesBy('rank', issues);
	}, [allIssues, filterIssues]);

	const reset = useCallback(() => {
		resetIssueStore();
		setInitialized(false);
		fetchedFields.current = [];
	}, [resetIssueStore]);

	useEffect(() => {
		if (activeWorkflowId !== activeWorkflowIdRef.current) {
			activeWorkflowIdRef.current = activeWorkflowId;

			reset();
		}
	}, [activeWorkflowId, reset]);

	const showErrorFlag = useCallback(() => {
		showFlag({
			id: FETCH_FAILED_FLAG_ID,
			type: 'error',
			title: messages.boardInitErrorTitle,
			description: messages.boardInitErrorMessage,
			messageId: 'work-management-board.controllers.board-data.show-flag.error',
			messageType: 'transactional',
		});
	}, [showFlag]);

	const handleStatusNotFoundForIssues = useCallback(
		(statusNotFoundCounts: Map<number, number>) => {
			fireErrorAnalytics({
				meta: {
					id: 'mapWorkflowIssuesToBoardIssues',
					packageName: 'jiraWorkManagementBoard',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Status not found for issues',
					statusNotFoundCounts: Array.from(statusNotFoundCounts, ([statusId, count]) => ({
						statusId,
						count,
					})),
				},
				sendToPrivacyUnsafeSplunk: true,
			});

			showFlag({
				type: 'warning',
				title: messages.boardStatusNotFoundWarningFlagTitle,
				description: messages.boardStatusNotFoundWarningFlagDescription,
				messageId: 'work-management-board.controllers.board-data.show-flag.warning',
				messageType: 'transactional',
				actions: [
					{
						content: messages.boardStatusNotFoundWarningFlagAction,
						href: `/issues/?jql=project%20%3D%20"${project.key}"`,
					},
				],
			});
		},
		[project.key, showFlag],
	);

	useEffect(() => {
		if (!initialized) {
			if (data) {
				setInitialized(true);

				const activeWorkflowData = getActiveWorkflow(data.workflows, activeWorkflowId);

				if (!activeWorkflowData) {
					return;
				}

				const mappedIssues = mapWorkflowIssuesToBoardIssues(
					activeWorkflowData,
					handleStatusNotFoundForIssues,
				);
				updateIssues(mappedIssues);

				setFetchResponse({
					activeWorkflow: activeWorkflowData,
					workflows: data.workflows,
					workflowIssuesLastChangedTime: data.lastChangedTime || undefined,
				});
			} else if (error) {
				setInitialized(true);
				showErrorFlag();
			}
		}
	}, [
		activeWorkflowId,
		data,
		error,
		initialized,
		setFetchResponse,
		updateIssues,
		showErrorFlag,
		handleStatusNotFoundForIssues,
	]);

	useEffect(() => {
		// if the list of fields changes, refetch the issues
		if (
			initialized &&
			!isLoadingFilters &&
			fieldIds.length > 0 &&
			xor(fieldIds, fetchedFields.current).length > 0 &&
			allIssues.size > 0
		) {
			fetchedFields.current = fieldIds;
			refetchIssuesWithThrow(Array.from(allIssues.keys())).catch(showErrorFlag);
		}
	}, [fieldIds, initialized, isLoadingFilters, allIssues, refetchIssuesWithThrow, showErrorFlag]);

	const refetch = useCallback(async () => {
		try {
			const response: WorkflowIssuesData = await fetchWorkflowIssues({
				projectKey: project.key,
				itemId,
				ifChangedSince: workflowIssuesLastChangedTime,
			});

			const activeWorkflowData = getActiveWorkflow(response.workflows, activeWorkflowId);

			if (!activeWorkflowData) {
				return;
			}

			await refetchIssuesWithThrow(
				activeWorkflowData.issues.map((issue) => issue.id),
				true,
			);

			setFetchResponse({
				activeWorkflow: activeWorkflowData,
				workflows: response.workflows,
				workflowIssuesLastChangedTime: response.lastChangedTime || undefined,
			});
			dismissFlag(FETCH_FAILED_FLAG_ID);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (refetchError: any) {
			if (refetchError instanceof Unchanged) {
				dismissFlag(FETCH_FAILED_FLAG_ID);
				return;
			}

			fireErrorAnalytics({
				meta: {
					id: 'refetchBoardData',
					packageName: 'jiraWorkManagementBoard',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to refetch workflow issues data',
				},
				error: refetchError,
				sendToPrivacyUnsafeSplunk: true,
			});
			showErrorFlag();
		}
	}, [
		project.key,
		itemId,
		workflowIssuesLastChangedTime,
		activeWorkflowId,
		refetchIssuesWithThrow,
		setFetchResponse,
		dismissFlag,
		showErrorFlag,
	]);

	// only mark as loading when the page initialises but not when applying filters thereafter
	const [loading, setLoading] = useState(isWorkflowLoading || isLoadingFilters);
	useEffect(() => {
		if (loading && !isWorkflowLoading && !isLoadingFilters) {
			setLoading(false);
		}
	}, [loading, isWorkflowLoading, isLoadingFilters]);

	const value = useMemo(
		() => ({
			filteredIssues,
			refetch,
			reset,
			// we want to show loading state for server side rendering
			loading: __SERVER__ ? true : loading,
			error,
		}),
		[filteredIssues, refetch, reset, loading, error],
	);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useBoardData = (): Context => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useBoardData must be used within a BoardDataProvider');
	}

	return context;
};
