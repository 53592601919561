import { type RefObject, useEffect, useState } from 'react';
import {
	type Edge,
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	type ElementDropTargetEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { isOptimisticIssue } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/IssueCreateProvider.tsx';
import { CARD_DND_TYPE } from '../../../common/constants.tsx';
import type {
	DraggableCardData,
	BoardIssue,
	Group,
	DraggableState,
} from '../../../common/types.tsx';
import {
	useCanDropCard,
	useStartCardDrag,
	useUpdateDropTarget,
} from '../card-drag-drop-context/index.tsx';
import {
	isCard,
	isClosestEdgeAdjacentToSource as isClosestEdgeAdjacentToSourceFn,
} from '../utils/index.tsx';
import { useHasDragPermission } from './utils.tsx';

export type CardDragMeta = {
	cardIndex: number;
	closestEdge: Edge;
};

export type OnCardDragChange = (meta: CardDragMeta | null) => void;

type Props = {
	ref: RefObject<HTMLElement>;
	issue: BoardIssue;
	cardIndex: number;
	column: Group;
	onCardDragChange: OnCardDragChange;
};

export const useDraggableCard = ({ ref, issue, cardIndex, column, onCardDragChange }: Props) => {
	const startCardDrag = useStartCardDrag();
	const { canDropAgainstCard } = useCanDropCard(column);
	const [dragState, setDragState] = useState<DraggableState>({ type: 'idle' });
	const hasDragPermission = useHasDragPermission(column.type);

	const updateDropTarget = useUpdateDropTarget();

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		const handleDrag = ({ source, self }: ElementDropTargetEventBasePayload) => {
			const sourceData = source.data;

			if (!isCard(sourceData)) {
				return;
			}

			if (sourceData.issueId === issue.id) {
				return;
			}

			const destinationData = self.data;
			const closestEdge = extractClosestEdge(destinationData);
			const isWithinSameColumn = sourceData.columnId === destinationData.columnId;

			const isClosestEdgeAdjacentToSource =
				isCard(destinationData) &&
				isWithinSameColumn &&
				closestEdge != null &&
				isClosestEdgeAdjacentToSourceFn(
					sourceData.cardIndex,
					destinationData.cardIndex,
					closestEdge,
				);

			onCardDragChange(
				closestEdge && !isClosestEdgeAdjacentToSource ? { cardIndex, closestEdge } : null,
			);

			if (isCard(destinationData) && isWithinSameColumn && canDropAgainstCard === 'yes') {
				updateDropTarget({
					group: column,
				});
			}
		};

		return combine(
			draggable({
				element: ref.current,
				canDrag: () => hasDragPermission && !isOptimisticIssue(issue.id),
				getInitialData: (): DraggableCardData => ({
					type: CARD_DND_TYPE,
					issueId: issue.id,
					cardIndex,
					columnId: column.id,
				}),
				onGenerateDragPreview: ({ nativeSetDragImage, location, source }) => {
					setCustomNativeDragPreview({
						getOffset: preserveOffsetOnSource({
							element: source.element,
							input: location.current.input,
						}),
						render({ container }) {
							setDragState({ type: 'preview', container });
							return () => setDragState({ type: 'idle' });
						},
						nativeSetDragImage,
					});
				},
				onDragStart: () => {
					startCardDrag(issue, column);
				},
				onDrop: () => {
					setDragState({ type: 'idle' });
				},
			}),
			dropTargetForElements({
				element: ref.current,
				canDrop: ({ source }) => isCard(source.data) && canDropAgainstCard === 'yes',
				getIsSticky: () => true,
				getData: ({ input, element }) => {
					const data: DraggableCardData = {
						type: CARD_DND_TYPE,
						issueId: issue.id,
						cardIndex,
						columnId: column.id,
					};

					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDragEnter: handleDrag,
				onDrag: handleDrag,
				onDragLeave: () => {
					onCardDragChange(null);
				},
				onDrop: () => {
					onCardDragChange(null);
				},
			}),
		);
	}, [
		canDropAgainstCard,
		cardIndex,
		column,
		hasDragPermission,
		issue,
		onCardDragChange,
		ref,
		startCardDrag,
		updateDropTarget,
	]);

	return { dragState };
};
