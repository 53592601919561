/**
 * @generated SignedSource<<93c69d7651d1674280ea3114fbd5af3b>>
 * @relayHash ee20361209187dcb1ca8f7dc7a6f8bce
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7a0dabf330570ad11ebc82d813a718ac8968cf166d6999b5ea58731573cca67d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSetBoardViewColumnStateInput = {
  collapsed: boolean;
  columnId: string;
  settings?: JiraBoardViewSettings | null | undefined;
  viewId: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type BoardColumnCollapsedStateMutation$variables = {
  input: JiraSetBoardViewColumnStateInput;
};
export type BoardColumnCollapsedStateMutation$data = {
  readonly jira_setBoardViewColumnState: {
    readonly boardView: {
      readonly columns: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly $updatableFragmentSpreads: FragmentRefs<"BoardColumn_updatable">;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_column">;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly isViewConfigModified: boolean | null | undefined;
    } | null | undefined;
    readonly success: boolean;
  } | null | undefined;
};
export type BoardColumnCollapsedStateMutation = {
  response: BoardColumnCollapsedStateMutation$data;
  variables: BoardColumnCollapsedStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "isViewConfigModified"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "BoardColumnCollapsedStateMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewColumnStatePayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewColumnState",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "JiraBoardViewColumnConnection",
                "kind": "LinkedField",
                "name": "columns",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardViewColumnEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "BoardColumn_column"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "BoardColumn_updatable"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardColumnCollapsedStateMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewColumnStatePayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewColumnState",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "JiraBoardViewColumnConnection",
                "kind": "LinkedField",
                "name": "columns",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardViewColumnEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraBoardViewColumn"
                          },
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "collapsed"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "statuses",
                                "plural": true,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "statusId"
                                  },
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "statusCategoryId"
                                      },
                                      (v5/*: any*/)
                                    ]
                                  },
                                  (v5/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewStatusColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "priorityId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v5/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewPriorityColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "accountId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v5/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewAssigneeColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "category",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "value"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "optionId"
                                  },
                                  {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "colorKey"
                                      },
                                      (v5/*: any*/)
                                    ]
                                  },
                                  (v5/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewCategoryColumn"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7a0dabf330570ad11ebc82d813a718ac8968cf166d6999b5ea58731573cca67d",
    "metadata": {},
    "name": "BoardColumnCollapsedStateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f07d3b93d89ff457fe9847695769a257";

export default node;
