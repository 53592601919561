import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const RestrictedIcon = () => (
	<Graphic
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<path
				d="M9.82452 2.57897C11.7787 2.57897 13.6529 3.35529 15.0348 4.73713C16.4166 6.11898 17.1929 7.99317 17.1929 9.94739C17.1929 11.9016 16.4166 13.7758 15.0348 15.1577C13.6529 16.5395 11.7787 17.3158 9.82452 17.3158C7.8703 17.3158 5.99611 16.5395 4.61426 15.1577C3.23241 13.7758 2.4561 11.9016 2.4561 9.94739C2.4561 7.99317 3.23241 6.11898 4.61426 4.73713C5.99611 3.35529 7.8703 2.57897 9.82452 2.57897ZM9.82452 0.473709C8.57209 0.460507 7.32946 0.695786 6.16855 1.16594C5.00763 1.63609 3.95146 2.33177 3.06115 3.21275C2.17084 4.09372 1.46406 5.1425 0.981702 6.29839C0.499342 7.45429 0.250977 8.69436 0.250977 9.94687C0.250977 11.1994 0.499342 12.4394 0.981702 13.5953C1.46406 14.7512 2.17084 15.8 3.06115 16.681C3.95146 17.562 5.00763 18.2576 6.16855 18.7278C7.32946 19.1979 8.57209 19.4332 9.82452 19.42C12.3371 19.42 14.7468 18.4219 16.5234 16.6452C18.3001 14.8686 19.2982 12.4589 19.2982 9.94634C19.2982 7.43376 18.3001 5.02409 16.5234 3.24743C14.7468 1.47077 12.3371 0.472656 9.82452 0.472656V0.473709Z"
				fill={token('color.icon.subtle')}
			/>
			<path
				d="M3.68457 5.09375L15.0656 16.4695L16.9856 14.7474L5.40352 3.15796C5.43299 3.18954 3.68457 5.09375 3.68457 5.09375Z"
				fill={token('color.icon.subtle')}
			/>
		</g>
	</Graphic>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Graphic = styled.svg({
	flex: 'none',
});

export default RestrictedIcon;
