/**
 * @generated SignedSource<<5a61d93066baee6d62214410fb058dcd>>
 * @relayHash a98c949cbc77c039f96c5db02d541f91
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 79756fe2958dd6b39f6fe9af1f5c16a5dc697d519aa6d3f07f927bb025b81bfa

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ColumnDeleteModalQuery } from './ColumnDeleteModalQuery.graphql';

const node: PreloadableConcreteRequest<ColumnDeleteModalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "79756fe2958dd6b39f6fe9af1f5c16a5dc697d519aa6d3f07f927bb025b81bfa",
    "metadata": {},
    "name": "ColumnDeleteModalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
