/* eslint-disable @atlassian/relay/query-restriction */
import React, { type ReactNode, useCallback } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import type { BoardFiltersProvider$key } from '@atlassian/jira-relay/src/__generated__/BoardFiltersProvider.graphql';
import type { BoardFiltersProviderSetFiltersMutation } from '@atlassian/jira-relay/src/__generated__/BoardFiltersProviderSetFiltersMutation.graphql';
import { useFlagsService } from '@atlassian/jira-flags';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useExperienceAbort } from '@atlassian/jira-experience-tracker/src/ui/experience-abort/index.tsx';
import { MutationErrorCollection } from '@atlassian/jira-mutation-error/src/mutation-error-collection.tsx';
import { InternalFiltersJqlProvider } from '@atlassian/jira-business-filters/src/controllers/index.tsx';
import { getUrlSettingsInput } from '../../common/url-settings-input.tsx';
import messages from './messages.tsx';

type Props = {
	children: ReactNode;
	viewFragment: BoardFiltersProvider$key;
};

const experience = 'setBoardViewFilter';

export const BoardFiltersProvider = ({ children, viewFragment }: Props) => {
	const { showFlag } = useFlagsService();
	const startExperience = useExperienceStart({
		experience,
		analyticsSource: 'jiraWorkManagementBoard',
	});
	const markExperienceSuccess = useExperienceSuccess({
		experience,
	});
	const markExperienceFailed = useExperienceFail({
		experience,
	});
	const markExperienceAborted = useExperienceAbort({
		experience,
	});

	const view = useFragment(
		graphql`
			fragment BoardFiltersProvider on JiraBoardView {
				id @required(action: THROW)
				filterConfig @required(action: THROW) {
					jql @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	const [commit] = useMutation<BoardFiltersProviderSetFiltersMutation>(graphql`
		mutation BoardFiltersProviderSetFiltersMutation($input: JiraSetBoardViewFilterInput!) {
			jira_setBoardViewFilter(input: $input) {
				success
				boardView {
					isViewConfigModified
					...BoardFiltersProvider
				}
				errors {
					message
					extensions {
						errorType
						statusCode
					}
				}
			}
		}
	`);

	const filterJql = view.filterConfig.jql || undefined;

	const saveJql = useCallback(
		(jql: string | undefined) => {
			startExperience();

			const handleFailure = (error: Error) => {
				markExperienceFailed('BoardFiltersProvider jira_setBoardViewFilter mutation failed', error);
				showFlag({
					messageId:
						'work-management-board.controllers.board-filters.show-flag.error.set-filters-failed',
					messageType: 'transactional',
					title: messages.setFiltersFailedTitle,
					description: messages.setFiltersFailedMessage,
					type: 'error',
				});
			};

			const newJql = jql ?? '';
			commit({
				variables: {
					input: {
						viewId: view.id,
						jql: newJql,
						settings: getUrlSettingsInput(),
					},
				},
				optimisticResponse: {
					jira_setBoardViewFilter: {
						success: true,
						boardView: {
							...view,
							isViewConfigModified: undefined,
							filterConfig: {
								jql: newJql,
							},
						},
						errors: null,
					},
				},
				onError(error: Error) {
					handleFailure(error);
				},
				onCompleted(response) {
					if (response.jira_setBoardViewFilter?.success !== true) {
						const error = response.jira_setBoardViewFilter?.errors?.[0];
						const statusCode = error?.extensions?.statusCode ?? 0;
						if (error?.message && statusCode >= 400 && statusCode < 500) {
							markExperienceAborted('jira_setBoardViewFilter experience aborted');
							showFlag({
								messageId:
									'work-management-board.controllers.board-filters.show-flag.error.set-filters-validation-error',
								messageType: 'transactional',
								title: messages.setFiltersValidationErrorTitle,
								description: error.message,
								type: 'error',
							});
							return;
						}

						const mutationError = new MutationErrorCollection(
							response.jira_setBoardViewFilter?.errors,
						);
						handleFailure(mutationError);
						return;
					}

					markExperienceSuccess();
				},
			});
		},
		[
			commit,
			view,
			showFlag,
			markExperienceAborted,
			markExperienceSuccess,
			startExperience,
			markExperienceFailed,
		],
	);

	return (
		<InternalFiltersJqlProvider jql={filterJql} saveJql={saveJql}>
			{children}
		</InternalFiltersJqlProvider>
	);
};
