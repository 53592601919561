/**
 * @generated SignedSource<<451b971e3c82429a05b6eabab0387874>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type StatusColumnHeader_column$data = {
  readonly collapsed: boolean;
  readonly statuses: ReadonlyArray<{
    readonly name: string;
    readonly statusCategory: {
      readonly statusCategoryId: string;
    };
    readonly statusId: string;
    readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_status" | "ColumnConfettiAsync_status">;
  } | null | undefined>;
  readonly " $fragmentType": "StatusColumnHeader_column";
};
export type StatusColumnHeader_column$key = {
  readonly " $data"?: StatusColumnHeader_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusColumnHeader_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "StatusColumnHeader_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "collapsed"
      },
      "action": "THROW",
      "path": "collapsed"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraStatus",
        "kind": "LinkedField",
        "name": "statuses",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BoardHeaderDropdown_status"
          },
          {
            "kind": "FragmentSpread",
            "name": "ColumnConfettiAsync_status"
          },
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "name"
            },
            "action": "THROW",
            "path": "statuses.name"
          },
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "statusId"
            },
            "action": "THROW",
            "path": "statuses.statusId"
          },
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraStatusCategory",
              "kind": "LinkedField",
              "name": "statusCategory",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "statusCategoryId"
                  },
                  "action": "THROW",
                  "path": "statuses.statusCategory.statusCategoryId"
                }
              ]
            },
            "action": "THROW",
            "path": "statuses.statusCategory"
          }
        ]
      },
      "action": "THROW",
      "path": "statuses"
    }
  ],
  "type": "JiraBoardViewStatusColumn"
};

(node as any).hash = "fe785cf784352e4c14d6cb6e5214254e";

export default node;
