/**
 * @generated SignedSource<<0cf9c670257988ef49af813ddf26a905>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OpenProjectSettingsMultipleWorkflows_project$data = {
  readonly key: string;
  readonly " $fragmentType": "OpenProjectSettingsMultipleWorkflows_project";
};
export type OpenProjectSettingsMultipleWorkflows_project$key = {
  readonly " $data"?: OpenProjectSettingsMultipleWorkflows_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenProjectSettingsMultipleWorkflows_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "OpenProjectSettingsMultipleWorkflows_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "7f720ba94aa97b43a3976d34e2193761";

export default node;
