/**
 * @generated SignedSource<<dc72a20d5b02dea78b8e59633fcec413>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssigneeColumnHeader_column$data = {
  readonly collapsed: boolean;
  readonly user: {
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "AssigneeColumnHeader_column";
};
export type AssigneeColumnHeader_column$key = {
  readonly " $data"?: AssigneeColumnHeader_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssigneeColumnHeader_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "AssigneeColumnHeader_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "collapsed"
      },
      "action": "THROW",
      "path": "collapsed"
    },
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "name"
          },
          "action": "THROW",
          "path": "user.name"
        },
        {
          "kind": "ScalarField",
          "name": "picture"
        }
      ]
    }
  ],
  "type": "JiraBoardViewAssigneeColumn"
};

(node as any).hash = "5365c77b7b32dc79c9ceaf261c448651";

export default node;
