type UrlOverridesInput = {
	filterJql?: string;
	groupBy?: string;
};

export const getUrlSettingsInput = (): UrlOverridesInput => {
	if (typeof window !== 'undefined') {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const filterJql = urlSearchParams.get('filter') ?? undefined;
		const groupBy = urlSearchParams.get('groupBy') ?? undefined;

		return {
			filterJql,
			groupBy,
		};
	}

	return {};
};
