/**
 * @generated SignedSource<<c390c936f86c11580305a5c625de2a82>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardCover_workManagementBoard_CardImageCover_queryFragment$data = {
  readonly jira: {
    readonly mediaClientId: string | null | undefined;
    readonly mediaExternalEndpointUrl: string | null | undefined;
  };
  readonly " $fragmentType": "cardCover_workManagementBoard_CardImageCover_queryFragment";
};
export type cardCover_workManagementBoard_CardImageCover_queryFragment$key = {
  readonly " $data"?: cardCover_workManagementBoard_CardImageCover_queryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_CardImageCover_queryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "cardCover_workManagementBoard_CardImageCover_queryFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v0/*: any*/),
            "kind": "ScalarField",
            "name": "mediaClientId"
          },
          {
            "args": (v0/*: any*/),
            "kind": "ScalarField",
            "name": "mediaExternalEndpointUrl"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "3e08da767606c294768dfcd7a2b95f9b";

export default node;
