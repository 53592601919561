/* eslint-disable @atlassian/relay/query-restriction */
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { createRouterSelector, useRouterStoreActions } from '@atlassian/react-resource-router';
import type { BusinessBoardQueryParamSync_view$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoardQueryParamSync_view.graphql';

type Props = {
	viewFragment: BusinessBoardQueryParamSync_view$key;
};

export const useLocation = createRouterSelector((state) => state.location);

export const BusinessBoardQueryParamSync = ({ viewFragment }: Props) => {
	const location = useLocation();
	const { updateQueryParam } = useRouterStoreActions();

	const view = useFragment(
		graphql`
			fragment BusinessBoardQueryParamSync_view on JiraBoardView {
				filterConfig @required(action: THROW) {
					jql @required(action: THROW)
				}
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	useEffect(() => {
		const filter = view.filterConfig.jql;
		const groupBy = view.groupByConfig.fieldId;
		const currentParams = new URLSearchParams(location.search);
		const currentFilter = currentParams.get('filter') ?? '';
		const currentGroupBy = currentParams.get('groupBy');

		if (filter !== currentFilter || groupBy !== currentGroupBy) {
			updateQueryParam(
				{
					// empty JQL string means no filter query param
					filter: filter || undefined,
					groupBy,
				},
				'replace',
			);
		}
		// recalculate if the location has changed (not just the search)
	}, [location, updateQueryParam, view.filterConfig.jql, view.groupByConfig.fieldId]);

	return null;
};
