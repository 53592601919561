import { useCallback, useMemo } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import {
	useExperienceAbort,
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { useWorkflowsV2 } from '@atlassian/jira-business-workflows/src/services/workflow-v2/index.tsx';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { TEMPORARY_STATUS_ID } from '../constants.tsx';
import { useStartAddOperation, useStopWorkflowOperationInProgress } from '../index.tsx';
import { getStatusActionFailedFlag, failStatusExperience } from '../utils.tsx';
import messages from './messages.tsx';

export const useCreateStatus = (experienceDetails: ExperienceDetails) => {
	const cloudId = useCloudId();
	const { createWorkflowStatusforSingleWorkflow } = useWorkflowsV2();

	const createStatusExperience = useMemo(
		() =>
			new UFOExperience(experienceDetails.experience, {
				type: ExperienceTypes.Operation,
				performanceType: ExperiencePerformanceTypes.Custom,
			}),
		[experienceDetails.experience],
	);

	const startAddOperation = useStartAddOperation();
	const stopWorkflowOperationInProgress = useStopWorkflowOperationInProgress();

	const startExperience = useExperienceStart(experienceDetails);
	const markExperienceSuccess = useExperienceSuccess(experienceDetails);
	const markExperienceFailed = useExperienceFail(experienceDetails);
	const abortExperience = useExperienceAbort(experienceDetails);

	const project = useProject();
	const { showFlag } = useFlagService();

	const markExperienceSuccessWithUFO = useCallback(() => {
		markExperienceSuccess();
		createStatusExperience.success();
	}, [markExperienceSuccess, createStatusExperience]);

	const createNewStatus = useCallback(
		async ({
			statusName,
			statusCategory,
			issueTypeId,
		}: {
			statusName: string;
			statusCategory: StatusCategory;
			issueTypeId: string;
		}) => {
			startAddOperation(String(TEMPORARY_STATUS_ID));

			startExperience();
			createStatusExperience.start();

			try {
				await createWorkflowStatusforSingleWorkflow({
					statusName,
					statusCategory,
					cloudId,
					projectId: String(project.id),
					issueTypeId,
					onSuccess: markExperienceSuccessWithUFO,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				showFlag(
					getStatusActionFailedFlag({
						messageTitle: messages.errorFlagTitleAddStatus,
						action: 'add',
						error,
						issueTypeId,
						projectKey: project.key,
						statusCode: error?.response?.status,
					}),
				);

				failStatusExperience({
					markExperienceFailed,
					abortExperience,
					ufoExperience: createStatusExperience,
					error,
					errorMessage: 'Failed to validate and update workflow',
				});

				throw error;
			} finally {
				stopWorkflowOperationInProgress();
			}
		},
		[
			startAddOperation,
			createStatusExperience,
			startExperience,
			createWorkflowStatusforSingleWorkflow,
			cloudId,
			project.id,
			project.key,
			markExperienceSuccessWithUFO,
			showFlag,
			markExperienceFailed,
			abortExperience,
			stopWorkflowOperationInProgress,
		],
	);

	return createNewStatus;
};
