import React, { memo, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { useSidebar } from '@atlassian/jira-business-app-wrapper/src/controllers/sidebar/index.tsx';
import { callWhenIdle } from '@atlassian/jira-call-when-idle/src/index.tsx';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { SidebarIssueApp, IssueApp } from './main.tsx';
import { useSetSidebarStyleVar } from './utils.tsx';

const ISSUE_VIEW_SIDEBAR_CONTENT_KEY = 'issueViewSidebar';

export type Props = {
	issueKey?: IssueKey | null;
	analyticsSource: AnalyticsSource;
	onClose: () => void;
	onChange?: OnChangeCallback;
	onIssueDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
	isSoftwareProject?: boolean;
};

export const SidebarIssueView = memo<Props>(
	({
		issueKey,
		onClose,
		analyticsSource,
		onChange,
		onIssueDeleteSuccess,
		isSoftwareProject,
	}: Props) => {
		const [{ content }, { setSidebarContent }] = useSidebar();
		const prevIssueKey = usePreviousWithInitial(issueKey);

		const { createAnalyticsEvent } = useAnalyticsEvents();
		useSetSidebarStyleVar();

		const onCloseSidebar = useCallback(() => {
			onClose();
		}, [onClose]);

		useEffect(() => {
			// avoid re-rendering in case props aren't memoised
			if (issueKey === prevIssueKey && !!content) {
				return;
			}

			if (issueKey) {
				const newContent = (
					<CSSAntiCorruptionLayer key={ISSUE_VIEW_SIDEBAR_CONTENT_KEY}>
						<SidebarIssueApp
							issueKey={toIssueKey(issueKey)}
							onClose={onCloseSidebar}
							analyticsSource={analyticsSource}
							onChange={onChange}
							onIssueDeleteSuccess={onIssueDeleteSuccess}
							isSoftwareProject={isSoftwareProject}
						/>
					</CSSAntiCorruptionLayer>
				);
				setSidebarContent(newContent);
				fireUIAnalytics(createAnalyticsEvent({}), 'sidebarIssueApp opened');
			} else {
				if (content?.key === ISSUE_VIEW_SIDEBAR_CONTENT_KEY) {
					setSidebarContent(null);
				}

				fireUIAnalytics(createAnalyticsEvent({}), 'sidebarIssueApp closed');
			}
		}, [
			analyticsSource,
			content,
			createAnalyticsEvent,
			isSoftwareProject,
			issueKey,
			onChange,
			onCloseSidebar,
			onIssueDeleteSuccess,
			prevIssueKey,
			setSidebarContent,
		]);

		useEffect(() => {
			// Preload IssueApp here, otherwise it'd be loaded when Sidebar opens up for the first time, which is a bit slow
			callWhenIdle(() => {
				IssueApp.preload();
			}, 3000);
		}, []);

		return null;
	},
);

// put an extra div around issue view to "adopt" styles from `SidebarContent` (`> div`)
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CSSAntiCorruptionLayer = styled.div({
	height: '100%',
	boxSizing: 'border-box',
});
