import { createLocalizationProvider } from '@atlaskit/locale';
import type { DateType } from '../types.tsx';

// Convert an CalendarDateType to a date string string formatted for a particular locale,
// returns Date string, eg "MM/DD/YYYY"
export const formatDateType = (date: DateType, locale: string) => {
	const { day, month, year } = date;
	const l10n = createLocalizationProvider(locale);
	const dateObj = new Date(year, month - 1, day);
	const formattedDate = l10n.formatDate(dateObj);
	const [monthPart, dayPart, yearPart] = formattedDate.split('/');
	return `${monthPart}/${dayPart}/${yearPart}`;
};

// Parse a date string into a DateType object based on the locale
export const parseDateType = (dateString: string, locale: string): DateType | null => {
	try {
		const l10n = createLocalizationProvider(locale);
		const date = l10n.parseDate(dateString);

		// If date is invalid
		if (Number.isNaN(date.getTime())) {
			return null;
		}
		const year = date.getFullYear();

		if (year < 1000 || year > 9999) {
			return null;
		}

		const dateObj = {
			day: date.getDate(),
			month: date.getMonth() + 1,
			year,
		};
		return dateObj;
	} catch (e) {
		return null;
	}
};

// Find the date segment (day, month, year) by cursor position
export const findDateSegmentByPosition = (
	cursorPos: number,
	dateString: string,
	locale: string,
): 'day' | 'month' | 'year' | undefined => {
	const l10n = createLocalizationProvider(locale);
	const dateFormat = l10n
		.formatDate(new Date())
		.replace(/\d/g, 'd')
		.replace(/[a-zA-Z]/g, 'M');
	const dayIndex = dateFormat.indexOf('d');
	const monthIndex = dateFormat.indexOf('M');
	const yearIndex = dateFormat.indexOf('y');

	if (cursorPos <= dayIndex + 2) {
		return 'day';
	}
	if (cursorPos <= monthIndex + 2) {
		return 'month';
	}
	if (cursorPos <= yearIndex + 4) {
		return 'year';
	}
	return undefined;
};

// Adjust the date by a given segment and adjustment value
export const adjustDate = (
	date: DateType,
	segment: 'day' | 'month' | 'year',
	adjustment: number,
): DateType => {
	const newDate = new Date(date.year, date.month - 1, date.day);

	switch (segment) {
		case 'day':
			newDate.setDate(newDate.getDate() + adjustment);
			break;
		case 'month':
			newDate.setMonth(newDate.getMonth() + adjustment);
			break;
		case 'year':
			newDate.setFullYear(newDate.getFullYear() + adjustment);
			break;
		default:
			break;
	}

	return {
		day: newDate.getDate(),
		month: newDate.getMonth() + 1,
		year: newDate.getFullYear(),
	};
};

// Inconclusively check if a date string is valid -
// a value of false means it is definitely invalid, a value of true means it might be valid.
export const isDatePossiblyValid = (date: string): boolean => {
	for (const c of date) {
		const isNumber = c >= '0' && c <= '9';
		const isValidPunctuation = '. ,/-'.indexOf(c) !== -1;
		if (!(isNumber || isValidPunctuation)) {
			return false;
		}
	}
	return true;
};
