import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { TEAMS_PLATFORM_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { SelectValue, AvatarOption } from '../../common/types.tsx';
import { transformTeams } from '../../common/utils/transform-field-values/index.tsx';
import { TEAMS_QUERY, TEAM_BY_ID_QUERY } from './gql.tsx';

// use ari functions from @atlassian/ari whenever it is ready for import
const prependOrgIdAri = (orgId: string) => `ari:cloud:platform::org/${orgId}`;

export const EMPTY_TEAMS = {
	[TEAMS_PLATFORM_CF_TYPE]: {
		totalCount: 0,
		options: [],
	},
};

const MAX_NUMBER_OF_OPTIONS = 20;
const EMPTY_ARRAY: SelectValue<AvatarOption> = [];

type FetchTeamResult = {
	[TEAMS_PLATFORM_CF_TYPE]: {
		options: SelectValue<AvatarOption>;
		totalCount: number;
	};
};

export type FetchTeams = (args: {
	query?: string;
	first?: number;
	after?: string;
}) => Promise<FetchTeamResult>;

export type FetchTeamsById = (args: { teamIds: string[] }) => Promise<SelectValue<AvatarOption>>;

export const useFetchTeams = () => {
	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();

	const fetchTeams: FetchTeams = useCallback(
		async ({ query, first = MAX_NUMBER_OF_OPTIONS, after }) => {
			if (orgId) {
				try {
					const response = await performPostRequest('/gateway/api/graphql', {
						body: JSON.stringify({
							query: TEAMS_QUERY,
							variables: {
								orgId: prependOrgIdAri(orgId),
								cloudId,
								queryString: query,
								first,
								after,
							},
						}),
					});

					const teamData = response.data.team;
					if (!teamData) {
						throw new Error('Empty team data');
					}
					const options = transformTeams(teamData.teamSearchV2) || [];
					// Teams query doesn't return the total count
					const totalCount = options.length >= first ? first : options.length;
					return {
						[TEAMS_PLATFORM_CF_TYPE]: {
							totalCount,
							options,
						},
					};
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					fireErrorAnalytics({
						meta: {
							id: 'fetchTeams',
							packageName: 'jiraBusinessFilters',
							teamName: 'wanjel',
						},
						attributes: {
							message: 'Failed to fetch team filter data',
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			}
			return EMPTY_TEAMS;
		},
		[cloudId, orgId],
	);

	return fetchTeams;
};

export const useFetchTeamsById = () => {
	const cloudId = useCloudId();

	const fetchTeamsById: FetchTeamsById = useCallback(
		async ({ teamIds }) =>
			Promise.all(
				teamIds.map((teamId) =>
					performPostRequest('/gateway/api/graphql', {
						body: JSON.stringify({
							query: TEAM_BY_ID_QUERY,
							variables: {
								cloudId,
								teamId: `ari:cloud:identity::team/${teamId}`,
							},
						}),
					}),
				),
			)
				.then((result) => {
					const options =
						result.map((response) => {
							const teamOption = response.data.team.teamV2;
							return {
								value: teamOption.id.replace('ari:cloud:identity::team/', ''),
								label: teamOption.displayName || '',
								avatar: teamOption.smallAvatarImageUrl || '',
							};
						}) || [];
					return options;
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.catch((err: any) => {
					fireErrorAnalytics({
						meta: {
							id: 'fetchTeamsById',
							packageName: 'jiraBusinessFilters',
							teamName: 'wanjel',
						},
						attributes: {
							message: 'Failed to fetch team by ID filter data',
						},
						error: err,
						sendToPrivacyUnsafeSplunk: true,
					});
					return EMPTY_ARRAY;
				}),
		[cloudId],
	);

	return fetchTeamsById;
};
