import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { srcLight as noResultsIllustrationOld } from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useRouterStoreActions } from '@atlassian/react-resource-router';
import { useFiltersPreference } from '../../controllers/index.tsx';
import { SIMPLE_SEARCH_PARAM } from '../../constants.tsx';
import messages from './messages.tsx';

type Props = {
	reason?: 'INVALID_JQL' | 'NO_RESULTS';
};

export const ThemedFilterEmptyState = ({ reason }: Props) => {
	const { updateQueryParam } = useRouterStoreActions();
	const { formatMessage } = useIntl();
	const { saveJql } = useFiltersPreference();
	const handleOnClear = useCallback(() => {
		saveJql(undefined);
		updateQueryParam({ [SIMPLE_SEARCH_PARAM]: undefined }, 'replace');
	}, [saveJql, updateQueryParam]);

	const message =
		reason === 'INVALID_JQL'
			? formatMessage(messages.invalidStateHeading)
			: formatMessage(messages.emptyStateHeading);

	return (
		<Box xcss={wrapperStyles}>
			<Illustration src={noResultsIllustrationOld} alt="" />
			<HeadingWrapper>{message}</HeadingWrapper>
			<ThemedButton appearance="primary" onClick={handleOnClear}>
				{formatMessage(messages.clearFiltersButton)}
			</ThemedButton>
		</Box>
	);
};

const wrapperStyles = xcss({
	textAlign: 'center',
	margin: 'auto',
	width: '450px',
	padding: 'space.300',
	borderRadius: 'border.radius.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST_BOLDER,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingWrapper = styled.h3({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
	marginBottom: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Illustration = styled.img({
	width: '208px',
	height: '190px',
});
