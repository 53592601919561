/**
 * @generated SignedSource<<5873d7be95495845dae7c338912cc103>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_project$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_Content_project">;
  readonly " $fragmentType": "BoardCard_project";
};
export type BoardCard_project$key = {
  readonly " $data"?: BoardCard_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_Content_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "e0170cfdbb6342e8b8371d146c10ba96";

export default node;
