import { format } from 'date-fns';
import type {
	CreateIssueParameters,
	ExtraCreateIssuePayload,
} from '@atlassian/jira-business-issue-create/src/types.tsx';
import {
	ASSIGNEE_TYPE,
	SUMMARY_TYPE,
	DUE_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { JWMBoardInlineCreateIssueVariables } from './__generated_apollo__/JWMBoardInlineCreateIssue';

const RANK_BEFORE_ID = 'rankBefore';
const RANK_AFTER_ID = 'rankAfter';

export const buildInput = (
	input: CreateIssueParameters['input'],
	extraPayload: ExtraCreateIssuePayload,
	categoryFieldId: string | null,
): JWMBoardInlineCreateIssueVariables['input'] => {
	const singleLineTextFields: { fieldId: string; text: string }[] = [
		{
			fieldId: SUMMARY_TYPE,
			text: input.summary,
		},
	];
	const singleSelectUserPickerFields = [];
	const datePickerFields = [];

	if (input.category && categoryFieldId) {
		singleLineTextFields.push({
			fieldId: categoryFieldId,
			text: input.category.optionId,
		});
	}

	if (input.assignee) {
		singleSelectUserPickerFields.push({
			fieldId: ASSIGNEE_TYPE,
			user: {
				accountId: input.assignee.accountId,
			},
		});
	}

	if (
		input.dueDate &&
		expVal('jira_powerful_inline_create_for_business_board', 'isEnabled', false)
	) {
		datePickerFields.push({
			fieldId: DUE_DATE_TYPE,
			date: {
				formattedDate: format(input.dueDate, 'yyyy-MM-dd'),
			},
		});
	}

	const fields = {
		singleLineTextFields,
		singleSelectUserPickerFields,
		...(input.dueDate &&
			expVal('jira_powerful_inline_create_for_business_board', 'isEnabled', false) && {
				datePickerFields,
			}),
		...(input.priority && {
			priority: {
				priorityId: input.priority.priorityId,
			},
		}),
		...(input.parentIssueId && {
			parentField: {
				issueId: input.parentIssueId,
			},
		}),
	};

	const rank = {
		...(input.rankBefore && {
			before: {
				id: RANK_BEFORE_ID,
				issueId: input.rankBefore.issueId,
			},
		}),
		...(input.rankAfter && {
			after: {
				id: RANK_AFTER_ID,
				issueId: input.rankAfter.issueId,
			},
		}),
	};

	const result = {
		projectId: input.projectId,
		issueType: input.issueType.id,
		fields,
		rank,
		...(extraPayload?.transitionId && { transitionId: extraPayload.transitionId }),
	};

	return result;
};
