import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyBoardTitle: {
		id: 'work-management-board.board.empty-state.empty-board-title',
		defaultMessage: 'Visualize your work with a board',
		description: '',
	},
	emptyBoardDescription: {
		id: 'work-management-board.board.empty-state.empty-board-description',
		defaultMessage:
			'Track, organize and prioritize your team’s work. Get started by creating an item for your team. ',
		description: 'Descriptive text to show on an empty board above create a new item button.',
	},
	emptyBoardCreateItem: {
		id: 'work-management-board.board.empty-state.empty-board-create-item',
		defaultMessage: 'Create an item',
		description: 'Shortcut button label to create a new item on an empty board.',
	},
	emptyBoardDescriptionIssueTermRefresh: {
		id: 'work-management-board.board.empty-state.empty-board-description-issue-term-refresh',
		defaultMessage:
			'Track, organize and prioritize your team’s work. Get started by creating a work item for your team. ',
		description: 'Descriptive text to show on an empty board above create a new item button.',
	},
	emptyBoardCreateItemIssueTermRefresh: {
		id: 'work-management-board.board.empty-state.empty-board-create-item-issue-term-refresh',
		defaultMessage: 'Create a work item',
		description: 'Shortcut button label to create a new item on an empty board.',
	},
});
