import React, { memo } from 'react';
import { styled } from '@compiled/react';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { InlineCreateStyles } from '../../types.tsx';
import messages from './messages.tsx';

export type Props = InlineCreateStyles & {
	onClick?: () => void;
};

const InlineCreateTrigger = ({ onClick, minHeight, borderRadius, horizontalPadding }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Trigger
			borderRadius={borderRadius}
			data-testid="business-issue-create.ui.inline-create-trigger"
			horizontalPadding={horizontalPadding}
			minHeight={minHeight}
			onClick={onClick}
		>
			<AddIcon label="" LEGACY_size="small" />
			<Label>{formatMessage(messages.createLabel)}</Label>
		</Trigger>
	);
};

export default memo(InlineCreateTrigger);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Trigger = styled.button<InlineCreateStyles>({
	display: 'flex',
	alignItems: 'center',

	color: token('color.text.subtle'),
	background: 'transparent',
	border: 'none',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ borderRadius }) => borderRadius ?? 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => minHeight ?? '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ horizontalPadding }) => `0 ${horizontalPadding ?? token('space.100')}`,
	gap: token('space.100'),
	width: '100%',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed'),
	},
	'&:focus, &:active, &:focus-visible': {
		outline: `${token('border.width.outline')} auto ${token('color.border.focused')}`,
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		outlineOffset: '-1px', // this value is used so the focus ring works nicely with overflow hidden
	},
});

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({});
