/**
 * @generated SignedSource<<fac6bba01e8faf3db241d941a980f1af>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_CoverWrapper_query">;
  readonly " $fragmentType": "BoardCard_query";
};
export type BoardCard_query$key = {
  readonly " $data"?: BoardCard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_CoverWrapper_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "6049a31da9de02d7b855cbd2f8bc0ff2";

export default node;
