/**
 * @generated SignedSource<<4f505fec75ae47d5b7ce2e8795de460b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CategoryColumnHeader_column$data = {
  readonly category: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly value: string;
  } | null | undefined;
  readonly collapsed: boolean;
  readonly " $fragmentType": "CategoryColumnHeader_column";
};
export type CategoryColumnHeader_column$key = {
  readonly " $data"?: CategoryColumnHeader_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryColumnHeader_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CategoryColumnHeader_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "collapsed"
      },
      "action": "THROW",
      "path": "collapsed"
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "value"
          },
          "action": "THROW",
          "path": "category.value"
        },
        {
          "concreteType": "JiraColor",
          "kind": "LinkedField",
          "name": "color",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "colorKey"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraBoardViewCategoryColumn"
};

(node as any).hash = "31f0a80e13dd5972370be8d9316bf9c9";

export default node;
