import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { BADGE_INNER_POSITION } from './constants.tsx';

const BADGE_SIZE = 16;
// the badge text color will remain white regardless of the theme applied
const BADGE_TEXT_COLOR = 'white';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NodeSelectionWrapper = styled.div<{ color: string }>({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ color }) => `0 0 0 2px ${color} inset`,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
});

export type ParticipantBadgeProps = { position?: string; color: string };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ParticipantBadge = styled.div<ParticipantBadgeProps>({
	position: 'absolute',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	right: '0px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	top: ({ position }) => (position === BADGE_INNER_POSITION ? '0px' : '-14px'),
	width: 'auto',
	height: `${BADGE_SIZE}px`,
	zIndex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ position }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		position === BADGE_INNER_POSITION
			? `0px 0px 0px ${
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
					token('border.radius', '3px')
				}`
			: `${
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
					token('border.radius', '3px')
				} ${
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
					token('border.radius', '3px')
				} 0px 0px`,
	paddingTop: '0px',
	paddingRight: token('space.050'),
	paddingBottom: '0px',
	paddingLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ color }) => color,
	boxSizing: 'border-box',
	font: token('font.body.UNSAFE_small'),
	color: `${BADGE_TEXT_COLOR}`,
});
