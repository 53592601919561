import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import type { BoardIssue } from '../../common/types.tsx';
import { useIssuesByGroup } from '../issues-by-group/IssuesByGroupProvider.tsx';

const EMPTY_ISSUES: BoardIssue[] = [];

type IssueGroup = {
	issues: BoardIssue[];
};

const IssueGroupContext = createContext<IssueGroup | null>(null);

type IssueGroupProviderProps = { groupId: string; children: ReactNode };

export const IssueGroupProvider = ({ groupId, children }: IssueGroupProviderProps) => {
	const issuesByGroup = useIssuesByGroup();

	const issues = issuesByGroup.get(groupId) ?? EMPTY_ISSUES;

	const value = useMemo(() => ({ issues }), [issues]);

	return <IssueGroupContext.Provider value={value}>{children}</IssueGroupContext.Provider>;
};

export const useIssueGroup = (): IssueGroup => {
	const issueGroup = useContext(IssueGroupContext);

	if (!issueGroup) {
		throw new Error('useIssueGroup must be used within a IssueGroupProvider');
	}

	return issueGroup;
};
