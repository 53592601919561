/**
 * @generated SignedSource<<acde1fe674b909121c0f8ce7667b29c7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_Inner_view$data = {
  readonly columns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly category?: {
          readonly optionId: string;
        } | null | undefined;
        readonly collapsed: boolean | null | undefined;
        readonly id: string;
        readonly priority?: {
          readonly priorityId: string;
        } | null | undefined;
        readonly statuses?: ReadonlyArray<{
          readonly statusId: string;
        } | null | undefined> | null | undefined;
        readonly user?: {
          readonly accountId: string;
        } | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_column">;
    } | null | undefined>;
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"BoardColumnCreate_columns" | "BoardMinimap_columns">;
  };
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_view">;
  readonly " $fragmentType": "BoardContent_Inner_view";
};
export type BoardContent_Inner_view$key = {
  readonly " $data"?: BoardContent_Inner_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "columns"
        ]
      }
    ]
  },
  "name": "BoardContent_Inner_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "columns",
        "concreteType": "JiraBoardViewColumnConnection",
        "kind": "LinkedField",
        "name": "__business_board_columns_connection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BoardColumnCreate_columns"
          },
          {
            "kind": "FragmentSpread",
            "name": "BoardMinimap_columns"
          },
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "totalCount"
            },
            "action": "THROW",
            "path": "columns.totalCount"
          },
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraBoardViewColumnEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "BoardColumn_column"
                },
                {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "id"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "collapsed"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "concreteType": "JiraStatus",
                          "kind": "LinkedField",
                          "name": "statuses",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "kind": "ScalarField",
                                "name": "statusId"
                              },
                              "action": "THROW",
                              "path": "columns.edges.node.statuses.statusId"
                            }
                          ]
                        }
                      ],
                      "type": "JiraBoardViewStatusColumn"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "concreteType": "JiraPriority",
                          "kind": "LinkedField",
                          "name": "priority",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "kind": "ScalarField",
                                "name": "priorityId"
                              },
                              "action": "THROW",
                              "path": "columns.edges.node.priority.priorityId"
                            }
                          ]
                        }
                      ],
                      "type": "JiraBoardViewPriorityColumn"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              "action": "THROW",
                              "path": "columns.edges.node.user.accountId"
                            }
                          ]
                        }
                      ],
                      "type": "JiraBoardViewAssigneeColumn"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "concreteType": "JiraOption",
                          "kind": "LinkedField",
                          "name": "category",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "kind": "ScalarField",
                                "name": "optionId"
                              },
                              "action": "THROW",
                              "path": "columns.edges.node.category.optionId"
                            }
                          ]
                        }
                      ],
                      "type": "JiraBoardViewCategoryColumn"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                }
              ]
            },
            "action": "THROW",
            "path": "columns.edges"
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "columns"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardColumn_view"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "aafe2fbc5cd5fd2c95d3bea18cbcf965";

export default node;
