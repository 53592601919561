/**
 * @generated SignedSource<<585091df852ca82a8e22a918b7bea1b2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type IssueCreateProvider$data = {
  readonly jira: {
    readonly rankField: {
      readonly fieldId: string | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "IssueCreateProvider";
};
export type IssueCreateProvider$key = {
  readonly " $data"?: IssueCreateProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueCreateProvider">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "IssueCreateProvider",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "JiraJqlFieldWithAliases",
            "kind": "LinkedField",
            "name": "rankField",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "fieldId"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};

(node as any).hash = "fea400f7b9138349b48b46b455b7d1db";

export default node;
