/**
 * @generated SignedSource<<6778780c79ae15165eb91c3f477c7986>>
 * @relayHash 7e9f3358a04f6fed1b1c1a92a10c541b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ef3d78f82565556a65c51d26e22028189b5cc13446cf614a8330d835e8d1aef9

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraBoardViewInput = {
  jiraBoardViewQueryInput: JiraBoardViewQueryInput;
  settings?: JiraBoardViewSettings | null | undefined;
};
export type JiraBoardViewQueryInput = {
  projectKeyAndItemIdQuery?: JiraBoardViewProjectKeyAndItemIdQuery | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraBoardViewProjectKeyAndItemIdQuery = {
  cloudId: string;
  itemId: string;
  projectKey: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type CardActionsMenuContentQuery$variables = {
  projectAri: string;
  viewInput: JiraBoardViewInput;
};
export type CardActionsMenuContentQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly canCreateIssues: {
        readonly canPerform: boolean;
      };
      readonly canDeleteIssues: {
        readonly canPerform: boolean;
      };
      readonly canEditIssues: {
        readonly canPerform: boolean;
      };
      readonly canLinkIssues: {
        readonly canPerform: boolean;
      };
      readonly canScheduleIssues: {
        readonly canPerform: boolean;
      };
    };
  };
  readonly jira_boardView: {
    readonly cardOptions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly field?: {
            readonly fieldId: string;
          };
        };
      } | null | undefined>;
    };
  };
};
export type CardActionsMenuContentQuery = {
  response: CardActionsMenuContentQuery$data;
  variables: CardActionsMenuContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewInput"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "viewInput"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "enabledOnly",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "CREATE_ISSUES"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "canPerform"
},
v8 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "DELETE_ISSUES"
  }
],
v9 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_ISSUES"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "LINK_ISSUES"
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "SCHEDULE_ISSUES"
  }
],
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "CardActionsMenuContentQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v2/*: any*/),
          "concreteType": "JiraBoardView",
          "kind": "LinkedField",
          "name": "jira_boardView",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v3/*: any*/),
                "concreteType": "JiraBoardViewCardOptionConnection",
                "kind": "LinkedField",
                "name": "cardOptions",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraBoardViewCardOptionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraField",
                                      "kind": "LinkedField",
                                      "name": "field",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v4/*: any*/),
                                          "action": "THROW",
                                          "path": "jira_boardView.cardOptions.edges.node.field.fieldId"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira_boardView.cardOptions.edges.node.field"
                                  }
                                ],
                                "type": "JiraBoardViewFieldCardOption"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira_boardView.cardOptions.edges.node"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira_boardView.cardOptions.edges"
                  }
                ],
                "storageKey": "cardOptions(enabledOnly:true,first:100)"
              },
              "action": "THROW",
              "path": "jira_boardView.cardOptions"
            }
          ]
        },
        "action": "THROW",
        "path": "jira_boardView"
      },
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v5/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "canCreateIssues",
                      "args": (v6/*: any*/),
                      "concreteType": "JiraProjectAction",
                      "kind": "LinkedField",
                      "name": "action",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraProject.canCreateIssues.canPerform"
                        }
                      ],
                      "storageKey": "action(type:\"CREATE_ISSUES\")"
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.canCreateIssues"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "canDeleteIssues",
                      "args": (v8/*: any*/),
                      "concreteType": "JiraProjectAction",
                      "kind": "LinkedField",
                      "name": "action",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraProject.canDeleteIssues.canPerform"
                        }
                      ],
                      "storageKey": "action(type:\"DELETE_ISSUES\")"
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.canDeleteIssues"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "canEditIssues",
                      "args": (v9/*: any*/),
                      "concreteType": "JiraProjectAction",
                      "kind": "LinkedField",
                      "name": "action",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraProject.canEditIssues.canPerform"
                        }
                      ],
                      "storageKey": "action(type:\"EDIT_ISSUES\")"
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.canEditIssues"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "canLinkIssues",
                      "args": (v10/*: any*/),
                      "concreteType": "JiraProjectAction",
                      "kind": "LinkedField",
                      "name": "action",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraProject.canLinkIssues.canPerform"
                        }
                      ],
                      "storageKey": "action(type:\"LINK_ISSUES\")"
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.canLinkIssues"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": "canScheduleIssues",
                      "args": (v11/*: any*/),
                      "concreteType": "JiraProjectAction",
                      "kind": "LinkedField",
                      "name": "action",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "jira.jiraProject.canScheduleIssues.canPerform"
                        }
                      ],
                      "storageKey": "action(type:\"SCHEDULE_ISSUES\")"
                    },
                    "action": "THROW",
                    "path": "jira.jiraProject.canScheduleIssues"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProject"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardActionsMenuContentQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "JiraBoardView",
        "kind": "LinkedField",
        "name": "jira_boardView",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraBoardViewCardOptionConnection",
            "kind": "LinkedField",
            "name": "cardOptions",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraBoardViewCardOptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraField",
                            "kind": "LinkedField",
                            "name": "field",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v12/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraBoardViewFieldCardOption"
                      },
                      (v12/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "storageKey": "cardOptions(enabledOnly:true,first:100)"
          },
          (v12/*: any*/)
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "alias": "canCreateIssues",
                "args": (v6/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "action(type:\"CREATE_ISSUES\")"
              },
              {
                "alias": "canDeleteIssues",
                "args": (v8/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "action(type:\"DELETE_ISSUES\")"
              },
              {
                "alias": "canEditIssues",
                "args": (v9/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "action(type:\"EDIT_ISSUES\")"
              },
              {
                "alias": "canLinkIssues",
                "args": (v10/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "action(type:\"LINK_ISSUES\")"
              },
              {
                "alias": "canScheduleIssues",
                "args": (v11/*: any*/),
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": "action(type:\"SCHEDULE_ISSUES\")"
              },
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ef3d78f82565556a65c51d26e22028189b5cc13446cf614a8330d835e8d1aef9",
    "metadata": {},
    "name": "CardActionsMenuContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "441742273d30ceb1d168e0b216aa8dcf";

export default node;
