/**
 * @generated SignedSource<<a01cdd949ccce4b84027b63db7b55395>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardInvalidDropTargetMessage_column$data = {
  readonly __typename: string;
  readonly " $fragmentType": "BoardInvalidDropTargetMessage_column";
};
export type BoardInvalidDropTargetMessage_column$key = {
  readonly " $data"?: BoardInvalidDropTargetMessage_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardInvalidDropTargetMessage_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardInvalidDropTargetMessage_column",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraBoardViewColumn",
  "abstractKey": "__isJiraBoardViewColumn"
};

(node as any).hash = "4c5d6c91d88154d5cf5da9b3426d94de";

export default node;
