/**
 * @generated SignedSource<<76d8231d201ad8356c493309770e912e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type StatusColumnHeader_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_view">;
  readonly " $fragmentType": "StatusColumnHeader_view";
};
export type StatusColumnHeader_view$key = {
  readonly " $data"?: StatusColumnHeader_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusColumnHeader_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "StatusColumnHeader_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardHeaderDropdown_view"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "a7e9bcd61ed41130688a25dae11386d5";

export default node;
