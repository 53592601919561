/**
 * @generated SignedSource<<d8eaf8ed0d07bdef309446c3b060737e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_Inner_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_query">;
  readonly " $fragmentType": "BoardContent_Inner_query";
};
export type BoardContent_Inner_query$key = {
  readonly " $data"?: BoardContent_Inner_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardContent_Inner_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardColumn_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "6b11931fc8ec0186be67833a2a622e1c";

export default node;
