/**
 * @generated SignedSource<<4e1dca20f6dba989f995cf4659bd49e1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_column$data = {
  readonly node: {
    readonly __typename: string;
    readonly $updatableFragmentSpreads: FragmentRefs<"BoardColumn_updatable">;
    readonly category?: {
      readonly color: {
        readonly colorKey: string | null | undefined;
      } | null | undefined;
      readonly optionId: string;
      readonly value: string;
    } | null | undefined;
    readonly collapsed: boolean;
    readonly id: string;
    readonly priority?: {
      readonly iconUrl: AGG$URL | null | undefined;
      readonly name: string;
      readonly priorityId: string;
    };
    readonly statuses?: ReadonlyArray<{
      readonly name: string;
      readonly statusCategory: {
        readonly statusCategoryId: string;
      };
      readonly statusId: string;
    } | null | undefined>;
    readonly user?: {
      readonly accountId: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AssigneeColumnHeader_column" | "BoardCard_column" | "BoardInlineCreate_column" | "BoardInvalidDropTargetMessage_column" | "CategoryColumnHeader_column" | "PriorityColumnHeader_column" | "StatusColumnHeader_column">;
  };
  readonly " $fragmentType": "BoardColumn_column";
};
export type BoardColumn_column$key = {
  readonly " $data"?: BoardColumn_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_column">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumn_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BoardInlineCreate_column"
          },
          {
            "kind": "FragmentSpread",
            "name": "BoardCard_column"
          },
          {
            "kind": "FragmentSpread",
            "name": "BoardInvalidDropTargetMessage_column"
          },
          {
            "kind": "FragmentSpread",
            "name": "BoardColumn_updatable"
          },
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "id"
            },
            "action": "THROW",
            "path": "node.id"
          },
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "collapsed"
            },
            "action": "THROW",
            "path": "node.collapsed"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "StatusColumnHeader_column"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "JiraStatus",
                  "kind": "LinkedField",
                  "name": "statuses",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v0/*: any*/),
                      "action": "THROW",
                      "path": "node.statuses.name"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "statusId"
                      },
                      "action": "THROW",
                      "path": "node.statuses.statusId"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraStatusCategory",
                        "kind": "LinkedField",
                        "name": "statusCategory",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "kind": "ScalarField",
                              "name": "statusCategoryId"
                            },
                            "action": "THROW",
                            "path": "node.statuses.statusCategory.statusCategoryId"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "node.statuses.statusCategory"
                    }
                  ]
                },
                "action": "THROW",
                "path": "node.statuses"
              }
            ],
            "type": "JiraBoardViewStatusColumn"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "PriorityColumnHeader_column"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "JiraPriority",
                  "kind": "LinkedField",
                  "name": "priority",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v0/*: any*/),
                      "action": "THROW",
                      "path": "node.priority.name"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "priorityId"
                      },
                      "action": "THROW",
                      "path": "node.priority.priorityId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "iconUrl"
                    }
                  ]
                },
                "action": "THROW",
                "path": "node.priority"
              }
            ],
            "type": "JiraBoardViewPriorityColumn"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "AssigneeColumnHeader_column"
              },
              {
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v0/*: any*/),
                    "action": "THROW",
                    "path": "node.user.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "accountId"
                    },
                    "action": "THROW",
                    "path": "node.user.accountId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  }
                ]
              }
            ],
            "type": "JiraBoardViewAssigneeColumn"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "CategoryColumnHeader_column"
              },
              {
                "concreteType": "JiraOption",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "value"
                    },
                    "action": "THROW",
                    "path": "node.category.value"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "optionId"
                    },
                    "action": "THROW",
                    "path": "node.category.optionId"
                  },
                  {
                    "concreteType": "JiraColor",
                    "kind": "LinkedField",
                    "name": "color",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "colorKey"
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraBoardViewCategoryColumn"
          }
        ]
      },
      "action": "THROW",
      "path": "node"
    }
  ],
  "type": "JiraBoardViewColumnEdge"
};
})();

(node as any).hash = "9418af4390a5ab11bd964ada61675049";

export default node;
