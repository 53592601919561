/**
 * @generated SignedSource<<71733446f7feb0b25ded972e4b7e6c09>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoardScreenAnalytics_view$data = {
  readonly filterConfig: {
    readonly jql: string;
  };
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly isViewConfigModified: boolean;
  readonly " $fragmentType": "BusinessBoardScreenAnalytics_view";
};
export type BusinessBoardScreenAnalytics_view$key = {
  readonly " $data"?: BusinessBoardScreenAnalytics_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoardScreenAnalytics_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoardScreenAnalytics_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewFilterConfig",
        "kind": "LinkedField",
        "name": "filterConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "jql"
            },
            "action": "THROW",
            "path": "filterConfig.jql"
          }
        ]
      },
      "action": "THROW",
      "path": "filterConfig"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isViewConfigModified"
      },
      "action": "THROW",
      "path": "isViewConfigModified"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "15b0a43b0bdaabb721154c95f12efff8";

export default node;
