import React from 'react';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

export const IssueLinksDetailsErrorFallback = ({
	callback,
	testId,
	error,
}: {
	callback?: () => void;
	testId?: string;
	error?: Error;
}) => {
	callback && callback();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	showFlag({
		id: 'async-issue-links-details-entrypoint-flag',
		type: 'error',
		testId,
		title: formatMessage(messages.issueLinksDetailsErrorFlagTitle),
		description: formatMessage(messages.issueLinksDetailsErrorFlagDescription),
		isAutoDismiss: true,
		messageId: 'issue-links-stats-business.ui.issue-links-details-error-fallback.show-flag.error',
		messageType: 'transactional',
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();
	fireOperationalAnalytics(createAnalyticsEvent({}), 'ui taskFail', {
		task: 'viewIssueLinksDetailsPopup',
		isClientFetchError: isClientFetchError(error),
	});
	// need to return an empty fragment as entrypoint fallback prop only accept ((props: { error: Error }) => ReactElement);
	return <></>;
};
