import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	itemMoveErrorTitle: {
		id: 'work-management-board.board.item-move-error-title',
		defaultMessage: 'Failed to move item',
		description: 'Error title informing the user attempt at moving the issue failed.',
	},
	itemMoveErrorMessage: {
		id: 'work-management-board.board.item-move-error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message informing the user attempt at moving the issue failed.',
	},
	columnMoveErrorTitle: {
		id: 'work-management-board.board.column-move-error-title',
		defaultMessage: 'Failed to move column.',
		description: 'Error title informing the user attempt at moving the column failed.',
	},
	columnMoveErrorMessage: {
		id: 'work-management-board.board.column-move-error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message informing the user attempt at moving the column failed.',
	},
	setBoardViewColumnsOrderFailedTitle: {
		id: 'work-management-board.board.use-set-board-view-columns-order.error-title',
		defaultMessage: 'Unable to reorder columns',
		description: 'Error title informing the user settings the columns order failed.',
	},
	setBoardViewColumnsOrderFailedMessage: {
		id: 'work-management-board.board.use-set-board-view-columns-order.error-message',
		defaultMessage:
			"We couldn't apply your column order settings, try refreshing the page or try again later.",
		description: 'Error message the user settings the columns order failed.',
	},
});
