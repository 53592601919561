/* eslint-disable @atlassian/relay/query-restriction */
import React, { type ReactNode, createContext, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { IssueLinkingProvider$key } from '@atlassian/jira-relay/src/__generated__/IssueLinkingProvider.graphql';

type Props = {
	children: ReactNode;
	queryFragment: IssueLinkingProvider$key | null | undefined;
};

const Context = createContext<boolean>(false);

export const IssueLinkingProvider = ({ children, queryFragment = null }: Props) => {
	const data = useFragment(
		graphql`
			fragment IssueLinkingProvider on Query {
				jira {
					issueLinkTypes(cloudId: $cloudId) {
						totalCount
					}
				}
			}
		`,
		queryFragment,
	);

	const issueLinksCount = data?.jira?.issueLinkTypes?.totalCount;
	const isLinkingEnabled = issueLinksCount != null && issueLinksCount > 0;

	return <Context.Provider value={isLinkingEnabled}>{children}</Context.Provider>;
};

export const useIsLinkingEnabled = (): boolean => {
	const enabled = useContext(Context);

	if (enabled == null) {
		throw new Error('useIsLinkingEnabled must be used within a IssueLinkingProvider');
	}

	return enabled;
};
