import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardInitErrorTitle: {
		id: 'work-management-board.controllers.board-data.board-init-error-title',
		defaultMessage: 'Failed to load the board',
		description: 'Error title informing the user loading issue board data failed.',
	},
	boardInitErrorMessage: {
		id: 'work-management-board.controllers.board-data.board-init-error-message',
		defaultMessage: "We couldn't load your board, try refreshing the page or try again later.",
		description: 'Error message informing the user loading issue board data failed.',
	},
	boardStatusNotFoundWarningFlagTitle: {
		id: 'work-management-board.controllers.board-data.board-status-not-found-warning-flag-title',
		defaultMessage: "Some work items couldn't be displayed",
		description: 'Error title informing the user some work items could not be displayed.',
	},
	boardStatusNotFoundWarningFlagDescription: {
		id: 'work-management-board.controllers.board-data.board-status-not-found-warning-flag-description',
		defaultMessage:
			"Some work items couldn't be displayed on the board due to unrecognized status IDs. This may occur if the work item's status isn't part of the current workflow.",
		description: 'Error message informing the user some work items could not be displayed.',
	},
	boardStatusNotFoundWarningFlagAction: {
		id: 'work-management-board.controllers.board-data.board-status-not-found-warning-flag-action',
		defaultMessage: 'View all work items',
		description: 'Action button in flag to view all work items.',
	},
});
