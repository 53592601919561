/* eslint-disable complexity */
import { useCallback } from 'react';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { StatusCategoryIds } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import {
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	PRIORITY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	NULL_GROUP_KEY,
	ASSIGNEE_ID,
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_STATUS,
	BOARD_FIELD_USER,
	CATEGORY_ID,
	PRIORITY_ID,
	STATUS_ID,
} from '../../common/constants.tsx';
import type {
	BoardIssue,
	BoardIssueFields,
	Group,
	Rank,
	StatusGroup,
} from '../../common/types.tsx';
import { useConfettiColumnsActions } from '../column-confetti/index.tsx';
import { useUpdateSessionId } from '../drag-and-drop/card-drag-drop-context/index.tsx';
import { useCardDragDropAnalytics } from '../drag-and-drop/use-card-drag-drop-analytics/index.tsx';
import { useUpdateIssue } from '../update-issue/index.tsx';

const isIssueNewlyMarkedAsDone = (sourceColumn: Group, destinationColumn: StatusGroup) => {
	if (sourceColumn.type !== GROUP_BY_STATUS) {
		return false;
	}

	return (
		sourceColumn.statusCategoryId !== StatusCategoryIds.done &&
		destinationColumn.statusCategoryId === StatusCategoryIds.done
	);
};

type MoveIssueProps = {
	issue: BoardIssue;
	sourceGroup: Group | null;
	destinationGroup: Group | null;
	rank?: Rank;
	transition?: Transition | null;
	categoryField: {
		id: string;
		name: string;
	} | null;
};

export const useMoveIssue = () => {
	const { addConfettiColumn } = useConfettiColumnsActions();
	const updateIssue = useUpdateIssue();
	const { fireCardDropAnalytics } = useCardDragDropAnalytics();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const updateSessionId = fg('one_event_improvements_1') ? undefined : useUpdateSessionId();

	return useCallback(
		async ({
			issue,
			sourceGroup,
			destinationGroup,
			rank,
			transition,
			categoryField,
		}: MoveIssueProps) => {
			let showConfetti = false;

			if (sourceGroup == null || destinationGroup == null) {
				throw new Error('Source or destination group not found');
			}

			const destinationGroupType = destinationGroup.type;
			const fieldUpdates: Partial<BoardIssueFields> = {};

			if (sourceGroup.id !== destinationGroup.id) {
				switch (destinationGroupType) {
					case GROUP_BY_ASSIGNEE:
						fieldUpdates[ASSIGNEE_ID] =
							destinationGroup.id === NULL_GROUP_KEY
								? undefined
								: {
										fieldId: ASSIGNEE_TYPE,
										type: BOARD_FIELD_USER,
										value: destinationGroup.id,
										user: {
											accountId: destinationGroup.id,
											avatarURL: destinationGroup.imageUrl,
											name: destinationGroup.name,
										},
									};
						break;

					case GROUP_BY_CATEGORY:
						if (categoryField) {
							fieldUpdates[CATEGORY_ID] =
								destinationGroup.id === NULL_GROUP_KEY
									? undefined
									: {
											name: categoryField.name,
											fieldId: categoryField.id,
											type: BOARD_FIELD_CATEGORY,
											value: destinationGroup.id,
											category: {
												color: destinationGroup.color,
												name: destinationGroup.name,
											},
										};
						}
						break;

					case GROUP_BY_PRIORITY:
						fieldUpdates[PRIORITY_ID] =
							destinationGroup.id === NULL_GROUP_KEY
								? undefined
								: {
										fieldId: PRIORITY_TYPE,
										type: BOARD_FIELD_PRIORITY,
										value: destinationGroup.id,
										priority: {
											id: destinationGroup.id,
											iconUrl: destinationGroup.imageUrl ?? '',
											name: destinationGroup.name,
										},
									};
						break;

					case GROUP_BY_STATUS: {
						showConfetti =
							!destinationGroup.isCollapsed &&
							isIssueNewlyMarkedAsDone(sourceGroup, destinationGroup);

						fieldUpdates[STATUS_ID] = {
							fieldId: STATUS_TYPE,
							type: BOARD_FIELD_STATUS,
							value: destinationGroup.id,
							status: {
								id: destinationGroup.id,
								statusCategoryId: destinationGroup.statusCategoryId,
								name: destinationGroup.name,
							},
						};

						break;
					}

					default: {
						const exhaustiveCheck = destinationGroupType;
						throw new Error(`Unexpected destination group type: ${exhaustiveCheck}`);
					}
				}
			}

			try {
				await updateIssue({
					issue,
					fields: fieldUpdates,
					transition,
					rank,
					categoryFieldId: categoryField?.id ?? null,
				});

				if (showConfetti) {
					addConfettiColumn(destinationGroup.id);
				}

				if (!fg('one_event_improvements_1')) {
					if (updateSessionId == null) {
						throw new Error('updateSessionId is required for card drop analytics');
					}
				}

				fireCardDropAnalytics({
					issue,
					sourceGroup,
					destinationGroup,
					rank,
					transition,
					...(!updateSessionId ? {} : { updateSessionId }), // TODO: remove this on one_event_improvements_1 cleanup
				});
			} catch (error: unknown) {
				// errors handled in updateIssue controller
			}
		},
		[addConfettiColumn, fireCardDropAnalytics, updateIssue, updateSessionId],
	);
};
