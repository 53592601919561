/**
 * @generated SignedSource<<999bbd70f236b1db2af1ec221a2b9e7d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_withWorkflow_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_query" | "IssueCreateProvider" | "IssueLinkingProvider">;
  readonly " $fragmentType": "BusinessBoard_withWorkflow_query";
};
export type BusinessBoard_withWorkflow_query$key = {
  readonly " $data"?: BusinessBoard_withWorkflow_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_withWorkflow_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "IssueCreateProvider"
    },
    {
      "kind": "FragmentSpread",
      "name": "IssueLinkingProvider"
    }
  ],
  "type": "Query"
};

(node as any).hash = "82b43ec93863b63e5f675f9be2287354";

export default node;
