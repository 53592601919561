/**
 * @generated SignedSource<<aca0b4cc6d9f902bb7b7aa85bea8298c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_withWorkflow_view$data = {
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_view" | "BoardDataProvider_view" | "BoardFieldIdsProvider_view" | "BoardHeader_view" | "BusinessBoardScreenAnalytics_view" | "IssuesByGroupProvider_view">;
  readonly " $fragmentType": "BusinessBoard_withWorkflow_view";
};
export type BusinessBoard_withWorkflow_view$key = {
  readonly " $data"?: BusinessBoard_withWorkflow_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_withWorkflow_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardDataProvider_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardHeader_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BusinessBoardScreenAnalytics_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "IssuesByGroupProvider_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardFieldIdsProvider_view"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "f0db343c248ca8e355aa5ffb7ab19088";

export default node;
