import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { OpenProjectSettingsMultipleWorkflows_project$key } from '@atlassian/jira-relay/src/__generated__/OpenProjectSettingsMultipleWorkflows_project.graphql';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import messages from './messages.tsx';

export type Props = {
	projectFragment: OpenProjectSettingsMultipleWorkflows_project$key;
};

export const OpenProjectSettingsMultipleWorkflows = ({ projectFragment }: Props) => {
	const project = useFragment(
		graphql`
			fragment OpenProjectSettingsMultipleWorkflows_project on JiraProject {
				key @required(action: THROW)
			}
		`,
		projectFragment,
	);

	return (
		<FormattedMessage
			{...messages.multipleWorkflowsBoardActionsDisabled}
			values={{
				link: (chunks: ReactNode) => (
					<Link href={`/jira/core/projects/${project.key}/settings/issuetypes`} target="_blank">
						{chunks} <ShortcutIcon label="" LEGACY_size="small" />
					</Link>
				),
			}}
		/>
	);
};
