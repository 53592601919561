/** @jsx jsx */
import { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { REMOTE_ISSUE_LINKS_TYPE } from '@atlassian/jira-business-constants/src/index.tsx';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { CardFooter_cardOptions$key } from '@atlassian/jira-relay/src/__generated__/CardFooter_cardOptions.graphql';
import {
	SUMMARY_ID,
	ISSUE_KEY_ID,
	ISSUE_TYPE_ID,
	PAGES,
} from '../../../../../common/constants.tsx';
import type { BoardIssue } from '../../../../../common/types.tsx';
import CardDetailConfluencePages from './confluence-pages/CardDetailConfluencePages.tsx';
import { IssueLinks } from './issue-links/index.tsx';

export type CardFooterProps = {
	cardOptionsFragment: CardFooter_cardOptions$key;
	issue: BoardIssue;
	isPlaceholder?: boolean;
};

export const CardFooter = memo(({ cardOptionsFragment, issue, isPlaceholder }: CardFooterProps) => {
	const cardOptions = useFragment(
		graphql`
			fragment CardFooter_cardOptions on JiraBoardViewCardOptionConnection {
				edges @required(action: THROW) {
					node @required(action: THROW) {
						... on JiraBoardViewFieldCardOption {
							field @required(action: THROW) {
								fieldId @required(action: THROW)
							}
						}
					}
				}
			}
		`,
		cardOptionsFragment,
	);

	const pageCount = issue.fields[REMOTE_ISSUE_LINKS_TYPE]?.value ?? 0;
	const issueLinksCount = issue.fields[ISSUE_LINKS_TYPE]?.value ?? 0;

	const hasConfluencePages =
		cardOptions.edges.some((edge) => edge?.node?.field?.fieldId === PAGES) && pageCount > 0;
	const hasIssueLinks =
		cardOptions.edges.some((edge) => edge?.node?.field?.fieldId === ISSUE_LINKS_TYPE) &&
		issueLinksCount > 0;

	const hasFooter = hasConfluencePages || hasIssueLinks;

	if (hasFooter) {
		if (isPlaceholder) {
			return (
				<div
					css={[footerPlaceholderStyle, isVisualRefreshEnabled() && footerPlaceholderRefreshStyles]}
				/>
			);
		}

		if (hasFooter) {
			return (
				<div css={[footerStyles, isVisualRefreshEnabled() && footerRefreshStyles]}>
					{hasConfluencePages ? <CardDetailConfluencePages issue={issue} /> : null}
					{hasIssueLinks ? (
						<IssueLinks
							issueId={issue.id}
							issueKey={issue.fields[ISSUE_KEY_ID].value}
							issueSummary={issue.fields[SUMMARY_ID].value}
							issueLinksCount={issue.fields[ISSUE_LINKS_TYPE]?.value ?? 0}
							issueTypeField={issue.fields[ISSUE_TYPE_ID]}
						/>
					) : null}
				</div>
			);
		}
	}

	return null;
});

const footerStyles = css({
	position: 'absolute',
	bottom: 0,
	display: 'flex',
	gap: token('space.050'),
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
});

const footerRefreshStyles = css({
	paddingTop: token('space.0'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
});

const footerPlaceholderStyle = css({
	height: token('space.500'),

	borderTop: `1px solid ${token('color.border')}`,
	marginTop: 0,
	marginRight: token('space.150'),
	marginBottom: 0,
	marginLeft: token('space.150'),
});

const footerPlaceholderRefreshStyles = css({
	height: token('space.400'),
	borderTop: 'none',
});
