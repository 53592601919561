/**
 * @generated SignedSource<<54e44b9954adec60a05669645886f525>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_query">;
  readonly " $fragmentType": "BoardContent_query";
};
export type BoardContent_query$key = {
  readonly " $data"?: BoardContent_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardContent_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_Inner_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "1ab8a827904c51269ee55f5f54589ae5";

export default node;
