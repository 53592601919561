/**
 * @generated SignedSource<<c5a27ac62d1bc781baed56da4be89f2d>>
 * @relayHash d0d62f0effd7e5fcd1a19c3c17541395
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2114a0add8189bca90bda28de62616b396a480646a6d48bbe0fd434c4e07d5b5

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSetBoardViewFilterInput = {
  jql: string;
  settings?: JiraBoardViewSettings | null | undefined;
  viewId: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type BoardFiltersProviderSetFiltersMutation$variables = {
  input: JiraSetBoardViewFilterInput;
};
export type BoardFiltersProviderSetFiltersMutation$data = {
  readonly jira_setBoardViewFilter: {
    readonly boardView: {
      readonly isViewConfigModified: boolean | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BoardFiltersProvider">;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly extensions: {
        readonly errorType: string | null | undefined;
        readonly statusCode: number | null | undefined;
      } | null | undefined;
      readonly message: string | null | undefined;
    }> | null | undefined;
    readonly success: boolean;
  } | null | undefined;
};
export type BoardFiltersProviderSetFiltersMutation = {
  response: BoardFiltersProviderSetFiltersMutation$data;
  variables: BoardFiltersProviderSetFiltersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "isViewConfigModified"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "BoardFiltersProviderSetFiltersMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewFilterPayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewFilter",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "BoardFiltersProvider"
              }
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardFiltersProviderSetFiltersMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewFilterPayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewFilter",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "concreteType": "JiraViewFilterConfig",
                "kind": "LinkedField",
                "name": "filterConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  }
                ]
              }
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "BulkMutationErrorExtension"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2114a0add8189bca90bda28de62616b396a480646a6d48bbe0fd434c4e07d5b5",
    "metadata": {},
    "name": "BoardFiltersProviderSetFiltersMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "aeec27652f206f5041fcd9092c50cc2a";

export default node;
