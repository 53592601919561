import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const CREATE_ISSUE_EXPERIENCE: ExperienceDetails = {
	experience: 'createIssue',
	packageName: 'jiraBusinessIssueCreate',
	teamName: 'deliveroo',
};

export const EDIT_ISSUE_TYPE_EXPERIENCE = new UFOExperience('business-list.edit-issue-type', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
});

export const CREATE_ISSUE_TYPE_EXPERIENCE = new UFOExperience('business-list.create-issue-type', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
});
