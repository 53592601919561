/* eslint-disable @atlassian/relay/query-restriction */
import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	STATUS_TYPE,
	SUMMARY_TYPE,
	PARENT_TYPE,
	TIME_TRACKING_TYPE,
	TIME_ESTIMATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { RANK_ALIAS_FIELD_ID } from '@atlassian/jira-business-constants/src/index.tsx';
import type { BoardFieldIdsProvider_view$key } from '@atlassian/jira-relay/src/__generated__/BoardFieldIdsProvider_view.graphql';
import { useFilterFieldIds } from '../board-filters/index.tsx';

type Context = string[];
type Props = {
	children: ReactNode;
	viewFragment: BoardFieldIdsProvider_view$key;
};
const Context = createContext<Context | null>(null);

const SYSTEM_FIELDS = [
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	PRIORITY_TYPE,
	RANK_ALIAS_FIELD_ID,
	STATUS_TYPE,
	SUMMARY_TYPE,
];

export const BoardFieldIdsProvider = ({ children, viewFragment }: Props) => {
	const { data: categoryField } = useCategoryField();
	const filteredFieldIds = useFilterFieldIds({ categoryFieldId: categoryField?.id });

	const view = useFragment(
		graphql`
			fragment BoardFieldIdsProvider_view on JiraBoardView {
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
				}
				cardOptions(first: 100, enabledOnly: true) @required(action: THROW) {
					edges @required(action: THROW) {
						node @required(action: THROW) {
							... on JiraBoardViewFieldCardOption {
								field @required(action: THROW) {
									fieldId @required(action: THROW)
								}
							}
						}
					}
				}
			}
		`,
		viewFragment,
	);

	const groupByFieldId = view.groupByConfig.fieldId;
	const selectedFields = useMemo(
		() =>
			view.cardOptions.edges
				.map((edge) => (edge?.node?.field ? edge.node.field.fieldId : null))
				.filter(Boolean),
		[view.cardOptions.edges],
	);

	const value = useMemo(() => {
		const fieldIds = new Set<string>(SYSTEM_FIELDS);
		if (groupByFieldId) {
			fieldIds.add(groupByFieldId);
		}
		for (const fieldId of filteredFieldIds) {
			fieldIds.add(fieldId);
		}
		for (const fieldId of selectedFields) {
			fieldIds.add(fieldId);
		}
		// The formatting of the timeoriginalestimate relies on the timetrackingsettings field within timetracking: FUN 1015
		if (fieldIds.has(TIME_ESTIMATE_TYPE)) {
			fieldIds.add(TIME_TRACKING_TYPE);
		}

		return Array.from(fieldIds);
	}, [filteredFieldIds, groupByFieldId, selectedFields]);
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useFieldIds = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useFieldIds must be used within a BoardFieldIdsProvider');
	}

	return context;
};
