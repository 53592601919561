/**
 * @generated SignedSource<<4a253bbfff0b8f9209e280d4dd60ee74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardHeader_project$data = {
  readonly id: string;
  readonly " $fragmentType": "BoardHeader_project";
};
export type BoardHeader_project$key = {
  readonly " $data"?: BoardHeader_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeader_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardHeader_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "6186ec952679bce0e0c89550d072b648";

export default node;
