/**
 * @generated SignedSource<<3c4251db9ac6de81ccb322793421d370>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCardActions_column$data = {
  readonly __typename: "JiraBoardViewAssigneeColumn";
  readonly user: {
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "BoardCardActions_column";
} | {
  readonly __typename: "JiraBoardViewCategoryColumn";
  readonly category: {
    readonly value: string;
  } | null | undefined;
  readonly " $fragmentType": "BoardCardActions_column";
} | {
  readonly __typename: "JiraBoardViewPriorityColumn";
  readonly priority: {
    readonly name: string;
  };
  readonly " $fragmentType": "BoardCardActions_column";
} | {
  readonly __typename: "JiraBoardViewStatusColumn";
  readonly statuses: ReadonlyArray<{
    readonly name: string;
  } | null | undefined>;
  readonly " $fragmentType": "BoardCardActions_column";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BoardCardActions_column";
};
export type BoardCardActions_column$key = {
  readonly " $data"?: BoardCardActions_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCardActions_column">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCardActions_column",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraStatus",
            "kind": "LinkedField",
            "name": "statuses",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v0/*: any*/),
                "action": "THROW",
                "path": "statuses.name"
              }
            ]
          },
          "action": "THROW",
          "path": "statuses"
        }
      ],
      "type": "JiraBoardViewStatusColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraPriority",
            "kind": "LinkedField",
            "name": "priority",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v0/*: any*/),
                "action": "THROW",
                "path": "priority.name"
              }
            ]
          },
          "action": "THROW",
          "path": "priority"
        }
      ],
      "type": "JiraBoardViewPriorityColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v0/*: any*/),
              "action": "THROW",
              "path": "user.name"
            }
          ]
        }
      ],
      "type": "JiraBoardViewAssigneeColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "value"
              },
              "action": "THROW",
              "path": "category.value"
            }
          ]
        }
      ],
      "type": "JiraBoardViewCategoryColumn"
    }
  ],
  "type": "JiraBoardViewColumn",
  "abstractKey": "__isJiraBoardViewColumn"
};
})();

(node as any).hash = "129cc1cf470a83897eb3e5b237b82bac";

export default node;
