/**
 * @generated SignedSource<<2302dacb2ab77f2d4339d9526a2e0bf7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardHeaderDropdown_project$data = {
  readonly canAdministerProject: {
    readonly canPerform: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OpenProjectSettingsMultipleWorkflows_project">;
  readonly " $fragmentType": "BoardHeaderDropdown_project";
};
export type BoardHeaderDropdown_project$key = {
  readonly " $data"?: BoardHeaderDropdown_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardHeaderDropdown_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "canAdministerProject",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "EDIT_PROJECT_CONFIG"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "canPerform"
            },
            "action": "THROW",
            "path": "canAdministerProject.canPerform"
          }
        ],
        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
      },
      "action": "THROW",
      "path": "canAdministerProject"
    },
    {
      "kind": "FragmentSpread",
      "name": "OpenProjectSettingsMultipleWorkflows_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "a0e5236d3e0bf014ceca98247a5f1151";

export default node;
