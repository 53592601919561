import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cardActionsWithIssueName: {
		id: 'work-management-board.board.column.card.card-actions.card-actions-with-issue-name',
		defaultMessage: 'Card actions on issue {issueKey} of column {columnName}',
		description:
			'Label for the button that opens the issue actions menu on a card of a specific column in the Jira board.',
	},
	cardActionsWithIssueNameIssueTermRefresh: {
		id: 'work-management-board.board.column.card.card-actions.card-actions-with-issue-name-issue-term-refresh',
		defaultMessage: 'Card actions on work item {issueKey} of column {columnName}',
		description:
			'Label for the button that opens the issue actions menu on a card of a specific column in the Jira board.',
	},
	noAssignee: {
		id: 'work-management-board.board.column.card.card-actions.no-assignee',
		defaultMessage: 'Unassigned',
		description: 'Label for board group column for items without assignee',
	},
	noCategory: {
		id: 'work-management-board.board.column.card.card-actions.no-category',
		defaultMessage: 'Uncategorized',
		description: 'Label for board group column for items without category',
	},
	moreActionsTooltip: {
		id: 'work-management-board.board.column.card.card-actions.more-actions',
		defaultMessage: 'More actions',
		description: 'Tooltip for the button that opens the issue actions menu on a card in the board.',
	},
});
