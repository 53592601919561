import { useCallback } from 'react';
import { REMOTE_ISSUE_LINKS_TYPE } from '@atlassian/jira-business-constants/src/index.tsx';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import type {
	ColorThemeName,
	GradientThemeName,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import {
	CATEGORY_TYPE,
	COMPONENTS_GQL_FIELD,
	DATE_PICKER_GQL_FIELD,
	DATE_TIME_GQL_FIELD,
	ISSUE_LINKS_TYPE,
	ISSUE_TYPE_GQL_FIELD,
	LABEL_GQL_FIELD,
	MULTI_SELECT_GQL_FIELD,
	MULTI_USER_PICKER_GQL_FIELD,
	NUMBER_GQL_FIELD,
	ORIGINAL_ESTIMATE_GQL_FIELD,
	PARENT_GQL_FIELD,
	PEOPLE_GQL_FIELD,
	PRIORITY_GQL_FIELD,
	RANK_CF_TYPE,
	SUMMARY_TYPE,
	ISSUE_KEY_TYPE,
	SINGLE_SELECT_GQL_FIELD,
	STATUS_GQL_FIELD,
	TEXT_GQL_FIELD,
	TIME_TRACKING_GQL_FIELD,
	USER_PICKER_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import {
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_COMPONENTS,
	BOARD_FIELD_CONFLUENCE_PAGES_COUNT,
	BOARD_FIELD_DATE_TIME,
	BOARD_FIELD_DATE,
	BOARD_FIELD_FLOAT,
	BOARD_FIELD_ISSUE_LINKS_COUNT,
	BOARD_FIELD_ISSUE_TYPE,
	BOARD_FIELD_LABEL,
	BOARD_FIELD_MULTI_USER,
	BOARD_FIELD_PARENT,
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_SELECT,
	BOARD_FIELD_STATUS,
	BOARD_FIELD_TEXT,
	BOARD_FIELD_TIME_ORIGINAL_ESTIMATE,
	BOARD_FIELD_USER,
	CATEGORY_ID,
	ISSUE_KEY_ID,
	ISSUE_TYPE_ID,
	LABEL_ID,
	PARENT_ID,
	RANK_ID,
	STATUS_ID,
	SUMMARY_ID,
} from '../../common/constants.tsx';
import type {
	BoardIssue,
	BoardIssueField,
	BoardIssueCoverMedia,
	BoardIssueIssueTypeField,
	BoardIssueStatusField,
	BoardIssueTextField,
} from '../../common/types.tsx';
import type { IssueDetailFieldJIS2, IssueDetailsJIS2 } from '../../services/board-issues/index.tsx';
import { getSubtaskMetadata } from './utils.tsx';

type FormatMessageOnly = Pick<IntlShape, 'formatMessage'>;

type TimeTrackingConfig = {
	workingHoursPerDay: number;
	workingDaysPerWeek: number;
	defaultUnit: TimeUnit;
	defaultFormat: string;
};

const transformers: Record<
	string,
	(
		field: IssueDetailFieldJIS2,
		timeTrackingConfig: TimeTrackingConfig | null,
		intl?: FormatMessageOnly,
	) => BoardIssueField | null
> = {
	[TEXT_GQL_FIELD]: (field) => {
		if (field.__typename !== TEXT_GQL_FIELD || field.text == null) {
			return null;
		}
		return {
			type: BOARD_FIELD_TEXT,
			fieldId: field.fieldId,
			name: field.name,
			value: field.text,
		};
	},
	[COMPONENTS_GQL_FIELD]: (field) => {
		if (field.__typename !== COMPONENTS_GQL_FIELD) {
			return null;
		}
		const value = field.selectedComponentsConnection?.edges
			?.map((edge) => edge?.node?.name ?? null)
			.filter((name): name is string => name != null);

		if (value == null || value.length === 0) {
			return null;
		}

		return {
			type: BOARD_FIELD_COMPONENTS,
			fieldId: field.fieldId,
			name: field.name,
			value,
		};
	},
	[LABEL_GQL_FIELD]: (field) => {
		if (field.__typename !== LABEL_GQL_FIELD || field.selectedLabelsConnection == null) {
			return null;
		}
		const value = field.selectedLabelsConnection.edges
			?.map((edge) => edge?.node?.name ?? null)
			.filter((name): name is string => name != null);
		if (value == null || value.length === 0) {
			return null;
		}
		return {
			type: BOARD_FIELD_LABEL,
			fieldId: field.fieldId,
			value,
		};
	},
	[ORIGINAL_ESTIMATE_GQL_FIELD]: (
		field,
		timeTrackingConfig: TimeTrackingConfig | null,
		intl?: FormatMessageOnly,
	) => {
		if (
			field.__typename !== ORIGINAL_ESTIMATE_GQL_FIELD ||
			field.originalEstimate?.timeInSeconds == null
		) {
			return null;
		}

		if (!timeTrackingConfig || !intl) {
			// if timeTrackingConfig is not available, return the original estimate in seconds
			return {
				type: BOARD_FIELD_TIME_ORIGINAL_ESTIMATE,
				fieldId: field.fieldId,
				name: field.name,
				value: field.originalEstimate.timeInSeconds,
			};
		}

		const {
			workingDaysPerWeek,
			workingHoursPerDay,
			defaultFormat: timeFormat,
			defaultUnit,
		} = timeTrackingConfig;
		return {
			type: BOARD_FIELD_TIME_ORIGINAL_ESTIMATE,
			fieldId: field.fieldId,
			name: field.name,
			value: timeTrackingFormatter(
				field.originalEstimate.timeInSeconds,
				{
					workingHoursPerDay,
					workingDaysPerWeek,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					timeFormat: (timeFormat.toLowerCase() as unknown as TimeFormat) ?? PRETTY,
					defaultUnit,
				},
				intl,
			),
		};
	},
	[DATE_PICKER_GQL_FIELD]: (field) => {
		if (field.__typename !== DATE_PICKER_GQL_FIELD || field.date == null) return null;
		return {
			type: BOARD_FIELD_DATE,
			fieldId: field.fieldId,
			name: field.name,
			value: field.date,
		};
	},
	[SINGLE_SELECT_GQL_FIELD]: (field) => {
		if (field.__typename !== SINGLE_SELECT_GQL_FIELD || field.fieldOption?.value == null)
			return null;

		if (
			field.__typename === SINGLE_SELECT_GQL_FIELD &&
			field.type === CATEGORY_TYPE &&
			field.fieldOption?.value != null
		) {
			return {
				fieldId: field.fieldId,
				name: field.name,
				type: BOARD_FIELD_CATEGORY,
				value: field.fieldOption.optionId,
				category: {
					name: field.fieldOption.value,
					color: field.fieldOption.color?.colorKey ?? null,
				},
			};
		}

		return {
			type: BOARD_FIELD_SELECT,
			fieldId: field.fieldId,
			name: field.name,
			value: [field.fieldOption.value],
		};
	},
	[DATE_TIME_GQL_FIELD]: (field) => {
		if (field.__typename !== DATE_TIME_GQL_FIELD || field.dateTime == null) {
			return null;
		}
		return {
			type: BOARD_FIELD_DATE_TIME,
			fieldId: field.fieldId,
			name: field.name,
			value: field.dateTime,
		};
	},
	[NUMBER_GQL_FIELD]: (field) => {
		if (field.__typename !== NUMBER_GQL_FIELD || field.number == null) return null;
		return {
			type: BOARD_FIELD_FLOAT,
			fieldId: field.fieldId,
			name: field.name,
			value: field.number,
		};
	},
	[USER_PICKER_GQL_FIELD]: (field) => {
		if (field.__typename !== USER_PICKER_GQL_FIELD || field.user == null) {
			return null;
		}

		return {
			type: BOARD_FIELD_USER,
			fieldId: field.fieldId,
			name: field.name,
			value: field.user.accountId,
			user: {
				accountId: field.user.accountId,
				name: field.user.name,
				avatarURL: field.user.picture,
			},
		};
	},
	[MULTI_USER_PICKER_GQL_FIELD]: (field) => {
		if (field.__typename !== MULTI_USER_PICKER_GQL_FIELD) {
			return null;
		}

		const users = field.selectedUsersConnection?.edges
			?.map((edge) => edge?.node)
			.filter(
				(
					user,
				): user is typeof user & {
					accountId: string;
					name: string;
				} => user != null,
			)
			.map((user) => ({
				accountId: user.accountId,
				name: user.name,
				avatarURL: user.picture,
			}));

		if (users == null || users.length < 1) {
			return null;
		}

		return {
			type: BOARD_FIELD_MULTI_USER,
			fieldId: field.fieldId,
			name: field.name,
			users,
		};
	},
	[MULTI_SELECT_GQL_FIELD]: (field) => {
		if (field.__typename !== MULTI_SELECT_GQL_FIELD) {
			return null;
		}
		const value = field.selectedOptions?.edges
			?.map((edge) => edge?.node)
			.map((node) => node?.value)
			.filter(
				// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
				(optionValue): optionValue is Exclude<typeof optionValue, null | void> =>
					optionValue != null,
			);
		if (value == null || value.length < 1) {
			return null;
		}

		return {
			type: BOARD_FIELD_SELECT,
			fieldId: field.fieldId,
			name: field.name,
			value,
		};
	},
	[PEOPLE_GQL_FIELD]: (field) => {
		if (field.__typename !== PEOPLE_GQL_FIELD) {
			return null;
		}

		const users = field.selectedUsersConnection?.edges
			?.map((edge) => edge?.node)
			// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
			.filter((user): user is Exclude<typeof user, null | void> => user != null)
			.map((user) => ({
				accountId: user.accountId,
				name: user.name,
				avatarURL: user.picture,
			}));

		if (users == null || users.length < 1) {
			return null;
		}

		return {
			type: BOARD_FIELD_MULTI_USER,
			fieldId: field.fieldId,
			name: field.name,
			users,
		};
	},
	[PARENT_GQL_FIELD]: (field) => {
		if (field.__typename !== PARENT_GQL_FIELD || field.parentIssue == null) {
			return null;
		}

		return {
			type: BOARD_FIELD_PARENT,
			fieldId: field.fieldId,
			name: field.name,
			value: field.parentIssue.issueId,
			parent: {
				key: field.parentIssue.key,
				summary: field.parentIssue.summaryField?.text ?? '',
			},
		};
	},
	[STATUS_GQL_FIELD]: (field) => {
		if (
			field.__typename !== STATUS_GQL_FIELD ||
			field.status.name == null ||
			field.status.statusCategory?.statusCategoryId == null ||
			field.status.statusId == null
		) {
			return null;
		}

		return {
			type: BOARD_FIELD_STATUS,
			fieldId: field.fieldId,
			name: field.name,
			value: field.status.statusId,
			status: {
				id: field.status.statusId,
				name: field.status.name,
				statusCategoryId: Number(field.status.statusCategory.statusCategoryId),
			},
		};
	},
	[ISSUE_TYPE_GQL_FIELD]: (field) => {
		if (
			field.__typename !== ISSUE_TYPE_GQL_FIELD ||
			field.issueType?.issueTypeId == null ||
			field.issueType.name == null ||
			field.issueType.hierarchy?.level == null
		) {
			return null;
		}

		return {
			type: BOARD_FIELD_ISSUE_TYPE,
			fieldId: field.fieldId,
			name: field.name,
			id: field.issueType.issueTypeId,
			issueType: {
				id: field.issueType.issueTypeId,
				name: field.issueType.name,
				iconUrl: field.issueType.avatar?.medium ?? '',
				hierarchyLevel: field.issueType.hierarchy.level,
			},
		};
	},
	[PRIORITY_GQL_FIELD]: (field) => {
		if (
			field.__typename !== PRIORITY_GQL_FIELD ||
			field.priority?.iconUrl == null ||
			field.priority?.name == null ||
			field.priority?.priorityId == null
		) {
			return null;
		}

		return {
			type: BOARD_FIELD_PRIORITY,
			fieldId: field.fieldId,
			name: field.name,
			value: field.priority.priorityId,
			priority: {
				iconUrl: field.priority.iconUrl,
				id: field.priority.priorityId,
				name: field.priority.name,
			},
		};
	},
};

const transformField = (
	field: IssueDetailFieldJIS2 | null,
	timeTrackingConfig: TimeTrackingConfig | null,
	intl: FormatMessageOnly,
): BoardIssueField | null => {
	if (field == null) return null;

	if (field.__typename in transformers) {
		return transformers[field.__typename](field, timeTrackingConfig, intl);
	}

	return null;
};

const extractTimeTrackingConfig = (issueDetail: IssueDetailsJIS2): TimeTrackingConfig | null => {
	const timetracking = issueDetail.fieldsByIdOrAlias?.find(
		(field) => field?.__typename === TIME_TRACKING_GQL_FIELD,
	);

	if (
		timetracking &&
		'timeTrackingSettings' in timetracking &&
		timetracking.timeTrackingSettings?.workingHoursPerDay != null &&
		timetracking.timeTrackingSettings?.workingDaysPerWeek != null &&
		timetracking.timeTrackingSettings?.defaultUnit != null
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return timetracking.timeTrackingSettings as unknown as TimeTrackingConfig;
	}
	return null;
};

// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line complexity
export const transformCoverMedia = (
	coverMedia: IssueDetailsJIS2['coverMedia'],
): BoardIssueCoverMedia | null => {
	if (!coverMedia) {
		return null;
	}

	if (coverMedia.__typename === 'JiraWorkManagementColorBackground') {
		if (coverMedia.colorValue == null) {
			return null;
		}

		return {
			type: 'color',
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			value: coverMedia.colorValue as ColorThemeName,
		};
	}

	if (coverMedia.__typename === 'JiraWorkManagementGradientBackground') {
		if (coverMedia.gradientValue == null) {
			return null;
		}

		return {
			type: 'gradient',
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			value: coverMedia.gradientValue as GradientThemeName,
		};
	}

	/**
	 * Images from the trello importer that use the JiraAttachmentBackground
	 * instead of the JiraMediaBackground
	 */
	if (coverMedia.__typename === 'JiraWorkManagementAttachmentBackground') {
		const { fileName, mediaApiFileId, mediaReadToken } = coverMedia.attachment ?? {};

		if (fileName == null || mediaApiFileId == null || mediaReadToken == null) {
			return null;
		}

		let unsplashId = null;
		const unsplashAttachment =
			coverMedia.attachment?.__typename === 'JiraPlatformAttachment' ? coverMedia.attachment : null;
		if (
			unsplashAttachment?.sourceType === 'UNSPLASH' &&
			unsplashAttachment?.sourceIdentifier != null
		) {
			unsplashId = unsplashAttachment.sourceIdentifier;
		}

		return {
			type: 'image',
			fileName,
			mediaApiFileId,
			mediaReadToken,
			unsplashId,
		};
	}

	if (coverMedia.__typename === 'JiraWorkManagementMediaBackground') {
		const { altText, mediaApiFileId, mediaReadToken, sourceIdentifier } =
			coverMedia.customBackground ?? {};

		if (mediaApiFileId == null || mediaReadToken == null || sourceIdentifier == null) {
			return null;
		}

		return {
			type: 'image',
			fileName: altText ?? 'Unsplash image',
			mediaApiFileId,
			mediaReadToken,
			unsplashId: sourceIdentifier,
		};
	}

	return null;
};

export const useTransformIssues = () => {
	const { formatMessage } = useIntl();

	return useCallback(
		(issueDetails: IssueDetailsJIS2[]) => {
			const transformedIssues: BoardIssue[] = [];

			issueDetails.forEach((issueDetail: IssueDetailsJIS2) => {
				if (!issueDetail.fieldsByIdOrAlias) return;

				const decodedFields: Record<string, BoardIssueField> = {};

				// required fields
				let issueTypeField: BoardIssueIssueTypeField | null = null;
				let issueKeyField: BoardIssueTextField | null = null;
				let statusField: BoardIssueStatusField | null = null;
				let summaryField: BoardIssueTextField | null = null;

				// the rank field defaults to an empty rank string
				let rankField: BoardIssueTextField = {
					type: BOARD_FIELD_TEXT,
					fieldId: RANK_CF_TYPE,
					name: '',
					value: '',
				};

				const timeTrackingConfig = extractTimeTrackingConfig(issueDetail);

				issueDetail.fieldsByIdOrAlias?.forEach((field) => {
					const transformedField = transformField(field, timeTrackingConfig, {
						formatMessage,
					});

					if (transformedField == null) return;

					switch (transformedField.type) {
						case BOARD_FIELD_ISSUE_TYPE: {
							issueTypeField = transformedField;
							break;
						}
						case BOARD_FIELD_STATUS: {
							statusField = transformedField;
							break;
						}
						case BOARD_FIELD_CATEGORY: {
							decodedFields[CATEGORY_ID] = transformedField;
							break;
						}
						case BOARD_FIELD_LABEL: {
							decodedFields[LABEL_ID] = transformedField;
							break;
						}
						case BOARD_FIELD_PARENT: {
							decodedFields[PARENT_ID] = transformedField;
							break;
						}
						case BOARD_FIELD_TEXT: {
							if (field && field.__typename === TEXT_GQL_FIELD && field.type === RANK_CF_TYPE) {
								rankField = transformedField;
								break;
							} else if (
								field &&
								field.__typename === TEXT_GQL_FIELD &&
								field.type === SUMMARY_TYPE
							) {
								summaryField = transformedField;
								break;
							} else if (
								field &&
								field.__typename === TEXT_GQL_FIELD &&
								field.type === ISSUE_KEY_TYPE
							) {
								issueKeyField = transformedField;
								break;
							}

							decodedFields[transformedField.fieldId] = transformedField;
							break;
						}
						default: {
							decodedFields[transformedField.fieldId] = transformedField;
						}
					}
				});

				if (issueDetail.legacyConfluenceLinks?.linkedConfluencePages?.pageCount != null) {
					decodedFields[REMOTE_ISSUE_LINKS_TYPE] = {
						type: BOARD_FIELD_CONFLUENCE_PAGES_COUNT,
						fieldId: 'remoteIssueLinks',
						value: issueDetail.legacyConfluenceLinks.linkedConfluencePages.pageCount,
					};
				}

				if (issueDetail.issueLinks?.edges) {
					decodedFields[ISSUE_LINKS_TYPE] = {
						type: BOARD_FIELD_ISSUE_LINKS_COUNT,
						fieldId: ISSUE_LINKS_TYPE,
						value: issueDetail.issueLinks.edges.filter(Boolean).length ?? 0,
					};
				}

				// if we are missing any of the required fields, we skip this issue
				if (
					issueTypeField == null ||
					issueKeyField == null ||
					statusField == null ||
					summaryField == null
				) {
					return;
				}

				const fields: BoardIssue['fields'] = {
					...decodedFields,
					[ISSUE_TYPE_ID]: issueTypeField,
					[ISSUE_KEY_ID]: issueKeyField,
					[RANK_ID]: rankField,
					[STATUS_ID]: statusField,
					[SUMMARY_ID]: summaryField,
				};

				const newIssue: BoardIssue = {
					id: issueDetail.issueId,
					fields,
					coverMedia: transformCoverMedia(issueDetail.coverMedia),
					subtaskMetadata: getSubtaskMetadata(
						issueDetail.childrenTotal?.totalCount,
						issueDetail.childrenNotDone?.totalCount,
					),
				};

				transformedIssues.push(newIssue);
			});

			return transformedIssues;
		},
		[formatMessage],
	);
};
