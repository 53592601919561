/**
 * @generated SignedSource<<d1472225cc14f3a0bb72c3e367f20074>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_view$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardFiltersProvider" | "BusinessBoardQueryParamSync_view" | "BusinessBoard_withWorkflow_view">;
  readonly " $fragmentType": "BusinessBoard_view";
};
export type BusinessBoard_view$key = {
  readonly " $data"?: BusinessBoard_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BusinessBoardQueryParamSync_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardFiltersProvider"
    },
    {
      "kind": "FragmentSpread",
      "name": "BusinessBoard_withWorkflow_view"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "9fc14a4f5d5cf526fdec2256e1d9fff3";

export default node;
