/**
 * @generated SignedSource<<3568e71aa42b73958c8093f2dee8085f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_withWorkflow_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_project" | "BoardDataProvider_project" | "BoardHeader_project">;
  readonly " $fragmentType": "BusinessBoard_withWorkflow_project";
};
export type BusinessBoard_withWorkflow_project$key = {
  readonly " $data"?: BusinessBoard_withWorkflow_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_withWorkflow_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardDataProvider_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardHeader_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "c335dc029ba1d5e31c84e85c466b42a6";

export default node;
