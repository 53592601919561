import type { MutationErrorPayload } from './types.tsx';

/**
 * Formats a mutation error payload into a readable error message string.
 *
 * @param error - The mutation error payload which can be of type `MutationErrorPayload`, `null`, or `undefined`.
 * @returns A formatted error message string.
 *
 * The formatted message includes:
 * - The `__typename` from the `extensions` property, if available, enclosed in parentheses.
 * - The `errorType` from the `extensions` property, if available, enclosed in square brackets.
 * - The `statusCode` from the `extensions` property, if available, prefixed with "HTTP".
 * - The main error message.
 *
 * The parts are concatenated with spaces and any `null` or `undefined` values are filtered out.
 */
function formatErrorMessage(error: MutationErrorPayload | null | undefined): string {
	const { message, extensions } = error ?? {};
	return [
		extensions?.__typename && `(${extensions.__typename})`,
		extensions?.errorType && `[${extensions.errorType}]`,
		extensions?.statusCode && `HTTP ${extensions.statusCode}:`,
		message,
	]
		.filter(Boolean)
		.join(' ')
		.trim();
}

/**
 * Combines an array of GraphQL errors into a single string message.
 *
 * Each error message can include the following information if available:
 * - `errorType`: A string representing the type of the error, enclosed in square brackets.
 * - `statusCode`: An HTTP status code, followed by a colon.
 * - `message`: The error message.
 *
 * The resulting message will be a combination of these parts, separated by spaces, and multiple error messages will be separated by semi-colons.
 *
 * Example formats:
 * - (GraphQLTypeName) [NOT_FOUND] HTTP 404: error message
 * - (GraphQLTypeName) [NOT_FOUND] error message
 * - (GraphQLTypeName) HTTP 404: error message
 * - (GraphQLTypeName) error message
 * - [NOT_FOUND] HTTP 404: error message
 * - [NOT_FOUND] error message
 * - HTTP 404: error message
 * - error message
 *
 * @param errors - An array of GraphQLError objects or null/undefined.
 * @returns A combined error message string or undefined if no errors are provided.
 */
export function combineErrorMessages(
	errors: ReadonlyArray<MutationErrorPayload | null | undefined> | null | undefined,
): string | undefined {
	return errors?.map(formatErrorMessage)?.filter(Boolean)?.join('; ') || undefined;
}
