/**
 * @generated SignedSource<<f55564c444323ea809d1152df8bdfe49>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumnCreate_columns$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly __typename: "JiraBoardViewStatusColumn";
      readonly statuses: ReadonlyArray<{
        readonly name: string;
      } | null | undefined>;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  } | null | undefined>;
  readonly " $fragmentType": "BoardColumnCreate_columns";
};
export type BoardColumnCreate_columns$key = {
  readonly " $data"?: BoardColumnCreate_columns$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumnCreate_columns">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumnCreate_columns",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraBoardViewColumnEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraStatus",
                        "kind": "LinkedField",
                        "name": "statuses",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "kind": "ScalarField",
                              "name": "name"
                            },
                            "action": "THROW",
                            "path": "edges.node.statuses.name"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "edges.node.statuses"
                    }
                  ],
                  "type": "JiraBoardViewStatusColumn"
                }
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    }
  ],
  "type": "JiraBoardViewColumnConnection"
};

(node as any).hash = "baf06ef75ca6b210abf3051ad9bc7554";

export default node;
