import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	resetViewSettingsButton: {
		id: 'work-management-board.header.save-reset-view-settings.reset-view-config-button',
		defaultMessage: 'Reset board back to default',
		description:
			'Label for the button to allow users to reset changes made to the board view settings',
	},
	saveOrResetViewSettingsButton: {
		id: 'work-management-board.header.save-reset-view-settings.save-or-reset-view-settings-button',
		defaultMessage: 'Save or reset view settings',
		description:
			'Label for the menu trigger button to allow users to save or reset changes made to the view settings',
	},
	saveViewSettingsMenuItem: {
		id: 'work-management-board.header.save-reset-view-settings.save-view-config-menu-item',
		defaultMessage: 'Save board',
		description:
			'Label for the menu item to allow users to save changes made to the board view settings',
	},
	resetViewSettingsMenuItem: {
		id: 'work-management-board.header.save-reset-view-settings.reset-view-config-menu-item',
		defaultMessage: 'Reset to last saved',
		description:
			'Label for the menu item to allow users to reset changes made to the board view settings',
	},
	saveSettingsSuccessTitle: {
		id: 'work-management-board.header.save-reset-view-settings.save-settings-success-title',
		defaultMessage: 'Board view saved',
		description: 'Success title informing the user saving board settings succeeded.',
	},
	saveSettingsSuccessMessage: {
		id: 'work-management-board.header.save-reset-view-settings.save-settings-success-message',
		defaultMessage: 'This view has been updated for everyone in this project.',
		description: 'Success message informing the user saving board settings succeeded.',
	},
	saveSettingsFailedTitle: {
		id: 'work-management-board.header.save-reset-view-settings.save-settings-failed-title',
		defaultMessage: 'Unable to save your settings',
		description: 'Error title informing the user saving board settings failed.',
	},
	saveSettingsFailedMessage: {
		id: 'work-management-board.header.save-reset-view-settings.save-settings-failed-message',
		defaultMessage: "We couldn't save your settings, try refreshing the page or try again later.",
		description: 'Error message informing the user saving board settings failed.',
	},
	resetSettingsFailedTitle: {
		id: 'work-management-board.header.save-reset-view-settings.reset-settings-failed-title',
		defaultMessage: 'Unable to reset your settings',
		description: 'Error title informing the user resetting board settings failed.',
	},
	resetSettingsFailedMessage: {
		id: 'work-management-board.header.save-reset-view-settings.reset-settings-failed-message',
		defaultMessage: "We couldn't reset your settings, try refreshing the page or try again later.",
		description: 'Error message informing the user resetting board settings failed.',
	},
	saveConfirmationModalTitle: {
		id: 'work-management-board.header.save-reset-view-settings.save-confirmation-modal-title',
		defaultMessage: 'Save board view',
		description: 'Title for the modal to confirm saving the board view settings',
	},
	saveConfirmationModalBody: {
		id: 'work-management-board.header.save-reset-view-settings.save-confirmation-modal-description',
		defaultMessage:
			"The board will be saved for everyone in the project. They'll see the applied filters, grouping, fields, and columns.",
		description: 'Description for the modal to confirm saving the board view settings',
	},
	saveButton: {
		id: 'work-management-board.header.save-reset-view-settings.save-confirmation-modal-button',
		defaultMessage: 'Save',
		description: 'Label for the button to confirm saving the board view settings',
	},
	cancelButton: {
		id: 'work-management-board.header.save-reset-view-settings.cancel-confirmation-modal-button',
		defaultMessage: 'Cancel',
		description: 'Label for the button to cancel saving the board view settings',
	},
	onboardingNudgeTitle: {
		id: 'work-management-board.header.save-reset-view-settings.onboarding-nudge-title',
		defaultMessage: 'Save and reset your board view',
		description: 'Title for the onboarding nudge to save the board view settings',
	},
	onboardingNudgeDescription: {
		id: 'work-management-board.header.save-reset-view-settings.onboarding-nudge-description',
		defaultMessage:
			'Save your view for everyone in the project or reset it back to the default settings.',
		description: 'Description for the onboarding nudge to save the board view settings',
	},
	onboardingNudgeAcknowledged: {
		id: 'work-management-board.header.save-reset-view-settings.onboarding-nudge-acknowledged',
		defaultMessage: 'OK',
		description: 'Label for the button to acknowledge the onboarding nudge',
	},
});
