/**
 * @generated SignedSource<<1050567b6fdfa2508f192ede0de6fed1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_Content_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCardActions_project">;
  readonly " $fragmentType": "BoardCard_Content_project";
};
export type BoardCard_Content_project$key = {
  readonly " $data"?: BoardCard_Content_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_Content_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_Content_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCardActions_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "bd1737805b4ed8bec8810a9b6aac4415";

export default node;
