/**
 * @generated SignedSource<<6f34629355f5d61423d95a69af490f56>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type IssuesByGroupProvider_view$data = {
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly " $fragmentType": "IssuesByGroupProvider_view";
};
export type IssuesByGroupProvider_view$key = {
  readonly " $data"?: IssuesByGroupProvider_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssuesByGroupProvider_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "IssuesByGroupProvider_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "0d0525a4fc8b7803ce3746585687d3aa";

export default node;
