import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import srcDark from '../../assets/business-board-empty-state-darkmode.svg';
import srcLight from '../../assets/business-board-empty-state.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;
