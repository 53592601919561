/** @jsx jsx */
import React, { memo, useCallback } from 'react';
import { jsx, css } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import Badge from '@atlaskit/badge';
import Lozenge from '@atlaskit/lozenge';
import SimpleTag from '@atlaskit/tag/simple-tag';
import { token } from '@atlaskit/tokens';
import CategoryTag from '@atlassian/jira-business-categories/src/ui/category-form/category-tag/index.tsx';
import { HierarchyIcon } from '@atlassian/jira-business-fields/src/common/field-types/parent/icon/hierarchy-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Timestamp from '@atlassian/jira-issue-timestamp/src/main.tsx';
import { CardLabels } from '@atlassian/jira-platform-card/src/common/ui/labels/index.tsx';
import {
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	SUMMARY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { DateOnly } from '@atlassian/jira-platform-utils-date-only/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { CardDetailFields_cardOptions$key } from '@atlassian/jira-relay/src/__generated__/CardDetailFields_cardOptions.graphql';
import {
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_COMPONENTS,
	BOARD_FIELD_DATE,
	BOARD_FIELD_TEXT,
	BOARD_FIELD_FLOAT,
	BOARD_FIELD_DATE_TIME,
	BOARD_FIELD_USER,
	BOARD_FIELD_MULTI_USER,
	BOARD_FIELD_SELECT,
	BOARD_FIELD_TIME_ORIGINAL_ESTIMATE,
	BOARD_FIELD_STORY_POINTS,
	BOARD_FIELD_PARENT,
} from '../../../../../../common/constants.tsx';
import type { BoardIssue, BoardIssueField } from '../../../../../../common/types.tsx';

type FieldRendererArgs = {
	field: BoardIssueField;
	formatDate: (date: DateOnly | null) => string;
	formatNumber: (number: number | bigint) => string;
	TimeStampComponent: typeof Timestamp;
};

const DATE_FORMAT_OPTIONS = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
} as const;

const TimeStampCss = {
	whenDisplaySettingSwitchable: css({
		'&:hover': {
			color: token('color.text.subtle'),
		},
	}),
};

const parentFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_PARENT &&
	(isVisualRefreshEnabled() ? (
		<div css={detailRowStyles} key={field.fieldId}>
			<Lozenge isBold>{`${field.parent?.key} ${field.parent?.summary}`}</Lozenge>
		</div>
	) : (
		<div css={[detailRowStyles, noIndentStyles]} key={field.fieldId}>
			<SimpleTag
				elemBefore={<HierarchyIcon size={20} />}
				text={`${field.parent?.key} ${field.parent?.summary}`}
			/>
		</div>
	));

const categoryFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_CATEGORY && (
		<div css={[detailRowStyles, noIndentStyles]} key={field.fieldId}>
			<div
				css={[fieldLabelStyles, indentStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}
			>
				{field.name}
			</div>
			<div css={categoryTagContainerStyles}>
				<CategoryTag categoryName={field.category.name} categoryColor={field.category.color} />
			</div>
		</div>
	);

const componentFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_COMPONENTS &&
	field.value.length > 0 && (
		<div css={[detailRowStyles, noIndentStyles]} key={field.fieldId}>
			<div
				css={[fieldLabelStyles, indentStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}
			>
				{field.name}
			</div>
			{field.value.map((component) => (
				<SimpleTag key={component} text={component} />
			))}
		</div>
	);

const dateRenderer = ({ field, formatDate }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_DATE &&
	field.value != null && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			{isVisualRefreshEnabled() ? (
				<span css={[subtleTextStyle, isVisualRefreshEnabled() && subtleTextVisualRefreshStyles]}>
					{formatDate(DateOnly.fromISODateString(field.value))}
				</span>
			) : (
				<div css={lozengeWrapperStyles}>
					<Lozenge>{formatDate(DateOnly.fromISODateString(field.value))}</Lozenge>
				</div>
			)}
		</div>
	);

const textAndNumberRenderer = ({ field, formatNumber }: FieldRendererArgs) =>
	(field.type === BOARD_FIELD_TEXT || field.type === BOARD_FIELD_FLOAT) && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			<span css={[subtleTextStyle, isVisualRefreshEnabled() && subtleTextVisualRefreshStyles]}>
				{field.type === BOARD_FIELD_FLOAT ? formatNumber(field.value) : field.value}
			</span>
		</div>
	);

const dateTimeRenderer = ({ field, TimeStampComponent }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_DATE_TIME && (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			css={detailRowStyles}
			key={field.fieldId}
			onClick={(e) => e.stopPropagation()}
			onKeyDown={() => noop}
		>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			<span
				css={[
					timestampContainerStyles,
					isVisualRefreshEnabled() && timestampContainerRefreshStyles,
				]}
			>
				<TimeStampComponent
					value={field.value}
					// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.
					extraStyles={TimeStampCss}
				/>
			</span>
		</div>
	);

const userRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_USER &&
	field.user && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			<Avatar name={field.user.name} src={field.user.avatarURL ?? undefined} size="small" />
			<span css={[subtleTextStyle, isVisualRefreshEnabled() && subtleTextVisualRefreshStyles]}>
				{field.user.name}
			</span>
		</div>
	);

const multiUserRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_MULTI_USER &&
	field.users.length > 0 && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			<AvatarGroup
				appearance="stack"
				data={field.users.map((user) => ({
					name: user.name,
					src: user.avatarURL ?? undefined,
					enableTooltip: true,
				}))}
				maxCount={10}
				size="small"
			/>
			{field.users.length === 1 && (
				<span css={[subtleTextStyle, isVisualRefreshEnabled() && subtleTextVisualRefreshStyles]}>
					{field.users[0].name}
				</span>
			)}
		</div>
	);

const selectFieldRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_SELECT &&
	field.value.length > 0 && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={[fieldLabelStyles, isVisualRefreshEnabled() && fieldLabelRefreshStyles]}>
				{field.name}
			</div>
			{field.value.length > 1 ? (
				<CardLabels labels={field.value} />
			) : (
				<span css={[subtleTextStyle, isVisualRefreshEnabled() && subtleTextVisualRefreshStyles]}>
					{field.value[0]}
				</span>
			)}
		</div>
	);

const timeOriginalEstimateRenderer = ({ field }: FieldRendererArgs) =>
	field.type === BOARD_FIELD_TIME_ORIGINAL_ESTIMATE && (
		<div css={detailRowStyles} key={field.fieldId}>
			<div css={inlineLabelStyles}>{field.name}</div>
			<div css={inlineContentStyles}>
				<Badge>{field.value}</Badge>
			</div>
		</div>
	);

const FIELD_RENDERERS: {
	[key: string]: (arg0: FieldRendererArgs) => false | React.JSX.Element | undefined;
} = {
	[BOARD_FIELD_PARENT]: parentFieldRenderer,
	[BOARD_FIELD_CATEGORY]: categoryFieldRenderer,
	[BOARD_FIELD_COMPONENTS]: componentFieldRenderer,
	[BOARD_FIELD_DATE]: dateRenderer,
	[BOARD_FIELD_TEXT]: textAndNumberRenderer,
	[BOARD_FIELD_USER]: userRenderer,
	[BOARD_FIELD_MULTI_USER]: multiUserRenderer,
	[BOARD_FIELD_SELECT]: selectFieldRenderer,
	[BOARD_FIELD_FLOAT]: textAndNumberRenderer,
	[BOARD_FIELD_STORY_POINTS]: textAndNumberRenderer,
	[BOARD_FIELD_TIME_ORIGINAL_ESTIMATE]: timeOriginalEstimateRenderer,
	[BOARD_FIELD_DATE_TIME]: dateTimeRenderer,
};

const FIELD_TYPE_ORDER = Object.keys(FIELD_RENDERERS);

const EXCLUDED_SYSTEM_FIELDS = new Set<string>([
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	SUMMARY_TYPE,
]);

export type Props = { cardOptionsFragment: CardDetailFields_cardOptions$key; issue: BoardIssue };

export const CardDetailFields = memo(({ cardOptionsFragment, issue }: Props) => {
	const cardOptions = useFragment(
		graphql`
			fragment CardDetailFields_cardOptions on JiraBoardViewCardOptionConnection {
				edges @required(action: THROW) {
					node @required(action: THROW) {
						... on JiraBoardViewFieldCardOption {
							field @required(action: THROW) {
								fieldId @required(action: THROW)
							}
						}
					}
				}
			}
		`,
		cardOptionsFragment,
	);

	const selectedFields = new Set(
		cardOptions.edges.map((edge) => edge?.node?.field && edge.node.field.fieldId),
	);

	const { formatDate: formatDateIntl, formatNumber } = useIntl();
	const formatDate = useCallback(
		(date: DateOnly | null) =>
			date ? formatDateIntl(date.toLocalDate(), DATE_FORMAT_OPTIONS) : '',
		[formatDateIntl],
	);

	const fields = Object.values(issue.fields)
		?.filter((field) => {
			/**
			 * Filter out field based off the following criteria:
			 * * If the field is not selected via customized card configuration
			 * * If the field type is not in the FIELD_TYPE_ORDER (i.e. no renderer for field type available)
			 * * If the field is in the EXCLUDED_SYSTEM_FIELDS
			 */
			if (
				!selectedFields.has(field.fieldId) ||
				!FIELD_TYPE_ORDER.includes(field.type) ||
				EXCLUDED_SYSTEM_FIELDS.has(field.fieldId)
			) {
				return false;
			}

			return true;
		})
		.sort(
			(field1, field2) =>
				FIELD_TYPE_ORDER.indexOf(field1.type) - FIELD_TYPE_ORDER.indexOf(field2.type),
		)
		.map(
			(field) =>
				FIELD_RENDERERS[field.type]?.({
					field,
					formatDate,
					formatNumber,
					TimeStampComponent: Timestamp,
				}) ?? null,
		);

	return fields;
});

const detailRowStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	columnGap: token('space.050'),
	with: '100%',
	marginLeft: token('space.0'),
});

const noIndentStyles = css({
	marginLeft: token('space.negative.050'),
});

const fieldLabelStyles = css({
	color: token('color.text.subtle'),
	width: '100%',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	font: token('font.body.small'),
	fontWeight: token('font.weight.medium'),
	marginLeft: token('space.0'),
});

const indentStyles = css({
	marginLeft: token('space.050'),
});

const fieldLabelRefreshStyles = css({
	color: token('color.text.subtlest'),
});

const inlineLabelStyles = css({
	color: token('color.text.subtle'),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	font: token('font.body.small'),
	fontWeight: token('font.weight.medium'),
	marginLeft: token('space.0'),
});

const inlineContentStyles = css({
	marginLeft: 'auto',
});

const subtleTextVisualRefreshStyles = css({
	color: token('color.text'),
});

const subtleTextStyle = css({
	color: token('color.text.subtlest'),
	font: token('font.body.UNSAFE_small'),
});

const lozengeWrapperStyles = css({
	marginLeft: 0,
});

const timestampContainerRefreshStyles = css({
	color: token('color.text'),
});

const timestampContainerStyles = css({
	color: token('color.text.subtlest'),
	font: token('font.body.UNSAFE_small'),
	'&:hover': {
		color: token('color.text.subtle'),
	},
});

const categoryTagContainerStyles = css({
	paddingTop: token('space.050'),
	paddingLeft: token('space.050'),
	minWidth: 0,
});
