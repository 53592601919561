/**
 * @generated SignedSource<<5a03d3cb293bba7d1b6792c27c5c2d34>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_view$data = {
  readonly columns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_view">;
  readonly " $fragmentType": "BoardContent_view";
};
export type BoardContent_view$key = {
  readonly " $data"?: BoardContent_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_view">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "columns"
        ]
      }
    ]
  },
  "name": "BoardContent_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_Inner_view"
    },
    {
      "kind": "RequiredField",
      "field": (v0/*: any*/),
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "columns",
        "concreteType": "JiraBoardViewColumnConnection",
        "kind": "LinkedField",
        "name": "__business_board_columns_connection",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraBoardViewColumnEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                }
              ]
            },
            "action": "THROW",
            "path": "columns.edges"
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "columns"
    }
  ],
  "type": "JiraBoardView"
};
})();

(node as any).hash = "ca6bd978a5c0d54bebc57dd1f0d9def5";

export default node;
