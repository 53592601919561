import React from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const HierarchyIconOld = ({ size = 24 }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="4.33333" cy="4.66732" r="1.33333" stroke="#9FADBC" strokeWidth="1.33333" />
		<circle cx="10.9998" cy="11.3333" r="1.33333" stroke="#9FADBC" strokeWidth="1.33333" />
		<path
			d="M4.3335 6V8.66667C4.3335 10.1394 5.5274 11.3333 7.00016 11.3333H9.66683"
			stroke="#9FADBC"
			strokeWidth="1.33333"
		/>
	</svg>
);

const HierarchyIconNew = ({ size = 24 }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="4.33333" cy="4.66732" r="1.33333" stroke="currentColor" strokeWidth="1" />
		<circle cx="10.9998" cy="11.3333" r="1.33333" stroke="currentColor" strokeWidth="1" />
		<path
			d="M4.3335 6V8.66667C4.3335 10.1394 5.5274 11.3333 7.00016 11.3333H9.66683"
			stroke="currentColor"
			strokeWidth="1"
		/>
	</svg>
);

export const HierarchyIcon = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_6'),
	HierarchyIconNew,
	HierarchyIconOld,
);
