import type { Maybe, MutationErrorPayload } from './types.tsx';
import { combineErrorMessages } from './utils.tsx';

/**
 * Class representing a collection of mutation errors.
 * Extends the built-in `Error` class.
 *
 * @remarks
 * This class is used to handle and represent multiple mutation errors
 * as a single error instance.
 *
 * The resulting message will be a combination of these parts, separated by spaces, and multiple error messages will be separated by semi-colons.
 *
 * Example formats:
 * - (GraphQLTypeName) [NOT_FOUND] HTTP 404: error message
 * - [NOT_FOUND] HTTP 404: error message
 * - [NOT_FOUND] error message
 * - HTTP 404: error message
 * - error message
 *
 * @param errors - An optional array of mutation error payloads.
 *
 * @example
 * ```typescript
 * const errors = [
 *   { message: 'Error 1' },
 *   { message: 'Error 2', extensions: { errorType: 'NOT_FOUND' } },
 *   { message: 'Error 3', extensions: { statusCode: 404 } },
 *   { message: 'Error 4', __typename: 'GraphQLTypeName' },
 * ];
 * const mutationErrors = new MutationErrorCollection(errors);
 * ```
 */
export class MutationErrorCollection extends Error {
	constructor(errors: Maybe<ReadonlyArray<MutationErrorPayload>>) {
		const errorMessage = combineErrorMessages(errors) ?? 'Unknown error';
		super(errorMessage);
		this.message = errorMessage;
	}
}
