import { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import type { DocumentNode } from 'graphql';
import { generateJql } from '@atlassian/jira-business-common/src/common/utils/get-variables/index.tsx';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { useFilteredViewJQL } from '@atlassian/jira-business-filters/src/controllers/default-query-input/index.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import type {
	CreateIssueParameters,
	ExtraCreateIssuePayload,
} from '@atlassian/jira-business-issue-create/src/types.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import { ISSUE_LINKS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIsLinkingEnabled } from '@atlassian/jira-business-issue-linking/src/IssueLinkingProvider.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { CARD_COVER } from '../../common/constants.tsx';
import type {
	JWMBoardInlineCreateIssue_createJiraBusinessIssue_item_fieldsByIdOrAlias_JiraSingleLineTextField as IssueKeyField,
	JWMBoardInlineCreateIssueVariables,
	JWMBoardInlineCreateIssue,
} from './__generated_apollo__/JWMBoardInlineCreateIssue';
import type {
	JWMBoardInlineCreateRefetchQueryVariables,
	JWMBoardInlineCreateRefetchQuery,
} from './__generated_apollo__/JWMBoardInlineCreateRefetchQuery';
import { CREATE_ISSUE_MUTATION, ISSUE_REFETCH_QUERY } from './gql.tsx';
import { buildInput } from './utils.tsx';

export function useLazyQuery<TData, TVariables>(query: DocumentNode) {
	const client = useApolloClient();
	return useCallback(
		(variables: TVariables) =>
			client.query<TData, TVariables>({
				query,
				variables,
				errorPolicy: 'all',
			}),
		[client, query],
	);
}

type Props = {
	categoryFieldId: string | null;
};

export const useCreateIssue = ({ categoryFieldId }: Props) => {
	const isLinkingEnabled = useIsLinkingEnabled();
	const cloudId = useCloudId();
	const jqlContext = useJqlContext();
	const filtersJQL = useFilteredViewJQL();

	const refetchIssue = useLazyQuery<
		JWMBoardInlineCreateRefetchQuery,
		JWMBoardInlineCreateRefetchQueryVariables
	>(ISSUE_REFETCH_QUERY);

	const [createIssue] = useMutation<JWMBoardInlineCreateIssue, JWMBoardInlineCreateIssueVariables>(
		CREATE_ISSUE_MUTATION,
	);

	const createIssueService = useCallback(
		async (createParams: CreateIssueParameters, extraPayload: ExtraCreateIssuePayload = {}) => {
			const input = buildInput(createParams.input, extraPayload, categoryFieldId);
			const fieldIdsSet = new Set(createParams.fieldIdsToReturn);

			const withIssueLinks = fieldIdsSet.has(ISSUE_LINKS_TYPE) && isLinkingEnabled;
			fieldIdsSet.delete(ISSUE_LINKS_TYPE);

			const withCoverMedia = fieldIdsSet.has(CARD_COVER);
			fieldIdsSet.delete(CARD_COVER);

			const fieldIds = Array.from(fieldIdsSet);

			const { data, errors } = await createIssue({
				variables: {
					input,
					fieldIds,
					withCoverMedia,
					withChildrenCount: false,
					withIssueLinks,
				},
				// @ts-expect-error - errorPolicy is not in the types but does work
				errorPolicy: 'all',
			});

			const result = data?.createJiraBusinessIssue;
			if (result?.success !== true || !result?.item) {
				const firstMutationError = result?.errors?.[0];

				if (firstMutationError) {
					const statusCode = firstMutationError.extensions?.statusCode;
					if (statusCode && statusCode >= 400 && statusCode < 500) {
						throw new ValidationError(firstMutationError.message ?? 'Failed to create issue');
					}

					throw new MutationError(firstMutationError);
				}

				throw new GraphQLErrors(errors);
			}

			return {
				item: result.item,
				itemIdentifiers: {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issueId: result.itemIdentifiers!.issueId,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-non-null-assertion
					issueKey: (result.itemIdentifiers!.issueKey![0] as IssueKeyField).text!,
				},
			};
		},
		[categoryFieldId, createIssue, isLinkingEnabled],
	);

	const refetchIssueService = useCallback(
		async (issueId: number, fieldIds: string[], withFiltersContext: boolean) => {
			const jql = generateJql({
				jqlContext,
				quickFiltersJql: withFiltersContext ? filtersJQL : undefined,
				issueIds: [issueId],
			});

			const fieldIdsSet = new Set(fieldIds);

			const withIssueLinks = fieldIdsSet.has(ISSUE_LINKS_TYPE) && isLinkingEnabled;
			fieldIdsSet.delete(ISSUE_LINKS_TYPE);

			const withCoverMedia = fieldIdsSet.has(CARD_COVER);
			fieldIdsSet.delete(CARD_COVER);

			const fieldIdsToRequest = Array.from(fieldIdsSet);

			const { data } = await refetchIssue({
				jql,
				fieldIds: fieldIdsToRequest,
				cloudId,
				first: 1,
				withCoverMedia,
				withChildrenCount: false,
				withIssueLinks,
			});

			const viewItems = data?.jira?.jwmViewItems;

			if (!viewItems || viewItems.__typename === 'QueryError') {
				throw new Error('Issue not found');
			}

			const issue = viewItems.edges?.[0]?.node;

			return issue ?? null;
		},
		[cloudId, filtersJQL, jqlContext, refetchIssue, isLinkingEnabled],
	);

	return {
		createIssue: createIssueService,
		refetchIssue: refetchIssueService,
	};
};
