// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStore,
	createStateHook,
	type StoreActionApi,
	createActionsHook,
} from '@atlassian/react-sweet-state';

export const CardCoverEditingContainer = createContainer();

type State = { isOpen: boolean };

const actions = {
	setOpen:
		(isOpen: boolean) =>
		({ setState }: StoreActionApi<State>) => {
			setState({ isOpen });
		},
};

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'BusinessBoardCardCoverEditingStore',
	initialState: { isOpen: false },
	actions,
	containedBy: CardCoverEditingContainer,
});

export const useIsCardCoverEditingOpen = createStateHook(store, {
	selector: (state) => state.isOpen,
});

export const useCardCoverEditingActions = createActionsHook(store);
