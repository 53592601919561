/**
 * @generated SignedSource<<e49c9a75b82d5a179e7ffc8fab861df4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CardDetailFields_cardOptions$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly field?: {
        readonly fieldId: string;
      };
    };
  } | null | undefined>;
  readonly " $fragmentType": "CardDetailFields_cardOptions";
};
export type CardDetailFields_cardOptions$key = {
  readonly " $data"?: CardDetailFields_cardOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardDetailFields_cardOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CardDetailFields_cardOptions",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraBoardViewCardOptionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraField",
                        "kind": "LinkedField",
                        "name": "field",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "kind": "ScalarField",
                              "name": "fieldId"
                            },
                            "action": "THROW",
                            "path": "edges.node.field.fieldId"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "edges.node.field"
                    }
                  ],
                  "type": "JiraBoardViewFieldCardOption"
                }
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    }
  ],
  "type": "JiraBoardViewCardOptionConnection"
};

(node as any).hash = "87681975a68254e1e024103652fa7b6e";

export default node;
