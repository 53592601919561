/**
 * @generated SignedSource<<4d4c5843f2787325e3d0274e324de77d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type PriorityColumnHeader_column$data = {
  readonly collapsed: boolean;
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
    readonly name: string;
  };
  readonly " $fragmentType": "PriorityColumnHeader_column";
};
export type PriorityColumnHeader_column$key = {
  readonly " $data"?: PriorityColumnHeader_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriorityColumnHeader_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "PriorityColumnHeader_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "collapsed"
      },
      "action": "THROW",
      "path": "collapsed"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraPriority",
        "kind": "LinkedField",
        "name": "priority",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "name"
            },
            "action": "THROW",
            "path": "priority.name"
          },
          {
            "kind": "ScalarField",
            "name": "iconUrl"
          }
        ]
      },
      "action": "THROW",
      "path": "priority"
    }
  ],
  "type": "JiraBoardViewPriorityColumn"
};

(node as any).hash = "9558f76cea7c50adf2475a0cb448f925";

export default node;
