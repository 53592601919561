import { useEffect, useCallback } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createStateHook,
	createActionsHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { getFlags, setFlag } from '@atlassian/jira-common-have-i-seen-it/src/index.tsx';
import type { HaveISeenItFlags } from '@atlassian/jira-common-have-i-seen-it/src/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

const TTL_SEEN = 1000 * 60 * 60 * 24 * 365; // ~ 365 days

export const RENAME_ONBOARDING = 'board-saved-views-rename';
export const SAVE_VIEW_ONBOARDING = 'board-saved-views-save-view';

/**
 * Delete this package when cleaning up saved_views experiment
 */

type OnboardingKey = typeof RENAME_ONBOARDING | typeof SAVE_VIEW_ONBOARDING;

type State = {
	[key in OnboardingKey]: boolean;
};

const actions = {
	initialize:
		(seenItResponse: HaveISeenItFlags) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				[RENAME_ONBOARDING]: seenItResponse[RENAME_ONBOARDING]?.status !== true,
				[SAVE_VIEW_ONBOARDING]: seenItResponse[SAVE_VIEW_ONBOARDING]?.status !== true,
			});
		},
	markAsSeen:
		(key: OnboardingKey) =>
		({ setState, getState }: StoreActionApi<State>) =>
			setState({ ...getState(), [key]: false }),
};

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'SavedViewsOnboardingStore',
	initialState: {
		[RENAME_ONBOARDING]: false,
		[SAVE_VIEW_ONBOARDING]: false,
	},
	actions,
});

const useActions = createActionsHook(store);

const getOnboardingByKey = (state: State, args: OnboardingKey[]) => state[args[0]];

const useOnboardingState = createStateHook(store, {
	selector: getOnboardingByKey,
});

export const SavedViewsOnboardingInitializer = () => {
	const { initialize } = useActions();

	useEffect(() => {
		if (!expVal('saved_views', 'enabled', false)) {
			return;
		}

		if (__SERVER__) {
			return;
		}

		getFlags({
			[RENAME_ONBOARDING]: {},
			[SAVE_VIEW_ONBOARDING]: {},
		})
			.then(initialize)
			.catch(() => {
				// Ignore errors
			});
	}, [initialize]);

	return null;
};

export const useOnboarding = (key: OnboardingKey) => {
	const { markAsSeen } = useActions();
	const isOnboardingVisible = useOnboardingState([key]);

	const markOnboardingAsSeen = useCallback(() => {
		markAsSeen(key);
		setFlag(key, TTL_SEEN);
	}, [key, markAsSeen]);

	return [isOnboardingVisible, markOnboardingAsSeen] as const;
};
