import React, { useMemo, type SyntheticEvent, useCallback } from 'react';
import noop from 'lodash/noop';
import { Pressable, xcss, Stack } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { asyncCreateIssueTypeModalEntryPoint } from '@atlassian/jira-create-issue-type-modal/entrypoint.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { LIST_VIEW_SOURCE } from '@atlassian/jira-issue-type-config-common/src/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getIssueTypesSettingsUrl } from '@atlassian/jira-business-issue-types/src/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import messages from '../messages.tsx';
import { CREATE_ISSUE_TYPE_EXPERIENCE } from '../../../constants.tsx';

type SelectFooterProps = {
	shouldShowManageType?: boolean;
	onIssueTypeCreated?: () => void;
	onCreateIssueTypeClick: () => void;
	onCreateIssueTypeClose: () => void;
	allowIssueTypeConfiguration?: boolean;
};

const fireExposureForCombinedImpactAAExperiment = () => {
	expVal('combined_adoption_configuration_experiment_aa', 'isEnabled', false);
	return true;
};

export const SelectFooter = ({
	shouldShowManageType = true,
	allowIssueTypeConfiguration = true,
	onIssueTypeCreated = noop,
	onCreateIssueTypeClick,
	onCreateIssueTypeClose,
}: SelectFooterProps) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const modalConfigProps = useMemo(() => ({ width: 424 }), []);
	const { key: projectKey, isSimplified } = useProject();

	const manageIssueTypesLink = getIssueTypesSettingsUrl(projectKey, isSimplified);

	const handleCreateWorkTypeClick = (_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		onCreateIssueTypeClick();
		fireUIAnalytics(analyticsEvent, 'createIssueTypeButton');
	};

	const handleManageTypeClick = (_e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'manageIssueTypesButton');
		push(manageIssueTypesLink);
	};

	const onCloseCreateIssueTypesModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineCreateIssueTypeModal',
				action: 'closed',
			}),
		);
		onCreateIssueTypeClose();
	}, [createAnalyticsEvent, onCreateIssueTypeClose]);

	const entryPointProps = useMemo(
		() => ({
			onIssueTypeCreated,
			issueTypesCountLimit: 30,
			onClose: onCloseCreateIssueTypesModal,
			source: LIST_VIEW_SOURCE,
			ufoExperience: CREATE_ISSUE_TYPE_EXPERIENCE,
		}),
		[onCloseCreateIssueTypesModal, onIssueTypeCreated],
	);

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			projectKey,
		}),
		[cloudId, projectKey],
	);

	return (
		<Stack xcss={footerContainerStyles}>
			{allowIssueTypeConfiguration &&
				fireExposureForCombinedImpactAAExperiment() &&
				// NOTE: same FG in front of the following experiments, new activated tenants pass 100% for combined_adoption_configuration_experiment and fail 100% on the other...
				(expVal('jsw_inline_issuetype_creation_list_view', 'isInlineIssueTypesEnabled', false) ||
					expVal('combined_adoption_configuration_experiment', 'isEnabled', false)) && (
					<ModalEntryPointPressableTrigger
						entryPoint={asyncCreateIssueTypeModalEntryPoint}
						entryPointProps={entryPointProps}
						entryPointParams={entryPointParams}
						interactionName="create-issue-type-modal"
						modalProps={modalConfigProps}
						useInternalModal={false}
					>
						{({ ref }) => (
							<Pressable
								onClick={handleCreateWorkTypeClick}
								xcss={pressableStyles}
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								ref={ref as React.Ref<HTMLButtonElement>}
							>
								{formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.createIssueTypesIssueTermRefresh
										: messages.createIssueTypes,
								)}
							</Pressable>
						)}
					</ModalEntryPointPressableTrigger>
				)}

			{shouldShowManageType && (
				<Pressable onClick={handleManageTypeClick} xcss={pressableStyles}>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.manageIssueTypesIssueTermRefresh
							: messages.manageIssueTypes,
					)}
				</Pressable>
			)}
		</Stack>
	);
};

const footerContainerStyles = xcss({
	borderTop: '2px solid',
	borderTopColor: 'color.border',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	marginTop: 'space.050',
});
const pressableStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	textAlign: 'left',
	font: 'inherit',
	color: 'color.text',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
