import { useCallback } from 'react';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import { isModifiedEvent } from '@atlassian/jira-business-modified-click-event/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryParam } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export const useShowCreateSuccessFlag = () => {
	const { formatMessage } = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();
	const [, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);

	return useCallback(
		(issueKey: string) => {
			const flagId = `jwm-create-success-flag-${issueKey}`;
			showFlag({
				id: flagId,
				isAutoDismiss: true,
				type: 'success',
				title: formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.createSuccessFlagTitleIssueTermRefresh
						: messages.createSuccessFlagTitle,
					{
						issueKey,
					},
				),
				messageId: 'business-issue-create.controllers.show-create-success-flag.show-flag.success',
				messageType: 'transactional',
				actions: [
					{
						content: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.viewIssueLinkTextIssueTermRefresh
							: messages.viewIssueLinkText,
						href: `/browse/${issueKey}`,
						onClick: (event) => {
							if (event && !isModifiedEvent(event)) {
								setSelectedIssueKey(issueKey);
								event.preventDefault();
								event.stopPropagation();
							}
							dismissFlag(flagId);
						},
					},
				],
			});
		},
		[dismissFlag, formatMessage, setSelectedIssueKey, showFlag],
	);
};
