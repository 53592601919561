import { createContext, useContext } from 'react';
import type { BoardFeaturesContextProps } from './types.tsx';

const defaultFeaturesContext = {
	isShareButtonEnabled: true,
	isPresenceEnabled: true,
	withSaveRevertView: true,
	issueViewInteraction: 'default',
	isOnboardingEnabled: true,
	featureView: 'jwm-board',
	inviteButtonInteraction: 'default',
	typeSelectInteraction: 'default',
} as const;

const BusinessBoardFeaturesContext =
	createContext<BoardFeaturesContextProps>(defaultFeaturesContext);

const useJWMBoardFeatures = () => useContext(BusinessBoardFeaturesContext);

export default BusinessBoardFeaturesContext;
export { useJWMBoardFeatures };
