/**
 * @generated SignedSource<<db8753c743a4297a200307d957dd6622>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type StatusColumnHeader_project$data = {
  readonly canAdministerProject: {
    readonly canPerform: boolean;
  };
  readonly projectStyle: JiraProjectStyle;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_project">;
  readonly " $fragmentType": "StatusColumnHeader_project";
};
export type StatusColumnHeader_project$key = {
  readonly " $data"?: StatusColumnHeader_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusColumnHeader_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "StatusColumnHeader_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "canAdministerProject",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "EDIT_PROJECT_CONFIG"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "canPerform"
            },
            "action": "THROW",
            "path": "canAdministerProject.canPerform"
          }
        ],
        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
      },
      "action": "THROW",
      "path": "canAdministerProject"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectStyle"
      },
      "action": "THROW",
      "path": "projectStyle"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardHeaderDropdown_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "d08380cce943eda78dc8f99fe01644d8";

export default node;
