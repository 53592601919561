import type {
	CollapsedColumns,
	ColumnsOrder,
} from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import type { SortingAttributes } from '@atlassian/jira-business-common/src/common/types/sorting.tsx';
import { FILTER_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import type { Preference, FieldsDetailPreference } from './types.tsx';
import { createSimplePreference, createSimpleUrlPreference } from './utils.tsx';

export const ACTIVE_WORKFLOW_PREFERENCE: Preference<string> =
	createSimplePreference<string>('activeWorkflow');

export const COLLAPSED_COLUMNS_PREFERENCE: Preference<CollapsedColumns> =
	createSimplePreference<CollapsedColumns>('collapsedColumns');

export const COLUMNS_ORDER_PREFERENCE: Preference<ColumnsOrder> =
	createSimplePreference<ColumnsOrder>('columnOrder');

export const CREATE_TYPE_ID_PREFERENCE: Preference<string> = createSimplePreference('createType');

export const EXPANDED_ISSUES_PREFERENCE: Preference<number[]> =
	createSimplePreference('expandedIssues');

export const FIELDS_PREFERENCE: Preference<string[]> = createSimplePreference('fields');

export const FIELDS_DETAIL_PREFERENCE: Preference<FieldsDetailPreference> =
	createSimplePreference('fieldsDetail');

export const FILTER_PREFERENCE: Preference<string> = createSimpleUrlPreference(FILTER_PARAM);

export const GROUPING_PREFERENCE: Preference<string> = createSimpleUrlPreference('groupBy');

export const HIDE_DONE_ITEMS_PREFERENCE: Preference<string> = createSimpleUrlPreference('hideDone');

export const HIDE_SOFTWARE_RELEASE_PREFERENCE: Preference<string> =
	createSimpleUrlPreference('hideSoftwareRelease');

export const RANGE_MODE_PREFERENCE: Preference<string> = createSimpleUrlPreference('rangeMode');

export const SORTING_PREFERENCE: Preference<SortingAttributes> = {
	...createSimplePreference<SortingAttributes>('sorting'),
	queryParameter: {
		serialize: (value: SortingAttributes | undefined) => ({
			sortBy: value?.sortBy,
			direction: value?.direction,
		}),
		deserialize: (value: { [key: string]: string }) =>
			value.sortBy
				? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({
						sortBy: value.sortBy,
						direction: value.direction,
					} as SortingAttributes)
				: undefined,
	},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BOARD_VIEW_PREFERENCES: Preference<any>[] = [
	CREATE_TYPE_ID_PREFERENCE,
	EXPANDED_ISSUES_PREFERENCE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LIST_VIEW_PREFERENCES: Preference<any>[] = [
	CREATE_TYPE_ID_PREFERENCE,
	FIELDS_PREFERENCE,
	FIELDS_DETAIL_PREFERENCE,
	FILTER_PREFERENCE,
	GROUPING_PREFERENCE,
	HIDE_DONE_ITEMS_PREFERENCE,
	SORTING_PREFERENCE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CALENDAR_VIEW_PREFERENCES: Preference<any>[] = [
	CREATE_TYPE_ID_PREFERENCE,
	FILTER_PREFERENCE,
	HIDE_DONE_ITEMS_PREFERENCE,
	HIDE_SOFTWARE_RELEASE_PREFERENCE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TIMELINE_VIEW_PREFERENCES: Preference<any>[] = [
	CREATE_TYPE_ID_PREFERENCE,
	FILTER_PREFERENCE,
	HIDE_DONE_ITEMS_PREFERENCE,
	RANGE_MODE_PREFERENCE,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ATTACHMENTS_VIEW_PREFERENCES: Preference<any>[] = [FILTER_PREFERENCE];
