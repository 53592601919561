/**
 * @generated SignedSource<<a34ed674e96e06d60703da5eca52461b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardHeaderDropdown_status$data = {
  readonly statusId: string;
  readonly " $fragmentType": "BoardHeaderDropdown_status";
};
export type BoardHeaderDropdown_status$key = {
  readonly " $data"?: BoardHeaderDropdown_status$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeaderDropdown_status">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardHeaderDropdown_status",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "statusId"
      },
      "action": "THROW",
      "path": "statusId"
    }
  ],
  "type": "JiraStatus"
};

(node as any).hash = "c6a4e6e11da61aa0631a5871bcf43d56";

export default node;
