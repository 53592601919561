import React, { memo, useCallback, useState, type ComponentType } from 'react';
import '@atlaskit/css-reset';
import {
	type EntryPointProps,
	usePreloadedQuery,
	useQueryLoader,
	graphql,
	useRelayEnvironment,
	fetchQuery,
} from 'react-relay';
import { ProjectContextProvider } from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/ProjectContextProvider.tsx';
import { BOARD_VIEW_PREFERENCES } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { ViewPreferencesProvider } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import BoardQuery, {
	type src_jiraBusinessBoardQuery,
} from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessBoardQuery.graphql';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import BusinessBoard from '@atlassian/jira-work-management-board/src/BusinessBoard.tsx';
import { getUrlSettingsInput } from '@atlassian/jira-work-management-board/src/common/url-settings-input.tsx';

type JiraBusinessBoardAppType = EntryPointProps<
	{ boardQuery: src_jiraBusinessBoardQuery },
	{},
	{},
	{}
>;

const JiraBusinessBoardApp: ComponentType<JiraBusinessBoardAppType> = ({
	queries: { boardQuery },
}) => {
	const environment = useRelayEnvironment();
	const [queryRef, loadQuery] = useQueryLoader(BoardQuery, boardQuery);
	const [isRefreshing, setIsRefreshing] = useState(false);
	if (!queryRef) {
		throw new Error('Query reference not found');
	}

	const data = usePreloadedQuery<src_jiraBusinessBoardQuery>(
		graphql`
			query src_jiraBusinessBoardQuery(
				$cloudId: ID!
				$projectKey: String!
				$viewInput: JiraBoardViewInput!
			) @preloadable {
				...BusinessBoard_query
				jira {
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey) {
						...BusinessBoard_project
						...ProjectContextProvider
					}
				}
				jira_boardView(input: $viewInput) @required(action: THROW) {
					id @required(action: THROW)
					...BusinessBoard_view
				}
			}
		`,
		queryRef,
	);

	const refresh = useCallback(() => {
		if (isRefreshing) {
			return;
		}
		const { variables } = boardQuery;
		const newVariables = {
			...variables,
			viewInput: { ...variables.viewInput, settings: getUrlSettingsInput() },
		};
		setIsRefreshing(true);
		// using fetchQuery to avoid triggering any suspense/loading state
		fetchQuery(environment, BoardQuery, newVariables).subscribe({
			complete: () => {
				setIsRefreshing(false);
				loadQuery(newVariables, { fetchPolicy: 'store-only' });
			},
			error: () => {
				setIsRefreshing(false);
			},
		});
	}, [boardQuery, environment, isRefreshing, loadQuery]);

	return (
		<UFOSegment name="business-board-app">
			<AuthenticationCheck>
				<ProjectContextProvider projectFragment={data.jira?.jiraProjectByKey}>
					<ViewPreferencesProvider view="board" preferences={BOARD_VIEW_PREFERENCES}>
						{data.jira?.jiraProjectByKey && (
							<BusinessBoard
								key={data.jira_boardView.id}
								queryFragment={data}
								projectFragment={data.jira.jiraProjectByKey}
								viewFragment={data.jira_boardView}
								refresh={refresh}
							/>
						)}
					</ViewPreferencesProvider>
				</ProjectContextProvider>
				<SubProductUpdater subProduct="core" />
			</AuthenticationCheck>
		</UFOSegment>
	);
};

export default memo(JiraBusinessBoardApp);
