import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	calenderInputAriaLabel: {
		id: 'issue-field-due-date-picker-input-aria-label-non-final',
		defaultMessage:
			'On the use of up and down arrow keys into the following input field, the date will update below',
		description:
			'The text for the date input informs the user that using the arrow keys (up and down) will update the date picker below',
	},
	invalidDateError: {
		id: 'issue-field-due-date-picker-invalid-date-error-non-final',
		defaultMessage: 'Enter a valid date',
		description:
			'Error message when the date typed in is invalid,requesting they inputs a new date',
	},
	dueDateText: {
		id: 'issue-field-due-date-picker-due-date-text-non-final',
		defaultMessage: 'Due date',
		description: 'Text for the due date input field',
	},
});
