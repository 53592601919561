/**
 * @generated SignedSource<<d3c8fa82bbb601383fb882c990a3907c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardDataProvider_project$data = {
  readonly key: string;
  readonly " $fragmentType": "BoardDataProvider_project";
};
export type BoardDataProvider_project$key = {
  readonly " $data"?: BoardDataProvider_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardDataProvider_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardDataProvider_project",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "e16b750e8a7273f8bdbd3759742ff9a4";

export default node;
