/**
 * @generated SignedSource<<e4f4507bf4bd7130602c95e78541a834>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_project">;
  readonly " $fragmentType": "BusinessBoard_project";
};
export type BusinessBoard_project$key = {
  readonly " $data"?: BusinessBoard_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BusinessBoard_withWorkflow_project"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "9f07241b1148b1c97d5f1d47d5f492bd";

export default node;
