/**
 * @generated SignedSource<<e8060ad88d3b3a55f198220caf5dcdb7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { UpdatableFragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_updatable$data = {
  collapsed: boolean | null | undefined;
  readonly " $fragmentType": "BoardColumn_updatable";
};
export type BoardColumn_updatable$key = {
  readonly " $data"?: BoardColumn_updatable$data;
  readonly $updatableFragmentSpreads: FragmentRefs<"BoardColumn_updatable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumn_updatable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "collapsed"
    }
  ],
  "type": "JiraBoardViewColumn",
  "abstractKey": "__isJiraBoardViewColumn"
};

(node as any).hash = "7e9f8626b6287567aefc198a72ac29c5";

export default node;
