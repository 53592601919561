/**
 * @generated SignedSource<<42f152ebc3a1cd3a7330fdd9a45b3d82>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_query">;
  readonly " $fragmentType": "BusinessBoard_query";
};
export type BusinessBoard_query$key = {
  readonly " $data"?: BusinessBoard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoard_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BusinessBoard_withWorkflow_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "f99ec3c2d35a75668d7e0bbd92a6cde2";

export default node;
