/** @jsx jsx */
import { type ReactNode, useRef, useState, useLayoutEffect, useEffect } from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';

type Props = {
	animationDuration?: number;
	children: ReactNode;
	direction?: 'horizontal' | 'vertical';
	id?: string;
	isExpanded: boolean;
};

const getSize = (element: HTMLElement, direction: 'horizontal' | 'vertical') => {
	return direction === 'horizontal' ? element.scrollWidth : element.scrollHeight;
};

const setCollapsedStyle = (wrapper: HTMLElement, direction: 'horizontal' | 'vertical') => {
	// set max width to 0 and opacity to 0
	wrapper.style.setProperty(`max-${direction === 'horizontal' ? 'width' : 'height'}`, '0px');
	wrapper.style.setProperty('opacity', '0');
};

const setExpandedStyle = (
	wrapper: HTMLElement,
	content: HTMLElement,
	direction: 'horizontal' | 'vertical',
) => {
	// set the max size to content size and opacity to 1
	wrapper.style.setProperty(
		`max-${direction === 'horizontal' ? 'width' : 'height'}`,
		`${getSize(content, direction)}px`,
	);
	wrapper.style.setProperty('opacity', '1');
};

const resetStyle = (wrapper: HTMLElement, direction: 'horizontal' | 'vertical') => {
	wrapper.style.removeProperty(`max-${direction === 'horizontal' ? 'width' : 'height'}`);
	wrapper.style.removeProperty('opacity');
};

export const Collapsible = ({
	animationDuration = 250,
	children,
	direction = 'vertical',
	id,
	isExpanded,
}: Props) => {
	const didMountRef = useRef(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const [isRendered, setIsRendered] = useState(isExpanded);

	useLayoutEffect(() => {
		if (!wrapperRef.current) {
			return;
		}

		// add the transition to the wrapper
		wrapperRef.current.style.setProperty(
			'transition',
			`max-${direction === 'horizontal' ? 'width' : 'height'} ${animationDuration}ms ease-in-out, opacity ${animationDuration}ms ease-in-out`,
		);

		if (!isExpanded) {
			setCollapsedStyle(wrapperRef.current, direction);
		}
		// initial render only
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}

		if (isExpanded) {
			if (!isRendered) {
				setIsRendered(true);
			} else {
				requestAnimationFrame(() => {
					if (!wrapperRef.current || !contentRef.current) {
						return;
					}

					setExpandedStyle(wrapperRef.current, contentRef.current, direction);

					// wait for the animation to finish before removing the overflow
					Promise.all(
						wrapperRef.current.getAnimations().map((animation) => animation.finished) ?? [],
					).then(() => {
						wrapperRef.current && resetStyle(wrapperRef.current, direction);
					});
				});
			}
		} else if (isRendered) {
			if (!wrapperRef.current || !contentRef.current) {
				return;
			}

			setExpandedStyle(wrapperRef.current, contentRef.current, direction);
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					if (!wrapperRef.current) {
						return;
					}

					setCollapsedStyle(wrapperRef.current, direction);

					// wait for the animation to finish before removing the content
					Promise.all(
						wrapperRef.current.getAnimations().map((animation) => animation.finished) ?? [],
					).then(() => {
						setIsRendered(false);
					});
				});
			});
		}
	}, [direction, isExpanded, isRendered]);

	return (
		<div id={id} css={styles.collapsibleWrapper} ref={wrapperRef}>
			{isRendered && <div ref={contentRef}>{children}</div>}
		</div>
	);
};

const styles = cssMap({
	collapsibleWrapper: {
		overflow: 'hidden',
	},
});
