/**
 * @generated SignedSource<<d4060e6b7d223c58b731cfc30dfd61a4>>
 * @relayHash 7f83c3d7ee67dd628a9a3408b548931f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ff881d9c3b5e95d418f0530d0855b87b989f8f19d3e6ca49c8a590a68577bbdb

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardViewInput = {
  jiraBoardViewQueryInput: JiraBoardViewQueryInput;
  settings?: JiraBoardViewSettings | null | undefined;
};
export type JiraBoardViewQueryInput = {
  projectKeyAndItemIdQuery?: JiraBoardViewProjectKeyAndItemIdQuery | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraBoardViewProjectKeyAndItemIdQuery = {
  cloudId: string;
  itemId: string;
  projectKey: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type BoardMoreMenuContentQuery$variables = {
  projectAri: string;
  viewInput: JiraBoardViewInput;
};
export type BoardMoreMenuContentQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly " $fragmentSpreads": FragmentRefs<"WorkflowSelectorMenu_project">;
    };
  };
  readonly jira_boardView: {
    readonly " $fragmentSpreads": FragmentRefs<"CardFieldsMenu" | "ExpandCollapseAll_view" | "RemoveResolvedItemsMenu" | "WorkflowSelectorMenu_view">;
  };
};
export type BoardMoreMenuContentQuery = {
  response: BoardMoreMenuContentQuery$data;
  variables: BoardMoreMenuContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "viewInput"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "BoardMoreMenuContentQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v1/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProject",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "WorkflowSelectorMenu_project"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProject"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v2/*: any*/),
          "concreteType": "JiraBoardView",
          "kind": "LinkedField",
          "name": "jira_boardView",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "CardFieldsMenu"
            },
            {
              "kind": "FragmentSpread",
              "name": "WorkflowSelectorMenu_view"
            },
            {
              "kind": "FragmentSpread",
              "name": "RemoveResolvedItemsMenu"
            },
            {
              "kind": "FragmentSpread",
              "name": "ExpandCollapseAll_view"
            }
          ]
        },
        "action": "THROW",
        "path": "jira_boardView"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardMoreMenuContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "alias": "canAdministerProject",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canPerform"
                  }
                ],
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v2/*: any*/),
        "concreteType": "JiraBoardView",
        "kind": "LinkedField",
        "name": "jira_boardView",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "menuCardOptions",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100
              }
            ],
            "concreteType": "JiraBoardViewCardOptionConnection",
            "kind": "LinkedField",
            "name": "cardOptions",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraBoardViewCardOptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraField",
                            "kind": "LinkedField",
                            "name": "field",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "fieldId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "typeKey"
                              },
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "canToggle"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "enabled"
                          }
                        ],
                        "type": "JiraBoardViewFieldCardOption"
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "storageKey": "cardOptions(first:100)"
          },
          {
            "kind": "ScalarField",
            "name": "selectedWorkflowId"
          },
          {
            "kind": "ScalarField",
            "name": "completedIssueSearchCutOffInDays"
          },
          {
            "concreteType": "JiraBoardViewColumnConnection",
            "kind": "LinkedField",
            "name": "columns",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ff881d9c3b5e95d418f0530d0855b87b989f8f19d3e6ca49c8a590a68577bbdb",
    "metadata": {},
    "name": "BoardMoreMenuContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2fbd9fa4b5b8e405b87ec371f9cd9939";

export default node;
