/**
 * @generated SignedSource<<235be1041e6980bd6bd29373d4afa657>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardGroupSelector_view$data = {
  readonly groupByConfig: {
    readonly fieldId: string;
    readonly fieldName: string;
  };
  readonly groupByOptions: ReadonlyArray<{
    readonly fieldId: string;
    readonly fieldName: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "BoardGroupSelector_view";
};
export type BoardGroupSelector_view$key = {
  readonly " $data"?: BoardGroupSelector_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardGroupSelector_view">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v1 = {
  "kind": "ScalarField",
  "name": "fieldName"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardGroupSelector_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          },
          {
            "kind": "RequiredField",
            "field": (v1/*: any*/),
            "action": "THROW",
            "path": "groupByConfig.fieldName"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByOptions",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "groupByOptions.fieldId"
          },
          {
            "kind": "RequiredField",
            "field": (v1/*: any*/),
            "action": "THROW",
            "path": "groupByOptions.fieldName"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByOptions"
    }
  ],
  "type": "JiraBoardView"
};
})();

(node as any).hash = "1cf2e14c5b47c0a9b5809df9d0fa287b";

export default node;
