import React, { useCallback, useState, memo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import PageIcon from '@atlaskit/icon/core/migration/page';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Spinner from '@atlaskit/spinner';
import { cssMap } from '@atlaskit/css';
import { REMOTE_ISSUE_LINKS_TYPE } from '@atlassian/jira-business-constants/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { BoardIssue } from '../../../../../../common/types.tsx';
import { cardConfluencePagesMenuContentEntryPoint } from './menu-content/entrypoint.tsx';
import messages from './messages.tsx';

export type Props = {
	issue: BoardIssue;
};

const menuContentEntryPointProps = {};

const styles = cssMap({
	fallbackMenu: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '250px',
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
	},
});

const CardDetailConfluencePages = ({ issue }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(false);
	const closeMenu = useCallback(() => setIsOpen(false), []);
	const pageCount = issue.fields[REMOTE_ISSUE_LINKS_TYPE]?.value ?? 0;

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		cardConfluencePagesMenuContentEntryPoint,
		menuContentEntryPointProps,
	);
	const menuContentTrigger = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const popupContent = useCallback(
		({ update }: { update: () => void }) => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="business-board-card-confluence-links-menu"
				packageName="@atlassian/jira-work-management-board"
				runtimeProps={{
					issue,
					onContentResize: update,
				}}
				fallback={
					<Box xcss={styles.fallbackMenu}>
						<Spinner />
					</Box>
				}
			/>
		),
		[entryPointReferenceSubject, issue],
	);

	const onTriggerClicked = useCallback(() => {
		setIsOpen((prev) => !prev);

		fireUIAnalytics(createAnalyticsEvent({}), 'confluencePagesField clicked');
	}, [createAnalyticsEvent]);

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<Tooltip
				content={formatMessage(messages.pageCount, {
					pageCount,
				})}
				hideTooltipOnClick
			>
				<StyledButton
					{...triggerProps}
					ref={(element: HTMLButtonElement) => {
						menuContentTrigger(element);
						if (typeof triggerProps.ref === 'function') {
							triggerProps.ref(element);
						}
					}}
					spacing="compact"
					appearance="subtle"
					onClick={onTriggerClicked}
					aria-label={formatMessage(messages.pageCount, { pageCount })}
					iconBefore={
						isVisualRefreshEnabled() ? (
							<PageIcon label="" LEGACY_size="small" color={token('color.icon')} />
						) : (
							<PageIcon label="" color={token('color.icon')} />
						)
					}
				>
					<Box xcss={confluencePagesCountStyles}>{pageCount}</Box>
				</StyledButton>
			</Tooltip>
		),
		[formatMessage, menuContentTrigger, onTriggerClicked, pageCount],
	);

	return (
		<Popup
			shouldUseCaptureOnOutsideClick
			isOpen={isOpen}
			placement="right-start"
			messageId="work-management-board.ui.board.column.card.card-footer.confluence-pages.popup"
			messageType="transactional"
			autoFocus={false}
			onClose={closeMenu}
			trigger={popupTrigger}
			content={popupContent}
		/>
	);
};

export default memo(CardDetailConfluencePages);

const confluencePagesCountStyles = xcss({
	marginLeft: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	paddingLeft: token('space.050'),
	paddingRight: token('space.050'),
});
