/**
 * @generated SignedSource<<dbe95cfa06f799fbe17cb8b28922ffb1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_Inner_project$data = {
  readonly projectStyle: JiraProjectStyle;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumnCreate_project" | "BoardColumn_project">;
  readonly " $fragmentType": "BoardContent_Inner_project";
};
export type BoardContent_Inner_project$key = {
  readonly " $data"?: BoardContent_Inner_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardContent_Inner_project",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardColumn_project"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardColumnCreate_project"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectStyle"
      },
      "action": "THROW",
      "path": "projectStyle"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "deffd039bf947c3b4d5d319d85ac5ffb";

export default node;
