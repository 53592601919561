import {
  JiraViewAriResourceOwner,
  JiraViewAriResourceType
} from "./chunk-RZI34DMQ.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/view/manifest.ts
var jiraViewAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraViewAriResourceOwner,
  resourceType: JiraViewAriResourceType,
  resourceIdSlug: "activation/{activationId}/{scopeType}/{scopeId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    scopeType: /(project|board)/,
    // eslint-disable-line no-useless-escape
    scopeId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/view/index.ts
var JiraViewAri = class _JiraViewAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._scopeType = opts.resourceIdSegmentValues.scopeType, this._scopeId = opts.resourceIdSegmentValues.scopeId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get scopeType() {
    return this._scopeType;
  }
  get scopeId() {
    return this._scopeId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraViewAriStaticOpts.qualifier,
      platformQualifier: jiraViewAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraViewAriStaticOpts.resourceOwner,
      resourceType: jiraViewAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.scopeType}/${opts.scopeId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        scopeType: opts.scopeType,
        scopeId: opts.scopeId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraViewAriStaticOpts);
    return new _JiraViewAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraViewAriStaticOpts);
    return new _JiraViewAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      scopeType: this.scopeType,
      scopeId: this.scopeId
    };
  }
};

export {
  JiraViewAri
};
