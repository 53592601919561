import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setColumnCollapsedStateFailedTitle: {
		id: 'work-management-board.column.error-title',
		defaultMessage: 'Unable to set the column state',
		description: 'Error title informing the user collapsing/expanding column failed.',
	},
	setColumnCollapsedStateFailedMessage: {
		id: 'work-management-board.column.error-message',
		defaultMessage:
			"We couldn't apply your column settings, try refreshing the page or try again later.",
		description: 'Error message informing the user collapsing/expanding column failed.',
	},
	noAssignee: {
		id: 'work-management-board.board.column.no-assignee',
		defaultMessage: 'Unassigned',
		description: 'Label for board group column for items without assignee',
	},
	noCategory: {
		id: 'work-management-board.board.column.no-category',
		defaultMessage: 'Uncategorized',
		description: 'Label for board group column for items without category',
	},
});
