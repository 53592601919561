/**
 * @generated SignedSource<<24c31ea6b8b5b401753c41007b7a76c2>>
 * @relayHash f180de419457fd2ccd31e03f62bf2c44
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b92731d011c9cbb06a75f8872be5e72ae47ae6d94e0ac6592ded471dd6575c10

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraPublishBoardViewConfigInput = {
  settings?: JiraBoardViewSettings | null | undefined;
  viewId: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type SaveResetViewSettingsPublishSettingsMutation$variables = {
  input: JiraPublishBoardViewConfigInput;
};
export type SaveResetViewSettingsPublishSettingsMutation$data = {
  readonly jira_publishBoardViewConfig: {
    readonly boardView: {
      readonly isViewConfigModified: boolean | null | undefined;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly extensions: {
        readonly errorType: string | null | undefined;
        readonly statusCode: number | null | undefined;
      } | null | undefined;
      readonly message: string | null | undefined;
    }> | null | undefined;
    readonly success: boolean;
  } | null | undefined;
};
export type SaveResetViewSettingsPublishSettingsMutation = {
  response: SaveResetViewSettingsPublishSettingsMutation$data;
  variables: SaveResetViewSettingsPublishSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "isViewConfigModified"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "SaveResetViewSettingsPublishSettingsMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraPublishBoardViewConfigPayload",
        "kind": "LinkedField",
        "name": "jira_publishBoardViewConfig",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveResetViewSettingsPublishSettingsMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraPublishBoardViewConfigPayload",
        "kind": "LinkedField",
        "name": "jira_publishBoardViewConfig",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/)
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "BulkMutationErrorExtension"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b92731d011c9cbb06a75f8872be5e72ae47ae6d94e0ac6592ded471dd6575c10",
    "metadata": {},
    "name": "SaveResetViewSettingsPublishSettingsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "287493b6f100ea466a41a5bc4a254a6b";

export default node;
