/**
 * @generated SignedSource<<1309c9a66aa452d14e10ecd44b14bd91>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_view$data = {
  readonly cardOptions: {
    readonly " $fragmentSpreads": FragmentRefs<"CardFooter_cardOptions">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardCardActions_view" | "BoardCard_CoverWrapper_view" | "CardContent_view">;
  readonly " $fragmentType": "BoardCard_view";
};
export type BoardCard_view$key = {
  readonly " $data"?: BoardCard_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_view",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_CoverWrapper_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "CardContent_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardCardActions_view"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "enabledOnly",
            "value": true
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "JiraBoardViewCardOptionConnection",
        "kind": "LinkedField",
        "name": "cardOptions",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CardFooter_cardOptions"
          }
        ],
        "storageKey": "cardOptions(enabledOnly:true,first:100)"
      },
      "action": "THROW",
      "path": "cardOptions"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "0d1e379b8d9711980af363d6c7f349f8";

export default node;
