import { fg } from '@atlassian/jira-feature-gating';
import { SHOULD_SHOW_BUSINESS_SAMPLE_DATA_NUDGE } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';

export const useBusinessSampleDataNudge = () => {
	if (fg('jfind-personalized-sample-data-walk-gate') === false) {
		return {
			shouldShowBusinessSampleDataNudge: false,
		};
	}

	const shouldShowBusinessSampleDataNudge = sessionStorageProvider.get(
		SHOULD_SHOW_BUSINESS_SAMPLE_DATA_NUDGE,
	);

	return {
		shouldShowBusinessSampleDataNudge,
	};
};

export const setShouldShowBusinessSampleDataNudgeSessionStorage = (): void => {
	sessionStorageProvider.set(SHOULD_SHOW_BUSINESS_SAMPLE_DATA_NUDGE, true);
};
