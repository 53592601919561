/**
 * @generated SignedSource<<c9573d579e2d37f018499a38cbf156b6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CardFooter_cardOptions$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly field?: {
        readonly fieldId: string;
      };
    };
  } | null | undefined>;
  readonly " $fragmentType": "CardFooter_cardOptions";
};
export type CardFooter_cardOptions$key = {
  readonly " $data"?: CardFooter_cardOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardFooter_cardOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CardFooter_cardOptions",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraBoardViewCardOptionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraField",
                        "kind": "LinkedField",
                        "name": "field",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "kind": "ScalarField",
                              "name": "fieldId"
                            },
                            "action": "THROW",
                            "path": "edges.node.field.fieldId"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "edges.node.field"
                    }
                  ],
                  "type": "JiraBoardViewFieldCardOption"
                }
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    }
  ],
  "type": "JiraBoardViewCardOptionConnection"
};

(node as any).hash = "d594220f166975c06eface2769d424c2";

export default node;
