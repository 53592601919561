import React, { useEffect, useMemo, useState, memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ColumnConfettiAsync_status$key } from '@atlassian/jira-relay/src/__generated__/ColumnConfettiAsync_status.graphql';
import { useConfettiColumns } from '../../../../../controllers/column-confetti/index.tsx';
import { boardConfettiEntrypoint } from './entrypoint.tsx';

type Props = {
	statusFragment: ColumnConfettiAsync_status$key;
};

const entryPointParams = {};
const ColumnConfettiAsync = ({ statusFragment }: Props) => {
	const status = useFragment(
		graphql`
			fragment ColumnConfettiAsync_status on JiraStatus {
				name @required(action: THROW)
				statusId @required(action: THROW)
			}
		`,
		statusFragment,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		boardConfettiEntrypoint,
		entryPointParams,
	);
	const [animating, setAnimating] = useState(false);
	const { confettiColumns, removeConfettiColumn } = useConfettiColumns();

	useEffect(() => {
		if (confettiColumns.has(status.statusId)) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'boardConfetti created', {
				columnType: 'done',
				columnId: status.statusId,
			});

			setAnimating(true);
			removeConfettiColumn(status.statusId);
		}
	}, [confettiColumns, createAnalyticsEvent, status.statusId, status.name, removeConfettiColumn]);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (animating) {
			entryPointActions.load();
		}
	}, [animating, entryPointActions]);

	const runtimeProps = useMemo(
		() => ({
			onConfettiComplete: () => setAnimating(false),
		}),
		[setAnimating],
	);

	if (!animating) {
		return null;
	}

	return (
		<JiraEntryPointContainer
			id="jwm-board-confetti"
			packageName="jiraWorkManagementBoard"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};

export default memo(ColumnConfettiAsync);
