/**
 * @generated SignedSource<<492c2e132e07baa4324f74c355dcf368>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CardContent_view$data = {
  readonly cardOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly field?: {
          readonly fieldId: string;
        };
      };
    } | null | undefined>;
    readonly " $fragmentSpreads": FragmentRefs<"CardDetailFields_cardOptions">;
  };
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly " $fragmentType": "CardContent_view";
};
export type CardContent_view$key = {
  readonly " $data"?: CardContent_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardContent_view">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "fieldId"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CardContent_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "enabledOnly",
            "value": true
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "JiraBoardViewCardOptionConnection",
        "kind": "LinkedField",
        "name": "cardOptions",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CardDetailFields_cardOptions"
          },
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraBoardViewCardOptionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "concreteType": "JiraField",
                              "kind": "LinkedField",
                              "name": "field",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": (v0/*: any*/),
                                  "action": "THROW",
                                  "path": "cardOptions.edges.node.field.fieldId"
                                }
                              ]
                            },
                            "action": "THROW",
                            "path": "cardOptions.edges.node.field"
                          }
                        ],
                        "type": "JiraBoardViewFieldCardOption"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "cardOptions.edges.node"
                }
              ]
            },
            "action": "THROW",
            "path": "cardOptions.edges"
          }
        ],
        "storageKey": "cardOptions(enabledOnly:true,first:100)"
      },
      "action": "THROW",
      "path": "cardOptions"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    }
  ],
  "type": "JiraBoardView"
};
})();

(node as any).hash = "4a3addf11b91d7bb3275b451170961fb";

export default node;
