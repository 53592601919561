import React, { type ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import type { Filters } from '../common/types.tsx';
import { serialiseJql } from './utils.tsx';

type ContextValue = {
	jql: string | undefined;
	saveJql: (jql: string | undefined) => void;
};

type Props = {
	children: ReactNode;
	jql: string | undefined;
	saveJql: (jql: string | undefined) => void;
};

const Context = createContext<ContextValue | null>(null);

export const InternalFiltersJqlProvider = ({ children, jql, saveJql }: Props) => {
	return (
		<Context.Provider
			value={useMemo(
				() => ({
					jql,
					saveJql,
				}),
				[jql, saveJql],
			)}
		>
			{children}
		</Context.Provider>
	);
};

export const useFiltersPreference = (): {
	jql: string | undefined;
	saveJql: (jql: string | undefined) => void;
} => {
	const context = useContext(Context);

	if (!context) {
		throw new Error('useFiltersPreference must be used within a InternalFiltersJqlProvider');
	}

	return context;
};

export const useFilters = (): {
	jql: string | undefined;
	setFilter: (filters: Filters) => void;
} => {
	const { jql, saveJql } = useFiltersPreference();

	const setFilter = useCallback(
		(filters: Filters) => {
			const serialisedJql = serialiseJql(filters);
			saveJql(serialisedJql !== '' ? serialisedJql : undefined);
		},
		[saveJql],
	);

	return {
		jql,
		setFilter,
	};
};
