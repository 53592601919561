import React, { useState, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CalendarIcon from '@atlaskit/icon/core/calendar';
import Popup from '@atlaskit/popup';
import type { Placement } from '@atlaskit/popper';
import { xcss, Inline, Text, Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { DateOnly } from '@atlassian/jira-platform-utils-date-only/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ContextualAnalyticsData, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ConfigureSource } from '@atlassian/jira-issue-view-common-types/src/inline-create-fields-type.tsx';
import { CalendarWithInputField } from './calendar-with-input-field/CalendarWithInputField.tsx';
import messages from './messages.tsx';
import type { DateType } from './types.tsx';

export const InlineDueDatePicker = ({
	popUpPlacement = 'top-end',
	onDateSelected,
	isCompactVersion = false,
	source,
	defaultDueDate,
}: {
	popUpPlacement: Placement | undefined;
	onDateSelected?: (date: Date | null) => void;
	isCompactVersion?: boolean;
	source?: ConfigureSource;
	defaultDueDate?: Date | null;
}) => {
	const { formatMessage, formatDate } = useIntl();

	const currentDate = DateOnly.fromDateAccordingToLocalTime(new Date());
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<DateType | null>(null);
	const getFormattedDateString = () => {
		if (!defaultDueDate) {
			return null;
		}
		const formattedDate = formatDate(defaultDueDate, { day: '2-digit', month: 'short' });

		return formattedDate;
	};

	const handleDateSelected = (date: DateType | null, analyticsEvent: UIAnalyticsEvent) => {
		if (date) {
			const formatInputDate = new Date(date.year, date.month - 1, date.day);
			setSelectedDate(DateOnly.fromDateAccordingToLocalTime(formatInputDate));
			onDateSelected?.(formatInputDate);
		} else {
			setSelectedDate(null);
		}
		setIsOpen(false);
		fireUIAnalytics(analyticsEvent, 'dueDate');
	};

	const handleOnClick = useCallback(
		(_e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'dueDatePickerButton');
			setIsOpen(!isOpen);
		},
		[isOpen],
	);

	return (
		<ContextualAnalyticsData
			sourceName="inlineDueDatePicker"
			attributes={{
				calledFrom: source,
			}}
		>
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement={popUpPlacement}
				shouldRenderToParent
				content={() => (
					<CalendarWithInputField
						defaultDate={selectedDate || currentDate}
						onDateSelected={handleDateSelected}
						setSelectedDate={setSelectedDate}
					/>
				)}
				trigger={({ ref, ...triggerProps }) => (
					<Pressable
						ref={ref}
						{...triggerProps}
						onClick={handleOnClick}
						backgroundColor="color.background.neutral.subtle"
						xcss={[
							isCompactVersion ? dueDatePickerCompact : dueDatePickerButtonStyle,
							isOpen && dueDatePickerSelectedStyle,
						]}
					>
						<Inline space="space.050" alignBlock="center">
							<CalendarIcon
								label=""
								testId="inline-due-date-picker.calender-icon"
								color={token('color.icon.subtle')}
							/>
							{getFormattedDateString() ||
								(!isCompactVersion && (
									<Text color="color.text.subtle">{formatMessage(messages.dueDateLabel)}</Text>
								))}
						</Inline>
					</Pressable>
				)}
			/>
		</ContextualAnalyticsData>
	);
};

const dueDatePickerButtonStyle = xcss({
	padding: 'space.050',
	color: 'color.text',
});

const dueDatePickerSelectedStyle = xcss({
	backgroundColor: 'color.background.selected',
	borderColor: 'color.border.selected',
	borderRadius: '3px',
	borderWidth: 'border.width',
	borderStyle: 'solid',
});

const dueDatePickerCompact = xcss({
	padding: 'space.025',
	color: 'color.text',
});
