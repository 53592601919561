/** @jsx jsx */
import React, { memo, useCallback, useState } from 'react';
import { jsx } from '@compiled/react';
import { graphql, useFragment, useMutation } from 'react-relay';
import UndoIcon from '@atlaskit/icon/core/undo';
import Tooltip from '@atlaskit/tooltip';
import SyncIcon from '@atlaskit/icon-lab/core/sync';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import {
	// eslint-disable-next-line jira/import/no-restricted-import
	Spotlight,
	SpotlightManager,
	SpotlightPulse,
	SpotlightTarget,
	SpotlightTransition,
} from '@atlaskit/onboarding';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { SaveResetViewSettingsDiscardSettingsMutation } from '@atlassian/jira-relay/src/__generated__/SaveResetViewSettingsDiscardSettingsMutation.graphql';
import type { SaveResetViewSettingsPublishSettingsMutation } from '@atlassian/jira-relay/src/__generated__/SaveResetViewSettingsPublishSettingsMutation.graphql';
import type { SaveResetViewSettings_view$key } from '@atlassian/jira-relay/src/__generated__/SaveResetViewSettings_view.graphql';
import {
	useOnboarding,
	SAVE_VIEW_ONBOARDING,
} from '@atlassian/jira-business-onboarding-board-saved-views/src/controllers/onboarding-context.tsx';
import { useBoardData } from '../../../controllers/board-data/BoardDataProvider.tsx';
import { getUrlSettingsInput } from '../../../common/url-settings-input.tsx';
import messages from './messages.tsx';

type Props = {
	viewFragment: SaveResetViewSettings_view$key;
};

const styles = cssMap({
	root: {
		display: 'flex',
		// this allows to have the gap between action buttons being animated properly upon reveal/hide
		paddingLeft: token('space.100'),
	},
});

export const SaveResetViewSettings = memo(({ viewFragment }: Props) => {
	const { formatMessage } = useIntl();
	const [isOnboardingVisible, markAsSeen] = useOnboarding(SAVE_VIEW_ONBOARDING);
	const [isSpotlightVisible, setSpotlightVisible] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { showFlag } = useFlagsService();
	const { refetch } = useBoardData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isModalOpen, setModalOpen] = useState(false);
	const openModal = useCallback(() => setModalOpen(true), []);
	const closeModal = useCallback(() => setModalOpen(false), []);

	const view = useFragment(
		graphql`
			fragment SaveResetViewSettings_view on JiraBoardView {
				id @required(action: THROW)
				canPublishViewConfig
			}
		`,
		viewFragment,
	);

	const [publish, publishing] = useMutation<SaveResetViewSettingsPublishSettingsMutation>(graphql`
		mutation SaveResetViewSettingsPublishSettingsMutation(
			$input: JiraPublishBoardViewConfigInput!
		) {
			jira_publishBoardViewConfig(input: $input) {
				success
				boardView {
					isViewConfigModified
				}
				errors {
					message
					extensions {
						errorType
						statusCode
					}
				}
			}
		}
	`);

	const [discard, discarding] = useMutation<SaveResetViewSettingsDiscardSettingsMutation>(graphql`
		mutation SaveResetViewSettingsDiscardSettingsMutation(
			$input: JiraDiscardUserBoardViewConfigInput!
		) {
			jira_discardUserBoardViewConfig(input: $input) {
				success
				boardView {
					...BusinessBoard_view
				}
				errors {
					message
					extensions {
						errorType
						statusCode
					}
				}
			}
		}
	`);

	const onClickSave = useCallback(() => {
		closeModal();

		const showFailureFlag = () => {
			showFlag({
				messageId:
					'work-management-board.ui.header.save-reset-view-settings.show-flag.error.save-settings-failed',
				messageType: 'transactional',
				title: messages.saveSettingsFailedTitle,
				description: messages.saveSettingsFailedMessage,
				type: 'error',
			});
		};

		publish({
			variables: {
				input: {
					viewId: view.id,
					settings: getUrlSettingsInput(),
				},
			},
			onError() {
				showFailureFlag();
			},
			onCompleted(response) {
				if (response.jira_publishBoardViewConfig?.success !== true) {
					showFailureFlag();
					return;
				}

				showFlag({
					messageId:
						'work-management-board.ui.header.save-reset-view-settings.show-flag.success.save-settings-success',
					messageType: 'transactional',
					title: messages.saveSettingsSuccessTitle,
					description: messages.saveSettingsSuccessMessage,
					type: 'success',
				});

				fireTrackAnalytics(createAnalyticsEvent({}), 'viewSettings saved');
			},
		});

		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'saveSettings');
	}, [closeModal, createAnalyticsEvent, publish, showFlag, view.id]);

	const onClickReset = useCallback(() => {
		const showFailureFlag = () => {
			showFlag({
				messageId:
					'work-management-board.ui.header.save-reset-view-settings.show-flag.error.reset-settings-failed',
				messageType: 'transactional',
				title: messages.resetSettingsFailedTitle,
				description: messages.resetSettingsFailedMessage,
				type: 'error',
			});
		};

		discard({
			variables: {
				input: {
					viewId: view.id,
					settings: getUrlSettingsInput(),
				},
			},
			onError() {
				showFailureFlag();
			},
			onCompleted(response) {
				if (response.jira_discardUserBoardViewConfig?.success !== true) {
					showFailureFlag();
					return;
				}

				fireTrackAnalytics(createAnalyticsEvent({}), 'viewSettings reset');
				refetch();
			},
		});

		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'resetSettings', {
			isProjectAdmin: view.canPublishViewConfig,
		});
	}, [createAnalyticsEvent, discard, refetch, showFlag, view]);

	if (!view.canPublishViewConfig) {
		return (
			<div css={styles.root}>
				<Tooltip
					content={formatMessage(messages.resetViewSettingsButton)}
					position="top"
					hideTooltipOnClick
				>
					<ThemedButton
						onClick={onClickReset}
						isLoading={discarding}
						iconAfter={
							<UndoIcon
								label={formatMessage(messages.resetViewSettingsButton)}
								spacing="spacious"
								color="currentColor"
							/>
						}
					/>
				</Tooltip>
			</div>
		);
	}

	return (
		<div css={styles.root}>
			<SpotlightManager>
				<DropdownMenu<HTMLButtonElement>
					trigger={({ triggerRef, ...props }) => (
						<Tooltip
							content={formatMessage(messages.saveOrResetViewSettingsButton)}
							position="top"
							hideTooltipOnClick
						>
							<SpotlightPulse radius={3} pulse={isOnboardingVisible}>
								<ThemedButton
									{...props}
									isLoading={discarding || publishing}
									iconAfter={
										<SyncIcon
											label={formatMessage(messages.saveOrResetViewSettingsButton)}
											spacing="spacious"
											color="currentColor"
										/>
									}
									ref={triggerRef}
									// remove onClick when cleaning saved_views and its onboarding, and have the menu as uncontrolled
									onClick={() => {
										setIsMenuOpen(!isMenuOpen);
										if (isOnboardingVisible) {
											markAsSeen();
											setTimeout(() => {
												setSpotlightVisible(true);
											}, 0);
										}
									}}
								/>
							</SpotlightPulse>
						</Tooltip>
					)}
					// shouldRenderToParent does not work with image project theme applied
					shouldRenderToParent={false}
					// remove isOpen when cleaning saved_views and its onboarding, and have the menu as uncontrolled
					isOpen={isMenuOpen}
					// remove onOpenChange when cleaning saved_views and its onboarding, and have the menu as uncontrolled
					onOpenChange={({ isOpen }) => !isSpotlightVisible && setIsMenuOpen(isOpen)}
				>
					<SpotlightTarget name="save-reset-view-settings-menu">
						<SpotlightPulse radius={3} pulse={isSpotlightVisible}>
							<DropdownItemGroup>
								<DropdownItem onClick={openModal}>
									{formatMessage(messages.saveViewSettingsMenuItem)}
								</DropdownItem>
								<DropdownItem onClick={onClickReset}>
									{formatMessage(messages.resetViewSettingsMenuItem)}
								</DropdownItem>
							</DropdownItemGroup>
						</SpotlightPulse>
					</SpotlightTarget>
				</DropdownMenu>

				<SpotlightTransition>
					{isSpotlightVisible && (
						<Spotlight
							actions={[
								{
									onClick: () => {
										setSpotlightVisible(false);
									},
									text: formatMessage(messages.onboardingNudgeAcknowledged),
								},
							]}
							heading={formatMessage(messages.onboardingNudgeTitle)}
							target="save-reset-view-settings-menu"
							targetRadius={3}
							dialogPlacement="left top"
							dialogWidth={280}
							targetBgColor={token('elevation.surface')}
						>
							{formatMessage(messages.onboardingNudgeDescription)}
						</Spotlight>
					)}
				</SpotlightTransition>
			</SpotlightManager>

			<ModalTransition>
				{isModalOpen && (
					<Modal onClose={closeModal} width="small">
						<ModalHeader>
							<ModalTitle>{formatMessage(messages.saveConfirmationModalTitle)}</ModalTitle>
						</ModalHeader>
						<ModalBody>{formatMessage(messages.saveConfirmationModalBody)}</ModalBody>
						<ModalFooter>
							<Button appearance="subtle" onClick={closeModal}>
								{formatMessage(messages.cancelButton)}
							</Button>
							<Button appearance="primary" onClick={onClickSave}>
								{formatMessage(messages.saveButton)}
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</ModalTransition>
		</div>
	);
});
