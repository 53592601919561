/* eslint-disable @atlassian/relay/query-restriction */
import React, { useMemo, memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BusinessBoardScreenAnalytics_view$key } from '@atlassian/jira-relay/src/__generated__/BusinessBoardScreenAnalytics_view.graphql';

export const BusinessBoardScreenAnalytics = memo(
	({ viewFragment }: { viewFragment: BusinessBoardScreenAnalytics_view$key }) => {
		const view = useFragment(
			graphql`
				fragment BusinessBoardScreenAnalytics_view on JiraBoardView {
					filterConfig @required(action: THROW) {
						jql @required(action: THROW)
					}
					groupByConfig @required(action: THROW) {
						fieldId @required(action: THROW)
					}
					isViewConfigModified @required(action: THROW)
				}
			`,
			viewFragment,
		);

		const withFilters = Boolean(view.filterConfig.jql);
		const groupByField = view.groupByConfig.fieldId;

		const attributes = useMemo(
			() => ({
				groupedBy: groupByField,
				withFilters,
				isViewConfigModified: view.isViewConfigModified,
			}),
			[groupByField, view.isViewConfigModified, withFilters],
		);

		return <FireScreenAnalytics attributes={attributes} />;
	},
);
