/**
 * @generated SignedSource<<05f80dc12871dfc32a0a6107e216623d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_CoverWrapper_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_queryFragment">;
  readonly " $fragmentType": "BoardCard_CoverWrapper_query";
};
export type BoardCard_CoverWrapper_query$key = {
  readonly " $data"?: BoardCard_CoverWrapper_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_CoverWrapper_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_CoverWrapper_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardCover_workManagementBoard_queryFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "cd3cb82e97deed1a96036ee15b098368";

export default node;
