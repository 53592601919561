/* eslint-disable @atlassian/relay/query-restriction */
import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { IssuesByGroupProvider_view$key } from '@atlassian/jira-relay/src/__generated__/IssuesByGroupProvider_view.graphql';
import { NULL_GROUP_KEY } from '../../common/constants.tsx';
import type { BoardIssue, Group } from '../../common/types.tsx';
import { useBoardData } from '../board-data/BoardDataProvider.tsx';
import { isGroupByType } from './utils.tsx';

type IssuesByGroup = Map<Group['id'], BoardIssue[]>;

const IssuesByGroupContext = createContext<IssuesByGroup | null>(null);

type IssuesByGroupProviderProps = {
	children: ReactNode;
	viewFragment: IssuesByGroupProvider_view$key;
};

export const IssuesByGroupProvider = ({ children, viewFragment }: IssuesByGroupProviderProps) => {
	const { filteredIssues } = useBoardData();

	const view = useFragment(
		graphql`
			fragment IssuesByGroupProvider_view on JiraBoardView {
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	const groupByFieldId = view.groupByConfig.fieldId;

	if (!isGroupByType(groupByFieldId)) {
		throw new Error(`Invalid groupBy fieldId: ${groupByFieldId}`);
	}

	const issuesByGroup = useMemo(() => {
		const map = new Map<Group['id'], BoardIssue[]>();

		// iterate over the filtered issues and add them to the correct group
		filteredIssues.forEach((issue) => {
			const groupId = issue.fields[groupByFieldId]?.value ?? NULL_GROUP_KEY;
			let groupIssues = map.get(groupId);
			if (!groupIssues) {
				groupIssues = [];
				map.set(groupId, groupIssues);
			}
			groupIssues.push(issue);
		});

		return map;
	}, [filteredIssues, groupByFieldId]);

	return (
		<IssuesByGroupContext.Provider value={issuesByGroup}>{children}</IssuesByGroupContext.Provider>
	);
};

export const useIssuesByGroup = (): IssuesByGroup => {
	const issuesByGroup = useContext(IssuesByGroupContext);

	if (!issuesByGroup) {
		throw new Error('useIssuesByGroup must be used within a IssuesByGroupProvider');
	}

	return issuesByGroup;
};
