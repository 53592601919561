/** @jsx jsx */
import React, { useState, useCallback, useMemo, memo } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { IconButton } from '@atlaskit/button/new';
import EditorAddIcon from '@atlaskit/icon/core/add';
import EditorAddIconLegacy from '@atlaskit/icon/glyph/editor/add';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { BoardColumnCreate_columns$key } from '@atlassian/jira-relay/src/__generated__/BoardColumnCreate_columns.graphql';
import { createColumnEntryPoint } from '@atlassian/jira-business-create-status-modal/entrypoint.tsx';
import { TEMPORARY_STATUS_ID } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/constants.tsx';
import { useCreateStatus } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/create-status/index.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import type { RuntimePropsOfEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointIconButtonTrigger } from '@atlassian/jira-modal-entry-point-icon-button-trigger/src/ModalEntryPointIconButtonTrigger.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BoardColumnCreate_project$key } from '@atlassian/jira-relay/src/__generated__/BoardColumnCreate_project.graphql';
import { OpenProjectSettingsMultipleWorkflows } from '../../../common/ui/open-project-settings-multiple-workflows/OpenProjectSettingsMultipleWorkflows.tsx';
import { COLUMN_ACTION_ADD_EXPERIENCE } from '../../../common/constants.tsx';
import { ContactAdminToModifyBoardMessage } from '../../../common/ui/contact-admin-to-modify-board/index.tsx';
import { useBoardData } from '../../../controllers/board-data/BoardDataProvider.tsx';
import {
	useWorkflowStoreState,
	useWorkflowStoreActions,
} from '../../../controllers/workflow-store/index.tsx';
import messages from './messages.tsx';

type CreateStatusModalProps = RuntimePropsOfEntryPoint<typeof createColumnEntryPoint>;
type Props = {
	columnsFragment: BoardColumnCreate_columns$key;
	projectFragment: BoardColumnCreate_project$key;
	refresh: () => void;
};

export const BoardColumnCreate = memo(({ columnsFragment, projectFragment, refresh }: Props) => {
	const columns = useFragment(
		graphql`
			fragment BoardColumnCreate_columns on JiraBoardViewColumnConnection {
				edges @required(action: THROW) {
					node @required(action: THROW) {
						__typename
						... on JiraBoardViewStatusColumn {
							statuses @required(action: THROW) {
								name @required(action: THROW)
							}
						}
					}
				}
			}
		`,
		columnsFragment,
	);

	const { workflows, issueTypes } = useWorkflowStoreState();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const issueTypeId = issueTypes[0]?.id;
	const existingColumnNames = useMemo(
		() =>
			columns.edges
				.flatMap((edge) => {
					const column = edge?.node;

					if (!column) {
						return null;
					}

					switch (column.__typename) {
						case 'JiraBoardViewStatusColumn':
							if (column.statuses.length === 0) {
								throw new Error('Missing statuses in column');
							}
							return column.statuses.map((status) => status?.name);
						default:
							return null;
					}
				})
				.filter(Boolean),
		[columns],
	);

	const { formatMessage } = useIntl();

	const [isNoPermissionMessageOpen, setIsNoPermissionMessageOpen] = useState<boolean | undefined>();
	const [isMultipleWorkflowsMessageOpen, setIsMultipleWorkflowsMessageOpen] = useState<
		boolean | undefined
	>();

	const createNewStatus = useCreateStatus(COLUMN_ACTION_ADD_EXPERIENCE);

	const { removeColumn } = useWorkflowStoreActions();

	const removeTemporaryColumn = useCallback(
		() => removeColumn(TEMPORARY_STATUS_ID),
		[removeColumn],
	);

	const { refetch: refetchBoardData } = useBoardData();

	const project = useFragment(
		graphql`
			fragment BoardColumnCreate_project on JiraProject {
				canAdministerProject: action(type: EDIT_PROJECT_CONFIG) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				...OpenProjectSettingsMultipleWorkflows_project
			}
		`,
		projectFragment,
	);
	const canAdministerProject = project.canAdministerProject.canPerform;
	const hasMultipleWorkflows = workflows.length > 1;

	const validateStatus: CreateStatusModalProps['validateStatus'] = useCallback(
		({ statusName }) =>
			!existingColumnNames.some(
				(existingColumnName: string) =>
					existingColumnName.toUpperCase() === statusName.toUpperCase(),
			),
		[existingColumnNames],
	);

	const createStatus = useCallback(
		async (payload: Parameters<typeof createNewStatus>[0]) => {
			try {
				await createNewStatus(payload);
			} catch (error) {
				removeTemporaryColumn();
				throw error;
			} finally {
				refresh();
				refetchBoardData();
			}
		},
		[createNewStatus, refresh, removeTemporaryColumn, refetchBoardData],
	);

	const createColumnModalProps = useMemo(() => {
		return {
			createStatus,
			validateStatus,
			issueTypeId,
		};
	}, [createStatus, issueTypeId, validateStatus]);

	if (!canAdministerProject) {
		// show button that will trigger no permission message for user without permission
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isNoPermissionMessageOpen),
			setIsOpen: setIsNoPermissionMessageOpen,
			testId: 'work-management-board.ui.board.column-create.no-permission-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateNoPermission',
			MessageComponent: ContactAdminToModifyBoardMessage,
		});
	}

	if (hasMultipleWorkflows) {
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isMultipleWorkflowsMessageOpen),
			setIsOpen: setIsMultipleWorkflowsMessageOpen,
			testId: 'work-management-board.ui.board.column-create.has-multiple-workflows-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateMultipleWorkflows',
			MessageComponent: () => <OpenProjectSettingsMultipleWorkflows projectFragment={project} />,
		});
	}

	if (!issueTypeId) {
		return null;
	}

	const button = (
		<ModalEntryPointIconButtonTrigger
			entryPoint={createColumnEntryPoint}
			entryPointProps={createColumnModalProps}
			interactionName="jwmColumnCreate"
			appearance="subtle"
			icon={() => (
				<EditorAddIcon
					label=""
					spacing="spacious"
					color="currentColor"
					LEGACY_fallbackIcon={EditorAddIconLegacy}
				/>
			)}
			isDisabled={isWorkflowOperationInProgress}
			label={formatMessage(messages.createStatusLabel)}
			useInternalModal={false}
		/>
	);
	return (
		<>
			<div css={surfaceWrapperStyles}>
				<Tooltip content={formatMessage(messages.createStatusLabel)}>{button}</Tooltip>
			</div>
		</>
	);
});

const renderColumnCreateButtonWithMessage = ({
	isOpen,
	setIsOpen,
	testId,
	label,
	analyticsId,
	MessageComponent,
}: {
	isOpen: boolean;
	setIsOpen: (state: boolean) => void;
	testId: string;
	label: string;
	analyticsId: string;
	MessageComponent: () => React.JSX.Element;
}) => (
	<InlineDialog
		content={
			<div css={messageWrapperStyles}>
				<MessageComponent />
			</div>
		}
		isOpen={isOpen}
		onClose={() => {
			setIsOpen(false);
		}}
		messageId="work-management-board.ui.board.column-create.inline-dialog"
		messageType="transactional"
	>
		<div css={surfaceWrapperStyles}>
			<IconButton
				icon={EditorAddIcon}
				label={label}
				testId={testId}
				onClick={(_, analyticsEvent) => {
					setIsOpen(true);
					fireUIAnalytics(analyticsEvent, analyticsId);
				}}
			/>
		</div>
	</InlineDialog>
);

const surfaceWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('elevation.surface.sunken'),
});

const messageWrapperStyles = css({
	width: '200px',
});
