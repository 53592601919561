/**
 * @generated SignedSource<<8b729a55fd26376a09a89fb6b35abb61>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_column$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCardActions_column" | "BoardInlineCreate_column">;
  readonly " $fragmentType": "BoardCard_column";
};
export type BoardCard_column$key = {
  readonly " $data"?: BoardCard_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_column">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_column",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardInlineCreate_column"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardCardActions_column"
    }
  ],
  "type": "JiraBoardViewColumn",
  "abstractKey": "__isJiraBoardViewColumn"
};

(node as any).hash = "0907f16a7a51541bffdc36fde33bcf25";

export default node;
