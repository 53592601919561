/**
 * @generated SignedSource<<11e4fbedc75998a5e67779ba07f05200>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoardQueryParamSync_view$data = {
  readonly filterConfig: {
    readonly jql: string;
  };
  readonly groupByConfig: {
    readonly fieldId: string;
  };
  readonly " $fragmentType": "BusinessBoardQueryParamSync_view";
};
export type BusinessBoardQueryParamSync_view$key = {
  readonly " $data"?: BusinessBoardQueryParamSync_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoardQueryParamSync_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BusinessBoardQueryParamSync_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewFilterConfig",
        "kind": "LinkedField",
        "name": "filterConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "jql"
            },
            "action": "THROW",
            "path": "filterConfig.jql"
          }
        ]
      },
      "action": "THROW",
      "path": "filterConfig"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewGroupByConfig",
        "kind": "LinkedField",
        "name": "groupByConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            "action": "THROW",
            "path": "groupByConfig.fieldId"
          }
        ]
      },
      "action": "THROW",
      "path": "groupByConfig"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "980b6fd1d42f858b1606feb74cb67a1a";

export default node;
