import { useEffect, type RefObject, useState } from 'react';
import noop from 'lodash/noop';
import {
	type Edge,
	extractClosestEdge,
	attachClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	type ElementDropTargetEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { COLUMN_DND_TYPE } from '../../../common/constants.tsx';
import type { DraggableColumnData, Group } from '../../../common/types.tsx';
import {
	useUpdateDropTarget,
	useResetDropTarget,
	useCanDropCard,
	useInferredDropTargetTransition,
} from '../card-drag-drop-context/index.tsx';
import { useColumnDragDrop } from '../column-drag-drop-context/index.tsx';
import { isCard, isColumn, isClosestEdgeAdjacentToSource } from '../utils/index.tsx';

export type Props = {
	columnDropTargetRef: RefObject<HTMLElement>;
	columnHandleRef: RefObject<HTMLElement>;
	column: Group;
	index: number;
};

export const useDraggableColumn = ({
	columnDropTargetRef,
	columnHandleRef,
	column,
	index,
}: Props) => {
	const {
		cleanupDragPreview,
		dragPreviewState,
		isDragging: isDraggingColumn,
		renderDragPreview,
		resetDraggedColumn,
		startColumnDrag,
	} = useColumnDragDrop(column.id);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [isCardOverColumn, setIsCardOverColumn] = useState(false);
	const updateDropTarget = useUpdateDropTarget();
	const resetDropTarget = useResetDropTarget();
	const { canDropInGroup } = useCanDropCard(column);
	const inferredDropTargetTransition = useInferredDropTargetTransition(column);

	useEffect(() => {
		const handleDrag = ({ source: { data }, self }: ElementDropTargetEventBasePayload) => {
			const destinationData = self.data;

			if (!isColumn(destinationData)) {
				return;
			}

			if (isColumn(data) && data.columnId !== column.id) {
				const extractedClosestEdge = extractClosestEdge(self.data);

				setClosestEdge(
					isClosestEdgeAdjacentToSource(data.index, destinationData.index, extractedClosestEdge)
						? null
						: extractedClosestEdge,
				);
				return;
			}

			if (isCard(data) && (isColumn(destinationData) || isCard(destinationData))) {
				setIsCardOverColumn(true);

				if (inferredDropTargetTransition == null) {
					updateDropTarget({
						group: column,
					});
				} else {
					updateDropTarget({
						group: column,
						transition: inferredDropTargetTransition,
					});
				}
			}
		};

		return combine(
			columnHandleRef.current != null
				? draggable({
						element: columnHandleRef.current,
						getInitialData: (): DraggableColumnData => ({
							type: COLUMN_DND_TYPE,
							columnId: column.id,
							index,
						}),
						onGenerateDragPreview: ({ nativeSetDragImage, location, source }) => {
							setCustomNativeDragPreview({
								getOffset: preserveOffsetOnSource({
									element: source.element,
									input: location.current.input,
								}),
								render({ container }) {
									renderDragPreview(container);
									return () => {
										cleanupDragPreview();
									};
								},
								nativeSetDragImage,
							});
						},
						onDragStart: startColumnDrag,
						onDrop: resetDraggedColumn,
					})
				: noop,
			columnDropTargetRef.current != null
				? dropTargetForElements({
						element: columnDropTargetRef.current,
						canDrop: ({ source }) =>
							isColumn(source.data) || (isCard(source.data) && canDropInGroup === 'yes'),
						getIsSticky: ({ source }) => isColumn(source.data),
						getData: ({ input, element }) => {
							const data: DraggableColumnData = {
								type: COLUMN_DND_TYPE,
								columnId: column.id,
								index,
							};

							return attachClosestEdge(data, {
								input,
								element,
								allowedEdges: ['left', 'right'],
							});
						},
						onDragEnter: handleDrag,
						onDrag: handleDrag,
						onDragLeave: ({ source: { data } }) => {
							if (isCard(data)) {
								resetDropTarget();
								setIsCardOverColumn(false);
								return;
							}

							setClosestEdge(null);
						},
						onDrop: ({ source: { data } }) => {
							if (isCard(data)) {
								setIsCardOverColumn(false);
								return;
							}

							if (isColumn(data)) {
								setClosestEdge(null);
							}
						},
					})
				: noop,
		);
	}, [
		canDropInGroup,
		columnDropTargetRef,
		columnHandleRef,
		column,
		index,
		inferredDropTargetTransition,
		resetDropTarget,
		updateDropTarget,
		startColumnDrag,
		renderDragPreview,
		cleanupDragPreview,
		resetDraggedColumn,
	]);

	return {
		canDropInGroup,
		closestEdge,
		dragPreviewState,
		isCardOverColumn,
		isDraggingColumn,
	};
};
