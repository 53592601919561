/**
 * @generated SignedSource<<f021762a9c66ab8bf5bfab4c2d129d80>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SaveResetViewSettings_view$data = {
  readonly canPublishViewConfig: boolean | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "SaveResetViewSettings_view";
};
export type SaveResetViewSettings_view$key = {
  readonly " $data"?: SaveResetViewSettings_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaveResetViewSettings_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "SaveResetViewSettings_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "ScalarField",
      "name": "canPublishViewConfig"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "4984ccf5ecf43d6b96d4d712dfe4633b";

export default node;
