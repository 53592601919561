import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dropdownHeaderTitle: {
		id: 'work-management-board.board.column.column-header.header-dropdown.dropdown-header-title',
		defaultMessage: 'Actions',
		description:
			'Title for the dropdown in the board column header, the dropdown contain multiple actions that can be done for the column',
	},
	rename: {
		id: 'work-management-board.board.column.column-header.header-dropdown.rename',
		defaultMessage: 'Rename',
		description: 'Action name for renaming the column',
	},
	delete: {
		id: 'work-management-board.board.column.column-header.header-dropdown.delete',
		defaultMessage: 'Delete',
		description: 'Action name for deleting the column',
	},
	deleteDisabledTooltip: {
		id: 'work-management-board.board.column.column-header.header-dropdown.delete-disabled-tooltip',
		defaultMessage: 'The board must contain at least one column',
		description:
			'Tooltip content for disabled delete action explaining that the board must contain at least one column',
	},
	columnActionsMenu: {
		id: 'work-management-board.board.column.column-header.header-dropdown.column-actions-menu',
		defaultMessage: 'Column actions',
		description:
			'Label for the button that opens the more actions menu on a column in the Jira board.',
	},
	columnActionsWithColumn: {
		id: 'work-management-board.board.column.column-header.header-dropdown.column-actions-with-column',
		defaultMessage: 'Column actions on column {columnName}',
		description:
			'Label for the button that opens the more actions menu on a column in the Jira board.',
	},
	moreActionsTooltip: {
		id: 'work-management-board.board.column.column-header.header-dropdown.more-actions-tooltip',
		defaultMessage: 'More actions',
		description:
			'Tooltip for the button that opens the more actions menu on a column in the Jira board.',
	},
});
