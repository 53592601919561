/**
 * @generated SignedSource<<e06ce07ac88e90d2f6eee9d89e596a19>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardInlineCreate_column$data = {
  readonly __typename: "JiraBoardViewAssigneeColumn";
  readonly user: {
    readonly accountId: string;
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "BoardInlineCreate_column";
} | {
  readonly __typename: "JiraBoardViewCategoryColumn";
  readonly category: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly optionId: string;
    readonly value: string;
  } | null | undefined;
  readonly " $fragmentType": "BoardInlineCreate_column";
} | {
  readonly __typename: "JiraBoardViewPriorityColumn";
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
    readonly name: string;
    readonly priorityId: string;
  };
  readonly " $fragmentType": "BoardInlineCreate_column";
} | {
  readonly __typename: "JiraBoardViewStatusColumn";
  readonly statuses: ReadonlyArray<{
    readonly name: string;
    readonly statusCategory: {
      readonly statusCategoryId: string;
    };
    readonly statusId: string;
  } | null | undefined>;
  readonly " $fragmentType": "BoardInlineCreate_column";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BoardInlineCreate_column";
};
export type BoardInlineCreate_column$key = {
  readonly " $data"?: BoardInlineCreate_column$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardInlineCreate_column">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardInlineCreate_column",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "__typename"
      },
      "action": "THROW",
      "path": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraStatus",
            "kind": "LinkedField",
            "name": "statuses",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v0/*: any*/),
                "action": "THROW",
                "path": "statuses.name"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "ScalarField",
                  "name": "statusId"
                },
                "action": "THROW",
                "path": "statuses.statusId"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "statusCategoryId"
                      },
                      "action": "THROW",
                      "path": "statuses.statusCategory.statusCategoryId"
                    }
                  ]
                },
                "action": "THROW",
                "path": "statuses.statusCategory"
              }
            ]
          },
          "action": "THROW",
          "path": "statuses"
        }
      ],
      "type": "JiraBoardViewStatusColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraPriority",
            "kind": "LinkedField",
            "name": "priority",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v0/*: any*/),
                "action": "THROW",
                "path": "priority.name"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "ScalarField",
                  "name": "priorityId"
                },
                "action": "THROW",
                "path": "priority.priorityId"
              },
              {
                "kind": "ScalarField",
                "name": "iconUrl"
              }
            ]
          },
          "action": "THROW",
          "path": "priority"
        }
      ],
      "type": "JiraBoardViewPriorityColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v0/*: any*/),
              "action": "THROW",
              "path": "user.name"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "accountId"
              },
              "action": "THROW",
              "path": "user.accountId"
            },
            {
              "kind": "ScalarField",
              "name": "picture"
            }
          ]
        }
      ],
      "type": "JiraBoardViewAssigneeColumn"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "value"
              },
              "action": "THROW",
              "path": "category.value"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "optionId"
              },
              "action": "THROW",
              "path": "category.optionId"
            },
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                }
              ]
            }
          ]
        }
      ],
      "type": "JiraBoardViewCategoryColumn"
    }
  ],
  "type": "JiraBoardViewColumn",
  "abstractKey": "__isJiraBoardViewColumn"
};
})();

(node as any).hash = "03401b9f93c13be3dd0237d7e3cd36b6";

export default node;
