import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const cardConfluencePagesMenuContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-business-card-confluence-pages-menu-content-entrypoint" */ './CardConfluencePagesMenuContent.tsx'
			),
	),
	getPreloadProps: () => ({}),
});
