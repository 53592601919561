import { useCallback, useState } from 'react';
import {
	useExperienceFail,
	useExperienceStart,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	ASSIGNEE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	PRIORITY_TYPE,
	STATUS_TYPE,
	SUMMARY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { LOAD_SUBTASKS_EXPERIENCE } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';
import { useFetchBoardIssues } from '../../services/board-issues/index.tsx';
import { useTransformIssues } from '../../utils/issue-transformer/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import messages from './messages.tsx';

export const SUBTASK_FIELD_IDS = [
	ASSIGNEE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	PRIORITY_TYPE,
	STATUS_TYPE,
	SUMMARY_TYPE,
];

export const useIssueSubtasks = (issue: BoardIssue) => {
	const { updateIssues } = useIssueStoreActions();
	const fetchIssues = useFetchBoardIssues();
	const transformToIssues = useTransformIssues();
	const { showFlag } = useFlagsService();
	const [loading, setLoading] = useState(false);

	const startExperience = useExperienceStart(LOAD_SUBTASKS_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(LOAD_SUBTASKS_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(LOAD_SUBTASKS_EXPERIENCE);

	const fetch = useCallback(async () => {
		startExperience();
		setLoading(true);

		try {
			const results = await fetchIssues({
				fieldIds: SUBTASK_FIELD_IDS,
				jql: `parent = ${issue.id}`,
			});

			const boardIssues = transformToIssues(results);
			updateIssues(boardIssues);

			markExperienceSuccess();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			showFlag({
				description: messages.errorMessage,
				title: messages.errorTitle,
				type: 'error',
				messageId: 'work-management-board.controllers.issue-subtasks.show-flag.error',
				messageType: 'transactional',
			});
			markExperienceFailed('Failed to load issue subtasks', error);
		} finally {
			setLoading(false);
		}
	}, [
		issue,
		fetchIssues,
		transformToIssues,
		updateIssues,
		showFlag,
		startExperience,
		markExperienceSuccess,
		markExperienceFailed,
	]);

	return { loading, fetch };
};
