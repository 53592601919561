/** @jsx jsx */
import React, { memo, useCallback, useMemo, useState, type RefCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import CustomizeIcon from '@atlaskit/icon/core/customize';
import { Box } from '@atlaskit/primitives';
import { cssMap, jsx } from '@atlaskit/css';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import {
	ActionsWrapper,
	LeftActionsWrapper,
	RightActionsWrapper,
} from '@atlassian/jira-business-app-wrapper/src/ui/actions-wrapper/index.tsx';
import type { QuickFilterKeys } from '@atlassian/jira-business-filters/src/common/types.tsx';
import { Filters } from '@atlassian/jira-business-filters/src/ui/filters/index.tsx';
import { SimpleSearchField } from '@atlassian/jira-business-filters/src/ui/simple-search-field/index.tsx';
import AddPeopleWithAvatar from '@atlassian/jira-business-invite-people/src/ui/add-people-with-avatars/index.tsx';
import { JWMShareButton } from '@atlassian/jira-business-invite-people/src/ui/jwm-share-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { expVal } from '@atlassian/jira-feature-experiments';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	ISSUE_TYPE,
	PARENT_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	STATUS_TYPE,
	USER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { BoardHeader_view$key } from '@atlassian/jira-relay/src/__generated__/BoardHeader_view.graphql';
import type { BoardHeader_project$key } from '@atlassian/jira-relay/src/__generated__/BoardHeader_project.graphql';
import { Collapsible } from '@atlassian/jira-business-collapsible/src/index.tsx';
import { JiraViewAri } from '@atlassian/ari/jira';
import { useJWMBoardFeatures } from '../../controllers/features-context/index.tsx';
import GroupSelector from './group-selector/BoardGroupSelector.tsx';
import messages from './messages.tsx';
import { SaveResetViewSettings } from './save-reset-view-settings/SaveResetViewSettings.tsx';
import { moreMenuContentEntryPoint } from './more-menu-content/entrypoint.tsx';

const HIDDEN_QUICKFILTERS: QuickFilterKeys[] = ['DONE', 'ACTIVE_SPRINTS'];

const ALLOWED_FIELD_TYPES = new Set<string>([
	ASSIGNEE_TYPE,
	ISSUE_TYPE,
	PRIORITY_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	USER_CF_TYPE,
	STATUS_TYPE,
	PARENT_TYPE,
]);

const styles = cssMap({
	menuContent: {
		width: '320px',
	},
	fallbackMenu: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: token('space.200'),
		paddingBottom: token('space.200'),
	},
	saveResetButtonWrapper: {
		marginLeft: token('space.negative.100'),
	},
});

type Props = {
	projectFragment: BoardHeader_project$key;
	viewFragment: BoardHeader_view$key;
};

const BoardHeader = ({ projectFragment, viewFragment }: Props) => {
	const { formatMessage } = useIntl();
	const { isShareButtonEnabled, inviteButtonInteraction, isPresenceEnabled, withSaveRevertView } =
		useJWMBoardFeatures();

	const project = useFragment(
		graphql`
			fragment BoardHeader_project on JiraProject {
				id @required(action: THROW)
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BoardHeader_view on JiraBoardView {
				id @required(action: THROW)
				isViewConfigModified @required(action: THROW)
				...SaveResetViewSettings_view
				...BoardGroupSelector_view
			}
		`,
		viewFragment,
	);

	const shareButton = (
		<JWMShareButton
			shareTitle={messages.shareTitle}
			integrationType={integrationTypes.BOARD}
			shareContentType="board"
		/>
	);

	let collabView: string | undefined;

	if (fg('sv-403_business_board_presence_channel_per_view')) {
		const jiraViewAri = JiraViewAri.parse(view.id);
		// scopeId is <projectId>/<viewType>/<viewId>
		collabView = jiraViewAri.scopeId.split('/').slice(1).join('/');
	}

	const searchBar = <SimpleSearchField placeholder={messages.searchBoardLabel} view="board" />;
	const addPeopleWithPresence = (
		<AddPeopleWithAvatar
			view="board"
			collabView={collabView}
			allowedFieldTypes={ALLOWED_FIELD_TYPES}
			isPresenceEnabledInContext={isPresenceEnabled}
			isOpenNewTabOnClick={inviteButtonInteraction === 'openNewTab'}
			allowCustomFields
			hasInviteButton={!isVisualRefreshEnabled()}
			shouldShowUnassignedUser={fg('show_unassigned_avatar_in_assignee_filter')}
		/>
	);

	const shareActionButton = isShareButtonEnabled && shareButton;

	const filters = (
		<Filters
			view="board"
			allowedFieldTypes={ALLOWED_FIELD_TYPES}
			hiddenQuickFilters={HIDDEN_QUICKFILTERS}
			allowCustomFields
		/>
	);

	const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
	const closeMoreMenu = useCallback(() => setIsMoreMenuOpen(false), []);
	const toggleMoreMenu = useCallback(() => setIsMoreMenuOpen((oldIsOpen) => !oldIsOpen), []);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		moreMenuContentEntryPoint,
		useMemo(() => ({ projectAri: project.id, viewId: view.id }), [project.id, view.id]),
	);
	const moreMenuContentTrigger = useEntryPointButtonTrigger(entryPointActions, !isMoreMenuOpen);
	const moreMenuProps = useMemo(
		() => ({
			onClose: closeMoreMenu,
		}),
		[closeMoreMenu],
	);
	const moreMenuContent = useCallback(
		() => (
			<Box xcss={styles.menuContent}>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="business-board-more-menu"
					packageName="@atlassian/jira-work-management-board"
					runtimeProps={moreMenuProps}
					fallback={
						<Box xcss={styles.fallbackMenu}>
							<Spinner />
						</Box>
					}
				/>
			</Box>
		),
		[entryPointReferenceSubject, moreMenuProps],
	);
	const moreMenuTrigger = useCallback(
		(triggerProps: TriggerProps | { ref: RefCallback<HTMLElement> }) => (
			<Tooltip
				content={formatMessage(messages.viewSettingsButton)}
				position="top"
				hideTooltipOnClick
			>
				<ThemedButton
					{...triggerProps}
					ref={(element: HTMLElement) => {
						moreMenuContentTrigger(element);
						if (typeof triggerProps.ref === 'function') {
							triggerProps.ref(element);
						}
					}}
					appearance={isVisualRefreshEnabled() ? undefined : 'subtle'}
					iconBefore={
						isVisualRefreshEnabled() ? undefined : <MoreIcon label="" LEGACY_size="small" />
					}
					iconAfter={
						isVisualRefreshEnabled() ? (
							<CustomizeIcon label="" spacing="spacious" color="currentColor" />
						) : undefined
					}
					isSelected={isMoreMenuOpen}
					onClick={toggleMoreMenu}
					aria-label={formatMessage(messages.viewSettingsButton)}
				>
					{isVisualRefreshEnabled() ? null : formatMessage(messages.moreButtonOld)}
				</ThemedButton>
			</Tooltip>
		),
		[formatMessage, isMoreMenuOpen, moreMenuContentTrigger, toggleMoreMenu],
	);

	return (
		<UFOSegment name="business-board-header">
			<ActionsWrapper>
				<LeftActionsWrapper>
					{searchBar}
					{addPeopleWithPresence}
					{isVisualRefreshEnabled() && filters}
				</LeftActionsWrapper>

				<RightActionsWrapper>
					{!isVisualRefreshEnabled() && !getWillShowNav4() && shareActionButton}

					{!isVisualRefreshEnabled() && filters}

					<GroupSelector viewFragment={view} />

					<Popup
						isOpen={isMoreMenuOpen}
						onClose={closeMoreMenu}
						placement="bottom-end"
						messageId="work-management-board.ui.header.popup"
						messageType="transactional"
						content={moreMenuContent}
						trigger={moreMenuTrigger}
						shouldRenderToParent={false}
						testId="work-management-board.ui.header.more-menu-popup"
					/>

					{withSaveRevertView &&
						view &&
						getWillShowNav4() &&
						expVal('saved_views', 'enabled', false) && (
							<div css={styles.saveResetButtonWrapper}>
								<Collapsible
									animationDuration={260}
									direction="horizontal"
									isExpanded={view.isViewConfigModified ?? false}
								>
									<SaveResetViewSettings viewFragment={view} />
								</Collapsible>
							</div>
						)}
				</RightActionsWrapper>
			</ActionsWrapper>
		</UFOSegment>
	);
};

export default memo(BoardHeader);
