/**
 * @generated SignedSource<<4e222cbc69f06d73479244fed52fb435>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardHeader_view$data = {
  readonly id: string;
  readonly isViewConfigModified: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"BoardGroupSelector_view" | "SaveResetViewSettings_view">;
  readonly " $fragmentType": "BoardHeader_view";
};
export type BoardHeader_view$key = {
  readonly " $data"?: BoardHeader_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardHeader_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardHeader_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isViewConfigModified"
      },
      "action": "THROW",
      "path": "isViewConfigModified"
    },
    {
      "kind": "FragmentSpread",
      "name": "SaveResetViewSettings_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardGroupSelector_view"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "717ca3a5897c05f29d846d023794b115";

export default node;
