import type { BoardGroupByType } from '@atlassian/jira-business-common/src/common/types/group-by.tsx';
import {
	GROUP_BY_STATUS,
	GROUP_BY_ASSIGNEE,
	GROUP_BY_PRIORITY,
	GROUP_BY_CATEGORY,
} from '@atlassian/jira-business-constants/src/index.tsx';

const GROUP_BY_TYPES = new Set([
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
]);

export const isGroupByType = (groupBy: string): groupBy is BoardGroupByType => {
	return GROUP_BY_TYPES.has(groupBy);
};
