import React, { memo, useCallback, useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import MenuExpandIcon from '@atlaskit/icon/core/migration/menu--menu-expand';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { MutationErrorCollection } from '@atlassian/jira-mutation-error/src/mutation-error-collection.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { BoardGroupSelector_view$key } from '@atlassian/jira-relay/src/__generated__/BoardGroupSelector_view.graphql';
import type { BoardGroupSelectorSetGroupByMutation } from '@atlassian/jira-relay/src/__generated__/BoardGroupSelectorSetGroupByMutation.graphql';
import { useFlagsService } from '@atlassian/jira-flags';
import { getUrlSettingsInput } from '../../../common/url-settings-input.tsx';
import messages from './messages.tsx';

type Props = {
	viewFragment: BoardGroupSelector_view$key;
};

const experience = 'setBoardViewGroupBy';

const BoardGroupSelector = ({ viewFragment }: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const closeMenu = useCallback(() => setIsOpen(false), []);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const startExperience = useExperienceStart({
		experience,
		analyticsSource: 'jiraWorkManagementBoard',
	});
	const markExperienceSuccess = useExperienceSuccess({
		experience,
	});
	const markExperienceFailed = useExperienceFail({
		experience,
	});

	const view = useFragment(
		graphql`
			fragment BoardGroupSelector_view on JiraBoardView {
				id @required(action: THROW)
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
					fieldName @required(action: THROW)
				}
				groupByOptions @required(action: THROW) {
					fieldId @required(action: THROW)
					fieldName @required(action: THROW)
				}
			}
		`,
		viewFragment,
	);

	const [commit, isInFlight] = useMutation<BoardGroupSelectorSetGroupByMutation>(graphql`
		mutation BoardGroupSelectorSetGroupByMutation($input: JiraSetBoardViewGroupByInput!) {
			jira_setBoardViewGroupBy(input: $input) {
				success
				boardView {
					...BusinessBoard_view
				}
				errors {
					message
					extensions {
						errorType
						statusCode
					}
				}
			}
		}
	`);

	const setGroupBy = useCallback(
		(newGroupBy: string) => {
			startExperience();

			const handleFailure = (error: Error) => {
				markExperienceFailed('BoardGroupSelector jira_setBoardViewGroupBy mutation failed', error);
				showFlag({
					messageId:
						'work-management-board.ui.header.group-selector.show-flag.error.set-group-by-failed',
					messageType: 'transactional',
					title: messages.setGroupByFailedTitle,
					description: messages.setGroupByFailedMessage,
					type: 'error',
				});
			};

			commit({
				variables: {
					input: {
						viewId: view.id,
						fieldId: newGroupBy,
						settings: getUrlSettingsInput(),
					},
				},
				onError(error: Error) {
					handleFailure(error);
				},
				onCompleted(response) {
					if (response.jira_setBoardViewGroupBy?.success !== true) {
						const error = new MutationErrorCollection(response.jira_setBoardViewGroupBy?.errors);
						handleFailure(error);
						return;
					}

					markExperienceSuccess();
					fireTrackAnalytics(createAnalyticsEvent({}), 'groupBy changed');
				},
			});

			closeMenu();
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'groupBy', {
				value: newGroupBy,
			});
		},
		[
			closeMenu,
			commit,
			createAnalyticsEvent,
			markExperienceFailed,
			markExperienceSuccess,
			showFlag,
			startExperience,
			view.id,
		],
	);

	const popupContent = useCallback(() => {
		const checkIcon = (
			<CheckIcon
				LEGACY_size="medium"
				spacing="spacious"
				label={formatMessage(messages.groupBySelected)}
				color={token('color.icon.selected')}
			/>
		);

		return (
			<MenuGroup>
				<Section>
					{view.groupByOptions.map((option) => (
						<ButtonItem
							key={option.fieldId}
							onClick={() => setGroupBy(option.fieldId)}
							iconAfter={view.groupByConfig.fieldId === option.fieldId && checkIcon}
						>
							{option.fieldName}
						</ButtonItem>
					))}
				</Section>
			</MenuGroup>
		);
	}, [formatMessage, setGroupBy, view.groupByConfig.fieldId, view.groupByOptions]);

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) =>
			isVisualRefreshEnabled() ? (
				<ThemedButton
					{...triggerProps}
					aria-label={formatMessage(messages.groupByButtonAriaLabel, {
						label: view.groupByConfig.fieldName,
					})}
					isLoading={isInFlight}
					isSelected
					onClick={() => setIsOpen((wasOpen) => !wasOpen)}
				>
					{formatMessage(messages.groupByButtonLabel)}
					{view.groupByConfig.fieldName}
				</ThemedButton>
			) : (
				<ThemedButton
					{...triggerProps}
					iconBefore={<MenuExpandIcon label="" LEGACY_size="small" />}
					isLoading={isInFlight}
					isSelected={isOpen}
					onClick={() => setIsOpen((wasOpen) => !wasOpen)}
					appearance="subtle"
				>
					{formatMessage(messages.groupByButtonLabelOld)}
					{view.groupByConfig.fieldName}
				</ThemedButton>
			),
		[formatMessage, isInFlight, isOpen, view.groupByConfig.fieldName],
	);

	return (
		<Popup
			content={popupContent}
			isOpen={isOpen}
			onClose={closeMenu}
			placement="bottom-end"
			trigger={popupTrigger}
		/>
	);
};

export default memo(BoardGroupSelector);
