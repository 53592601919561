/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { BoardInvalidDropTargetMessage_column$key } from '@atlassian/jira-relay/src/__generated__/BoardInvalidDropTargetMessage_column.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { COLUMN_HEADER_HEIGHT } from '../../../../common/constants.tsx';
import RestrictedIcon from '../../../../common/ui/restricted-icon/index.tsx';
import messages from './messages.tsx';

type Props = {
	columnFragment: BoardInvalidDropTargetMessage_column$key;
};

export const BoardInvalidDropTargetMessage = memo(({ columnFragment }: Props) => {
	const { formatMessage } = useIntl();
	const column = useFragment(
		graphql`
			fragment BoardInvalidDropTargetMessage_column on JiraBoardViewColumn {
				__typename
			}
		`,
		columnFragment,
	);

	let message: MessageDescriptor;

	switch (column.__typename) {
		case 'JiraBoardViewAssigneeColumn':
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdateAssigneeMessageIssueTermRefresh
				: messages.unableToUpdateAssigneeMessage;
			break;
		case 'JiraBoardViewCategoryColumn':
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdateCategoryMessageIssueTermRefresh
				: messages.unableToUpdateCategoryMessage;
			break;
		case 'JiraBoardViewPriorityColumn':
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdatePriorityMessageIssueTermRefresh
				: messages.unableToUpdatePriorityMessage;
			break;
		case 'JiraBoardViewStatusColumn':
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.transitionNotAvailableMessageIssueTermRefresh
				: messages.transitionNotAvailableMessage;
			break;
		default: {
			const exhaustiveCheck = column.__typename;
			throw new Error(`Unexpected group type: ${exhaustiveCheck}`);
		}
	}

	return (
		<div
			css={wrapperStyes}
			data-testid="work-management-board.ui.board.column.invalid-drop-target-message"
		>
			<div css={contentWrapperStyles}>
				<RestrictedIcon />
				<Text as="p">{formatMessage(message)}</Text>
			</div>
		</div>
	);
});

const wrapperStyes = css({
	backgroundColor: token('color.background.danger'),
	borderRadius: `0 0 ${token('border.radius.200')} ${token('border.radius.200')}`,
	inset: '0',
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${COLUMN_HEADER_HEIGHT}px`,
});

const contentWrapperStyles = css({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100'),
	paddingTop: token('space.400'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.400'),
	paddingLeft: token('space.200'),
	textAlign: 'center',
});
