import React, {
	memo,
	useCallback,
	useEffect,
	useState,
	useRef,
	useMemo,
	type KeyboardEvent,
} from 'react';
import { styled, css } from '@compiled/react';
import noop from 'lodash/noop';
import ButtonLegacy from '@atlaskit/button';
import Button, { IconButton } from '@atlaskit/button/new';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import { xcss, Text, Flex } from '@atlaskit/primitives';
import type { SingleValue } from '@atlaskit/react-select/types';
import {
	type OptionProps,
	components,
	PopupSelect,
	type GroupProps,
	type ModifierList,
} from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type { BusinessIssueType } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import {
	ExperienceErrorBoundary,
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { LIST_VIEW_SOURCE } from '@atlassian/jira-issue-type-config-common/src/types.tsx';
import {
	CREATE_TYPE_ID_PREFERENCE,
	SORTING_PREFERENCE,
} from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { useFiltersPreference } from '@atlassian/jira-business-filters/src/controllers/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_SUMMARY_LENGTH } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { InlineConfigButtons } from '@atlassian/jira-inline-config-buttons-for-select/src/index.tsx';
import { EditIssueTypeModalEntrypointTrigger } from '@atlassian/jira-edit-issue-type-modal-trigger/src/EditIssueTypeModalEntrypointTrigger.tsx';
import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';
import {
	InlineCardCreateAdditionalFields,
	type DueDate,
	type InlineCardCreateAdditionalFieldsProps,
} from '@atlassian/jira-inline-card-create-additional-fields/src/InlineCardCreateAdditionalFields.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { DUE_DATE_TYPE, ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { getUserShape } from '@atlassian/jira-issue-field-assignee/src/common/utils.tsx';
import { CREATE_ISSUE_EXPERIENCE, EDIT_ISSUE_TYPE_EXPERIENCE } from '../../constants.tsx';
import { CancelledError } from '../../controllers/create-issue-dialog/index.tsx';
import { useCreateIssue } from '../../controllers/issue-create-context/IssueCreateProvider.tsx';
import type {
	AdditionalCreateIssueContext,
	CreateIssueReturn,
	InlineCreateStyles,
	Location,
} from '../../types.tsx';
import messages from './messages.tsx';
import ReturnIcon from './return-icon/index.tsx';
import { SelectFooter } from './select-footer/SelectFooter.tsx';
import {
	REFOCUS_DELAY_MS,
	FOCUS_TO_CREATE_MEASURE_NAME,
	FOCUS_TO_CREATE_START_MARK,
	FOCUS_TO_CREATE_END_MARK,
} from './constants.tsx';

const GENERIC_ICON_URL = '/icons/issuetypes/genericissue.svg';

export const CARD_APPEARANCE = 'CARD' as const;
export const ROW_APPEARANCE = 'ROW' as const;

const ISSUE_TYPE = 'issueType';

type InlineCreateAppearance = typeof CARD_APPEARANCE | typeof ROW_APPEARANCE;

type ExperienceId = 'board' | 'list' | 'calendar' | 'timeline';

export type Props<TIssue> = InlineCreateStyles & {
	allowedIssueTypeIds?: string[];
	appearance: InlineCreateAppearance;
	fieldIdsToReturn?: string[];
	/**
	 * @deprecated
	 * this prop is no longer required after visual-refresh, remove when cleaning up
	 */
	isCompact?: boolean;
	location: Location;
	maxHierarchyLevel?: number;
	minHierarchyLevel?: number;
	onBlur?: (event?: React.KeyboardEvent | React.MouseEvent) => void;
	onKeyDown?: (event: React.KeyboardEvent, textContent?: string) => void;
	onPrepareIssueContext?: () => AdditionalCreateIssueContext;
	onSubmit: (result: CreateIssueReturn<TIssue>) => void;
	popperStrategy?: 'absolute' | 'fixed';
	allowIssueTypeConfiguration?: boolean;
	onIssueTypeConfigured?: () => void;
	// To show assignee and duedate fields
	shouldShowAdditionalFields?: () => boolean;
	additionalFieldProps?: Pick<
		InlineCardCreateAdditionalFieldsProps,
		'isCompactDueDatePicker' | 'shouldShowNameLabel'
	>;
	additionalFooterContent?: React.ReactNode;
	/**
	 * When InlineCreateForm is displayed under a group, this prop includes details about the group
	 * When this prop is omitted, it means InlineCreateform is not under a group
	 * `group.fieldId` defines how which field is being used to group work types and can be used as the key of a tagged union to define different types for the property `value`
	 * `group.value` contains any data necessary to prefill the field defined in `fieldId`
	 * */
	group?: GroupType | null | false;
	placeholderOverride?: string;
	shouldSubmitOnBlur?: boolean;
	isCompactCreateButton?: boolean;
};
export type GroupType = {
	fieldId: typeof ASSIGNEE_TYPE;
	value: {
		accountId: string;
		avatarUrl: string;
		displayName: string;
	};
};
type PropsWithIssueTypes<TIssue> = Props<TIssue> & {
	issueTypes: BusinessIssueType[];
};

type IssueTypeOption = {
	label: string;
	value: string;
	issueType: BusinessIssueType;
	optionType: typeof ISSUE_TYPE;
};

type SelectOption = IssueTypeOption;

type OptionWithInlineEditProps = OptionProps<SelectOption> & {
	project: JiraProject;
	onEditModalClose: () => void;
	onEditButtonClick: () => void;
	onRefetchIssueTypesWhenIssueTypeUpdated?: () => void;
};

const useExperience = (location: Location): ExperienceDetails =>
	useMemo(() => {
		let experienceId: ExperienceId;

		switch (location) {
			case 'listFooter':
			case 'listSibling':
			case 'listGroup':
			case 'listHierarchy':
				experienceId = 'list';
				break;
			case 'boardFooter':
			case 'boardSibling':
				experienceId = 'board';
				break;
			case 'calendarDate':
				experienceId = 'calendar';
				break;
			case 'timelineFooter':
			case 'timelineSibling':
			case 'timelineHierarchy':
				experienceId = 'timeline';
				break;
			default:
				throw new Error(`Unknown location ${location}`);
		}

		return {
			...CREATE_ISSUE_EXPERIENCE,
			experienceId,
		};
	}, [location]);

const Group = ({ children }: GroupProps<SelectOption>) => {
	return <ExtraLinksGroup>{children}</ExtraLinksGroup>;
};

const Option = ({ children, ...props }: OptionProps<SelectOption>) => {
	if (props.data.optionType === ISSUE_TYPE) {
		return (
			<components.Option {...props}>
				<OptionWrapper data-testid="business-issue-create.ui.inline-create-form.option">
					<OptionTypeIcon src={props.data.issueType.avatarUrl ?? GENERIC_ICON_URL} alt="" />
					{children}
				</OptionWrapper>
			</components.Option>
		);
	}
};

const OptionWithInlineEdit = ({
	children,
	project,
	onEditModalClose,
	onEditButtonClick,
	onRefetchIssueTypesWhenIssueTypeUpdated,
	...props
}: OptionWithInlineEditProps) => {
	const editIssueTypeButtonRef = useRef<HTMLButtonElement>(null);

	const queryParams = useMemo(
		() => ({
			projectKey: project.key,
			projectId: project.id,
			ufoExperience: EDIT_ISSUE_TYPE_EXPERIENCE,
			issueTypeId: String(props.data.value),
			calledFrom: LIST_VIEW_SOURCE,
			onRefetchIssueTypesWhenIssueTypeUpdated: onRefetchIssueTypesWhenIssueTypeUpdated ?? noop,
			onEditModalClose: fg('fix_refocus_issue_after_modal_close_on_list_view')
				? onEditModalClose
				: noop,
			buttonRef: !fg('inline_config_a11y_improvements') ? editIssueTypeButtonRef : undefined,
		}),
		[
			onEditModalClose,
			onRefetchIssueTypesWhenIssueTypeUpdated,
			project.key,
			project.id,
			props.data.value,
		],
	);

	if (props.data.optionType === ISSUE_TYPE) {
		return (
			<components.Option {...props}>
				<InlineConfigButtons
					buttonRef={!fg('inline_config_a11y_improvements') ? editIssueTypeButtonRef : undefined}
					isFocused={props.isFocused}
					showEditButton
					queryParams={queryParams}
					EditButtonWrapper={EditIssueTypeModalEntrypointTrigger}
					editActionSubjectId="editIssueTypeButton"
					shouldStopPropagationOnClick={fg('inline_config_a11y_improvements')}
					onEditButtonClick={
						fg('inline_config_a11y_improvements') ||
						fg('fix_refocus_issue_after_modal_close_on_list_view')
							? onEditButtonClick
							: undefined
					}
				>
					<OptionWrapper data-testid="business-issue-create.ui.inline-create-form.option">
						<OptionTypeIcon src={props.data.issueType.avatarUrl ?? GENERIC_ICON_URL} alt="" />
						{children}
					</OptionWrapper>
				</InlineConfigButtons>
			</components.Option>
		);
	}
};

export const useIsReady = () => {
	const isReadyRef = useRef(false);
	useEffect(() => {
		setTimeout(() => {
			isReadyRef.current = true;
		}, 100);
	}, []);

	return isReadyRef;
};

const isComposing = (event: React.KeyboardEvent) => event.nativeEvent.isComposing;

const ChevronDownIcon = () => {
	if (isVisualRefreshEnabled()) {
		return <ChevronDown label="" color={token('color.icon')} />;
	}
	return <ChevronDown spacing="spacious" label="" />;
};

const InlineCreateForm = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	borderRadius,
	fieldIdsToReturn = [],
	indent,
	isCompact,
	location,
	maxHierarchyLevel = Number.POSITIVE_INFINITY,
	minHeight,
	minHierarchyLevel = 0,
	onBlur,
	onKeyDown,
	onPrepareIssueContext,
	onSubmit,
	popperStrategy = 'absolute',
	allowIssueTypeConfiguration,
	onIssueTypeConfigured,
	shouldShowAdditionalFields = () => false,
	additionalFieldProps,
	additionalFooterContent,
	group,
	shouldSubmitOnBlur = true,
	placeholderOverride,
	isCompactCreateButton,
}: Props<TIssue>) => {
	const { formatMessage } = useIntl();
	const {
		data: { issueTypes },
		loading,
	} = useIssueTypesAndFields({ issueOperation: 'CREATE' });

	if (loading) {
		return (
			<FullContent
				appearance={appearance}
				borderRadius={borderRadius}
				indent={indent}
				minHeight={minHeight}
			>
				<Flex alignItems="center" justifyContent="center" xcss={spinnerWrapperStyles}>
					<Spinner
						label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.loadingIssueCreateFormIssueTermRefresh
								: messages.loadingIssueCreateForm,
						)}
					/>
				</Flex>
			</FullContent>
		);
	}

	// this is not a scenario we expect to happen but we know it does happen when a project is just created
	// the issue types and fields have a retry mechanism to avoid this but we want to handle it here as well
	// to avoid runtime errors
	if (issueTypes.length === 0) {
		throw new Error('Unable to show the issue create form, no issue types available');
	}

	return (
		<InlineCreateFormInternal
			allowedIssueTypeIds={allowedIssueTypeIds}
			appearance={appearance}
			borderRadius={borderRadius}
			fieldIdsToReturn={fieldIdsToReturn}
			indent={indent}
			isCompact={isCompact}
			issueTypes={issueTypes}
			location={location}
			maxHierarchyLevel={maxHierarchyLevel}
			minHeight={minHeight}
			minHierarchyLevel={minHierarchyLevel}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			onPrepareIssueContext={onPrepareIssueContext}
			onSubmit={onSubmit}
			popperStrategy={popperStrategy}
			allowIssueTypeConfiguration={allowIssueTypeConfiguration}
			onIssueTypeConfigured={onIssueTypeConfigured}
			shouldShowAdditionalFields={shouldShowAdditionalFields}
			additionalFieldProps={additionalFieldProps}
			additionalFooterContent={additionalFooterContent}
			group={fg('jira_show_additional_field_list_view_icc') && group}
			placeholderOverride={placeholderOverride}
			shouldSubmitOnBlur={shouldSubmitOnBlur}
			isCompactCreateButton={isCompactCreateButton}
		/>
	);
};

// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line complexity
const InlineCreateFormInternal = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	borderRadius,
	fieldIdsToReturn = [],
	indent,
	isCompact,
	issueTypes,
	location,
	maxHierarchyLevel = Number.POSITIVE_INFINITY,
	minHeight,
	minHierarchyLevel = 0,
	onBlur,
	onKeyDown,
	onPrepareIssueContext,
	onSubmit,
	popperStrategy = 'absolute',
	allowIssueTypeConfiguration = false,
	shouldShowAdditionalFields = () => false,
	additionalFieldProps = { shouldShowNameLabel: true, isCompactDueDatePicker: false },
	onIssueTypeConfigured,
	additionalFooterContent,
	group,
	shouldSubmitOnBlur = false,
	placeholderOverride,
	isCompactCreateButton = false,
}: PropsWithIssueTypes<TIssue>) => {
	const { formatMessage } = useIntl();
	const project = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { jql } = useFiltersPreference();
	const [sorting] = useViewPreference(SORTING_PREFERENCE);
	const [preferredIssueTypeId, savePreferredIssueTypeId] =
		useViewPreference(CREATE_TYPE_ID_PREFERENCE);
	const showSuccessFlag = jql != null || sorting != null;

	const experience = useExperience(location);
	const startExperience = useExperienceStart(experience);
	const markExperienceSuccess = useExperienceSuccess(experience);
	const markExperienceFail = useExperienceFail(experience);
	const abortExperience = useExperienceAbort(experience);

	const issueTypeOptions = useMemo(() => {
		const validIssueTypes: BusinessIssueType[] = issueTypes.filter((issueType) => {
			const isAllowed = allowedIssueTypeIds?.includes(issueType.id) ?? true;

			return (
				isAllowed &&
				issueType.hierarchyLevel >= minHierarchyLevel &&
				issueType.hierarchyLevel <= maxHierarchyLevel
			);
		});

		const typeOptions: IssueTypeOption[] = validIssueTypes.map((issueType) => ({
			label: issueType.name,
			value: issueType.id,
			issueType,
			optionType: ISSUE_TYPE,
		}));

		typeOptions.sort(
			// sort by decreasing hierarchy level
			(a, b) => b.issueType.hierarchyLevel - a.issueType.hierarchyLevel,
		);

		return typeOptions;
	}, [allowedIssueTypeIds, issueTypes, maxHierarchyLevel, minHierarchyLevel]);

	const {
		administerProject: canManageTypes,
		assignIssues: canAssignIssues = false,
		scheduleIssues: canScheduleIssues = false,
	} = project.permissions;

	const isTMPProject = project.isSimplified;
	const projectKey = project.key;

	const options = useMemo(() => {
		return [...issueTypeOptions];
	}, [issueTypeOptions]);

	const { summaryRef, submit, blur } = useCreateIssue<TIssue>(onSubmit, onBlur);
	const [selectedType, setSelectedType] = useState<IssueTypeOption>(() => {
		if (preferredIssueTypeId) {
			const defaultIssueType = issueTypeOptions.find(
				(issueType) => issueType.value === preferredIssueTypeId,
			);
			if (defaultIssueType) {
				return defaultIssueType;
			}
		}

		// Returns the first issue type with hierarchy level of zero if there is no last used issue type
		if (minHierarchyLevel === 0) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (issueTypeOptions.find((option) => option.issueType.hierarchyLevel === 0) ??
				options[0]) as IssueTypeOption;
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return options[0] as IssueTypeOption;
	});
	const [textContent, setTextContent] = useState(summaryRef.current);
	summaryRef.current = textContent;

	const formRef = useRef<HTMLFormElement | null>(null);
	const popperRef = useRef<HTMLElement | null>(null);
	const isEmptyRef = useRef(true);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const selectRef = useRef<PopupSelect<SelectOption, false, ModifierList> | null>(null);
	isEmptyRef.current = textContent.trim().length === 0;

	const [assignee, setAssignee] = useState<UserValue>(null);
	const [dueDate, setDueDate] = useState<DueDate>(null);

	const defaultAssignee = useMemo(() => {
		if (assignee || !group) return assignee;
		if (group?.fieldId !== ASSIGNEE_TYPE || !group?.value) return assignee;
		const assigneeData = group.value;

		return getUserShape(assigneeData.accountId, assigneeData.displayName, assigneeData.avatarUrl);
	}, [assignee, group]);

	const { typesWithFields } =
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_show_additional_field_list_view_icc') && shouldShowAdditionalFields()
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useIssueTypesAndFields({
					issueOperation: 'CREATE',
				})
			: { typesWithFields: [] };
	const isDueDateFieldLinked = useMemo(
		() =>
			typesWithFields
				.find(({ issueType }) => issueType.id === selectedType.issueType.id)
				?.fields.some((field) => field.id === DUE_DATE_TYPE),
		[selectedType.issueType.id, typesWithFields],
	);

	const shouldShowDueDatePicker = canScheduleIssues && isDueDateFieldLinked;

	const handleCreateIssue = () => {
		if (!isEmptyRef.current && selectedType.optionType === ISSUE_TYPE) {
			const issueType = issueTypes.find((type) => type.id === selectedType.value);

			startExperience();

			let timeMsSinceFocus;
			// TODO: WOLVES-1917 remove timeMsSinceFocus once metric no longer needed
			if (fg('jira_list_view_inline_create_form_focus_time')) {
				setMark(FOCUS_TO_CREATE_END_MARK);
				setMeasure(
					FOCUS_TO_CREATE_MEASURE_NAME,
					FOCUS_TO_CREATE_START_MARK,
					FOCUS_TO_CREATE_END_MARK,
				);
				const marks = performance.getEntriesByName(FOCUS_TO_CREATE_MEASURE_NAME) || [];
				const currentMark = marks.length > 0 ? marks[marks.length - 1] : null;
				timeMsSinceFocus = currentMark && Math.round(currentMark.duration);

				// reuse the start marker as once a user has submitted an issue to create,
				// they can stay on the text area and enter text for the next issue to create...
				// so set the marker again.
				setMark(FOCUS_TO_CREATE_START_MARK);
			}
			const result = submit({
				input: {
					summary: textContent.trim(),
					projectId: String(project.id),
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issueType: issueType!,
					...onPrepareIssueContext?.(),
					...(shouldShowAdditionalFields() && assignee !== null
						? {
								assignee: {
									accountId: assignee.accountId ?? '',
									avatarUrl: assignee.avatarUrls['48x48'] ?? '',
									displayName: assignee.displayName,
								},
							}
						: {}),
					...(shouldShowAdditionalFields() && dueDate !== null ? { dueDate } : {}),
				},
				fieldIdsToReturn,
				location,
				showSuccessFlag,
				timeMsSinceFocus,
			});
			setTextContent('');
			setAssignee(null);
			setDueDate(null);

			result.promise
				.then(() => markExperienceSuccess())
				.catch((error) => {
					if (error instanceof CancelledError) {
						abortExperience('User cancelled');
					} else {
						markExperienceFail('Failed to create issue', error);
					}
				});
		}
	};
	const handleCreateIssueRef = useRef(handleCreateIssue);
	handleCreateIssueRef.current = handleCreateIssue;

	const handleChangeOption = useCallback(
		(option: SingleValue<IssueTypeOption>) => {
			if (option?.optionType === ISSUE_TYPE) {
				setIsPopupSelectOpen(false);
				setSelectedType(option);
				savePreferredIssueTypeId(option.value);
			}
		},
		[savePreferredIssueTypeId],
	);

	// prevent blurring from occurring when the form is first rendered
	const isReadyRef = useIsReady();

	const [isConfiguringIssueType, setIsConfiguringIssueType] = useState(false);
	const [isChangingAdditionalFields, setIsChangingAdditionalFields] = useState(false);

	const handleBlur = useCallback(
		(event: MouseEvent) => {
			if (!isReadyRef.current) {
				return;
			}

			// make sure to make a local copy of the isEmptyRef value before it gets reset by the create handler
			const formEmpty = isEmptyRef.current;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const inForm = formRef.current?.contains(event.target as Node) ?? false;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const inPopper = popperRef.current?.contains(event.target as Node) ?? false;

			if (
				!inForm &&
				!inPopper &&
				!isConfiguringIssueType &&
				!isChangingAdditionalFields &&
				shouldSubmitOnBlur
			) {
				handleCreateIssueRef.current();
				setIsPopupSelectOpen(false);
				blur();
				fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
					location,
					formEmpty,
					escapeKeyPressed: false,
				});
			}
		},
		[
			blur,
			createAnalyticsEvent,
			isChangingAdditionalFields,
			isConfiguringIssueType,
			isReadyRef,
			location,
			shouldSubmitOnBlur,
		],
	);
	const [isPopupSelectOpen, setIsPopupSelectOpen] = useState(false);
	const issueTypeButton = useCallback(
		// PopupSelectTriggerProps is not exported from @atlaskit/select
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(triggerProps: any) =>
			isVisualRefreshEnabled() ? (
				<Button
					{...triggerProps}
					appearance="subtle"
					aria-label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.selectIssueTypeButtonIssueTermRefresh
							: messages.selectIssueTypeButton,
						{
							issueType: selectedType.label,
						},
					)}
					testId="business-issue-create.ui.inline-create-form.issue-type-trigger"
					spacing="compact"
					onClick={() => setIsPopupSelectOpen(!isPopupSelectOpen)}
				>
					<Flex
						alignItems="center"
						justifyContent="center"
						gap="space.075"
						xcss={issueTypeButtonStyles}
					>
						<img
							src={selectedType.issueType.avatarUrl ?? undefined}
							alt=""
							height={16}
							width={16}
						/>
						<ChevronDownIcon />
					</Flex>
				</Button>
			) : (
				<StyledButton
					{...triggerProps}
					aria-label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.selectIssueTypeButtonIssueTermRefresh
							: messages.selectIssueTypeButton,
						{
							issueType: selectedType.label,
						},
					)}
					iconBefore={
						<img
							src={selectedType.issueType.avatarUrl ?? undefined}
							alt=""
							height={16}
							width={16}
						/>
					}
					iconAfter={
						isCompact ? (
							<ChevronDownWrapper>
								<ChevronDownIcon />
							</ChevronDownWrapper>
						) : (
							<ChevronDownIcon />
						)
					}
					spacing="compact"
					testId="business-issue-create.ui.inline-create-form.issue-type-trigger"
					onClick={() => setIsPopupSelectOpen(!isPopupSelectOpen)}
				>
					{!isCompact && selectedType.label}
				</StyledButton>
			),
		[
			formatMessage,
			isCompact,
			isPopupSelectOpen,
			selectedType.issueType.avatarUrl,
			selectedType.label,
		],
	);

	useEffect(() => {
		// on mount, we want to move the cursor to the end of the input
		textAreaRef.current?.setSelectionRange(
			textAreaRef.current.value.length,
			textAreaRef.current.value.length,
		);
		if (fg('jira_list_view_inline_create_form_focus_time')) {
			setMark(FOCUS_TO_CREATE_START_MARK);
		}
		fireUIAnalytics(createAnalyticsEvent({}), 'form focused', 'inlineCreate', { location });
	}, [createAnalyticsEvent, location]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('click', handleBlur, true);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('click', handleBlur, true);
		};
	}, [handleBlur]);

	// Refocus to the Select component after create/edit issue type modal is closed
	const refocusRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		if (refocusRef && fg('fix_refocus_issue_after_modal_close_on_list_view')) {
			return () => clearTimeout(refocusRef?.current);
		}
	}, [refocusRef]);

	const inlineIssueTypeCreateProps = useMemo(() => {
		const onCreateIssueTypeClick = () => {
			setIsConfiguringIssueType(true);
			setIsPopupSelectOpen(false);
		};

		const onCreateIssueTypeClose = () => {
			setIsConfiguringIssueType(false);
			if (fg('fix_refocus_issue_after_modal_close_on_list_view')) {
				refocusRef.current = setTimeout(() => {
					selectRef.current?.targetRef?.blur();
					selectRef.current?.targetRef?.focus();
				}, REFOCUS_DELAY_MS);
			} else {
				setTimeout(() => {
					selectRef.current?.targetRef?.blur();
					selectRef.current?.targetRef?.focus();
				}, REFOCUS_DELAY_MS);
			}
		};

		return {
			isOpen: isPopupSelectOpen,
			onKeyDown: (event: KeyboardEvent) => {
				if (event.key === 'Escape') {
					// handle PopupSelect close on escape when experiment is ON
					setIsPopupSelectOpen(false);
				}
			},
			footer: canManageTypes && (
				<SelectFooter
					onIssueTypeCreated={onIssueTypeConfigured}
					onCreateIssueTypeClick={onCreateIssueTypeClick}
					onCreateIssueTypeClose={onCreateIssueTypeClose}
					allowIssueTypeConfiguration={allowIssueTypeConfiguration && isTMPProject}
				/>
			),
			shouldCloseMenuOnTab: false,
		};
	}, [
		isPopupSelectOpen,
		canManageTypes,
		onIssueTypeConfigured,
		isTMPProject,
		allowIssueTypeConfiguration,
	]);

	const toggleIsChangingAdditionalFields = useCallback(() => {
		setIsChangingAdditionalFields(!isChangingAdditionalFields);
	}, [isChangingAdditionalFields]);

	const issueTypesSelect = useMemo(
		() => (
			<PopupSelect
				onMenuOpen={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'select opened', 'inlineCreateIssueTypes', {
						location,
					});
				}}
				components={{
					Option:
						canManageTypes &&
						isTMPProject &&
						allowIssueTypeConfiguration &&
						expVal('jira_inline_edit_issuetype_list_view', 'isEnabled', false)
							? (props) => (
									<OptionWithInlineEdit
										project={project}
										onRefetchIssueTypesWhenIssueTypeUpdated={onIssueTypeConfigured}
										onEditButtonClick={() => {
											setIsConfiguringIssueType(true);
											setIsPopupSelectOpen(false);
										}}
										onEditModalClose={() => {
											setIsConfiguringIssueType(false);
											refocusRef.current = setTimeout(() => {
												selectRef.current?.targetRef?.blur();
												selectRef.current?.targetRef?.focus();
											}, REFOCUS_DELAY_MS);
										}}
										{...props}
									>
										{props.children}
									</OptionWithInlineEdit>
								)
							: Option,
					Group,
				}}
				searchThreshold={10}
				options={options}
				onChange={handleChangeOption}
				popperProps={{ strategy: popperStrategy, innerRef: popperRef }}
				value={selectedType}
				target={({ isOpen, ...triggerProps }) =>
					isCompact || (appearance === ROW_APPEARANCE && isVisualRefreshEnabled()) ? (
						<Tooltip content={selectedType.label}>{issueTypeButton(triggerProps)}</Tooltip>
					) : (
						issueTypeButton(triggerProps)
					)
				}
				ref={selectRef}
				{...inlineIssueTypeCreateProps}
			/>
		),
		[
			appearance,
			allowIssueTypeConfiguration,
			canManageTypes,
			createAnalyticsEvent,
			handleChangeOption,
			isTMPProject,
			project,
			inlineIssueTypeCreateProps,
			isCompact,
			issueTypeButton,
			location,
			options,
			onIssueTypeConfigured,
			popperStrategy,
			selectedType,
			refocusRef,
			setIsPopupSelectOpen,
			setIsConfiguringIssueType,
		],
	);

	const getRowHeader = useCallback(() => {
		if (isVisualRefreshEnabled()) {
			return issueTypesSelect;
		}
		if (!isCompact) {
			return <IssueTypeIcon alt="" src={selectedType.issueType.avatarUrl ?? GENERIC_ICON_URL} />;
		}
		return null;
	}, [isCompact, issueTypesSelect, selectedType]);

	return (
		<FullContent
			appearance={appearance}
			borderRadius={borderRadius}
			indent={indent}
			minHeight={minHeight}
			onKeyDown={(event) => {
				event.stopPropagation();
				if (event.key === 'Escape' && !isComposing(event)) {
					blur(event);
					fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
						location,
						formEmpty: isEmptyRef.current,
						escapeKeyPressed: true,
					});
				}
			}}
			onSubmit={(event) => {
				event.preventDefault();
				handleCreateIssue();
			}}
			ref={formRef}
		>
			{appearance === ROW_APPEARANCE && getRowHeader()}
			<StyledTextArea
				appearance={appearance}
				autoFocus
				data-testid="business-issue-create.ui.inline-create-form.textarea"
				maxLength={MAX_SUMMARY_LENGTH}
				onChange={(event) => {
					const content = event.target.value.replace(/[\n\r]+/g, ' ');
					setTextContent(content);
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter' && !isComposing(event)) {
						event.preventDefault();
						handleCreateIssue();
					}
					if (onKeyDown && fg('aiwb_in_list_view_technical_changes')) {
						onKeyDown(event, textContent);
					}
				}}
				placeholder={
					placeholderOverride && fg('aiwb_in_list_view_technical_changes')
						? placeholderOverride
						: formatMessage(messages.placeholderText)
				}
				value={textContent}
				ref={textAreaRef}
			/>
			<Footer appearance={appearance}>
				{(!isVisualRefreshEnabled() || appearance !== ROW_APPEARANCE) && issueTypesSelect}
				{appearance !== ROW_APPEARANCE && isVisualRefreshEnabled() ? (
					<>
						{shouldShowAdditionalFields() && (
							<InlineCardCreateAdditionalFields
								projectKey={projectKey}
								shouldShowNameLabel={false}
								isCompactDueDatePicker
								onAssigneeUpdate={setAssignee}
								defaultAssignee={defaultAssignee}
								shouldShowDueDatePicker={shouldShowDueDatePicker}
								shouldShowAssigneePicker={canAssignIssues}
								onDateSelected={setDueDate}
								defaultDueDate={dueDate}
								onToggleAdditionalFields={toggleIsChangingAdditionalFields}
							/>
						)}
						<IconButton
							label={formatMessage(messages.createButton)}
							appearance="default"
							type="submit"
							onClick={() => {
								fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineCreateSubmit', {
									location,
									hierarchyLevel:
										selectedType.optionType === ISSUE_TYPE
											? selectedType.issueType?.hierarchyLevel
											: undefined,
								});
							}}
							isDisabled={isEmptyRef.current}
							spacing="compact"
							icon={() => (
								<Text color="inherit" weight="medium">
									⏎
								</Text>
							)}
						/>
					</>
				) : (
					<>
						{/* eslint-disable-next-line jira/ff/no-preconditioning */}
						{fg('jira_show_additional_field_list_view_icc') && shouldShowAdditionalFields() ? (
							<InlineCardCreateAdditionalFields
								projectKey={projectKey}
								shouldShowNameLabel={additionalFieldProps.shouldShowNameLabel}
								isCompactDueDatePicker={additionalFieldProps.isCompactDueDatePicker}
								onAssigneeUpdate={setAssignee}
								shouldShowDueDatePicker={shouldShowDueDatePicker}
								defaultAssignee={defaultAssignee}
								shouldShowAssigneePicker={canAssignIssues}
								onDateSelected={setDueDate}
								defaultDueDate={dueDate}
								onToggleAdditionalFields={toggleIsChangingAdditionalFields}
							/>
						) : (
							<></>
						)}
						<StyledCreateButton
							appearance="primary"
							iconAfter={<ReturnIcon />}
							isDisabled={isEmptyRef.current}
							spacing="compact"
							type="submit"
							onClick={() => {
								fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineCreateSubmit', {
									location,
									hierarchyLevel:
										selectedType.optionType === ISSUE_TYPE
											? selectedType.issueType?.hierarchyLevel
											: undefined,
								});
							}}
						>
							{!isCompactCreateButton && formatMessage(messages.createButton)}
						</StyledCreateButton>
						{fg('aiwb_in_list_view_technical_changes') ? additionalFooterContent : null}
					</>
				)}
			</Footer>
		</FullContent>
	);
};

const InlineCreateFormWithErrorBoundary = <TIssue,>(props: Props<TIssue>) => {
	const experience = useExperience(props.location);

	return (
		<ExperienceErrorBoundary experience={experience} fallback="flag">
			<InlineCreateForm<TIssue> {...props} />
		</ExperienceErrorBoundary>
	);
};

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(InlineCreateFormWithErrorBoundary);

type AppearanceProps = InlineCreateStyles & { appearance: InlineCreateAppearance };

// margin: '0px' is needed to override the default margin of the form element
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullContent = styled.form<AppearanceProps>(
	{
		display: 'flex',
		gap: token('space.100'),
		backgroundColor: token('elevation.surface'),
		transition: 'box-shadow 200ms ease-in-out',
		boxShadow: `inset 0 0 0 ${token('border.width.outline')} ${token('color.border.focused')}`,
		boxSizing: 'border-box',
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderRadius: ({ borderRadius }) => borderRadius ?? token('border.radius'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minHeight: ({ minHeight }) => minHeight ?? '40px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		flexDirection: (props) => (props.appearance === CARD_APPEARANCE ? 'column' : 'row'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		alignItems: (props) => props.appearance === ROW_APPEARANCE && 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) => (props.appearance === ROW_APPEARANCE ? '40px' : '100px'),
		margin: '0px',
		paddingTop: token('space.050'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.050'),
		paddingLeft: token('space.100'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.appearance === CARD_APPEARANCE && {
			paddingTop: token('space.150'),
			paddingRight: token('space.150'),
			paddingBottom: token('space.150'),
			paddingLeft: token('space.150'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.indent && {
			paddingLeft: props.indent,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTextArea = styled.textarea<AppearanceProps>(
	{
		flex: '1',
		border: 'none',
		outline: 'none',
		margin: 0,
		paddingTop: token('space.050'),
		paddingRight: token('space.050'),
		paddingBottom: token('space.050'),
		paddingLeft: token('space.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) => (props.appearance === CARD_APPEARANCE ? '40px' : '20px'),
		resize: 'none',
		backgroundColor: token('elevation.surface'),
		color: token('color.text'),
		font: token('font.body'),
		fontFamily: token('font.family.body'),
		'&::placeholder': {
			color: token('color.text.subtlest'),
			opacity: 1,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.appearance === ROW_APPEARANCE &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			whiteSpace: 'pre',
			scrollbarWidth: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div<AppearanceProps>({
	display: 'flex',
	gap: token('space.075'),
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (props) => (props.appearance === CARD_APPEARANCE ? 'space-between' : 'end'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(ButtonLegacy)({
	minWidth: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCreateButton = styled(ButtonLegacy)({
	flex: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionTypeIcon = styled.img({
	height: token('space.200'),
	width: token('space.200'),
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExtraLinksGroup = styled.div({
	borderTop: `2px solid ${token('color.border')}`,
	marginTop: token('space.050'),
	paddingTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeIcon = styled.img({
	height: token('space.200'),
	width: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChevronDownWrapper = styled.div({
	marginTop: 0,
	marginRight: token('space.negative.100'),
	marginBottom: 0,
	marginLeft: token('space.negative.050'),
});

const issueTypeButtonStyles = xcss({
	marginTop: 'space.025',
});

const spinnerWrapperStyles = xcss({
	flex: 1,
});
