/**
 * @generated SignedSource<<a9b26901cdcc81e83e7a97c2fe8798c2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_query">;
  readonly " $fragmentType": "BoardColumn_query";
};
export type BoardColumn_query$key = {
  readonly " $data"?: BoardColumn_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumn_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "2618c5eb4c1eed64a1964aab24e0e89b";

export default node;
