/**
 * @generated SignedSource<<a7264af18e07e8c153b54228d9d052ad>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ColumnConfettiAsync_status$data = {
  readonly name: string;
  readonly statusId: string;
  readonly " $fragmentType": "ColumnConfettiAsync_status";
};
export type ColumnConfettiAsync_status$key = {
  readonly " $data"?: ColumnConfettiAsync_status$data;
  readonly " $fragmentSpreads": FragmentRefs<"ColumnConfettiAsync_status">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ColumnConfettiAsync_status",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "statusId"
      },
      "action": "THROW",
      "path": "statusId"
    }
  ],
  "type": "JiraStatus"
};

(node as any).hash = "0162c2e8e5def2521eecbbf9600cf44a";

export default node;
