/** @jsx jsx */
import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import type { BoardMinimap_columns$key } from '@atlassian/jira-relay/src/__generated__/BoardMinimap_columns.graphql';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

const MINIMAP_MAP_HEIGHT = 36;
const MIN_SCALING_FACTOR = 14;
const MAX_MAP_WIDTH = 100;
const SHOW_MAP_RATIO = 1.1;

export type MinimapProps = {
	boardContentElement: HTMLElement | null;
	scrollElement: HTMLElement | null;
	columnsFragment: BoardMinimap_columns$key;
};

const getScalingFactor = (updatedScrollWidth: number) => {
	const maxSizeScalingFactor = updatedScrollWidth / MAX_MAP_WIDTH;
	return Math.max(MIN_SCALING_FACTOR, maxSizeScalingFactor);
};

export const BoardMinimap = memo(
	({ boardContentElement, scrollElement, columnsFragment }: MinimapProps) => {
		const columns = useFragment(
			graphql`
				fragment BoardMinimap_columns on JiraBoardViewColumnConnection {
					edges @required(action: THROW) {
						node @required(action: THROW) {
							id @required(action: THROW)
							collapsed @required(action: THROW)
						}
					}
				}
			`,
			columnsFragment,
		);

		const [scrollLeft, setScrollLeft] = useState(0);
		const [scrollWrapperWidth, setScrollWrapperWidth] = useState(scrollElement?.clientWidth ?? 0);
		const [contentWrapperWidth, setContentWrapperWidth] = useState(
			boardContentElement?.clientWidth ?? 0,
		);
		const [isDragging, setIsDragging] = useState(false);
		const [mouseStart, setMouseStart] = useState<number | null>(null);
		const [initialOffset, setInitialOffset] = useState(0);

		const innerViewRef = useRef<HTMLElement | null>(null);

		const scalingFactor = getScalingFactor(contentWrapperWidth);
		const outerWidth = contentWrapperWidth / scalingFactor;
		const innerWidth = scrollWrapperWidth / scalingFactor;
		const innerOffset = scrollLeft / scalingFactor;

		const onMouseDown = useCallback(
			(e: MouseEvent | React.MouseEvent) => {
				if (e.button === 0) {
					setIsDragging(true);
					setMouseStart(e.clientX);
					setInitialOffset(innerOffset);
				}
			},
			[innerOffset],
		);

		const onMouseUp = useCallback(() => {
			if (isDragging) {
				setIsDragging(false);
			}
		}, [isDragging]);

		const setHorizontalScroll = useCallback(
			(newOffset: number) => {
				scrollElement?.scrollTo(newOffset, scrollElement?.scrollTop);
			},
			[scrollElement],
		);

		const onScroll = useCallback(
			(newOffset: number) => {
				const newScalingFactor = getScalingFactor(contentWrapperWidth);
				setHorizontalScroll(newOffset * newScalingFactor);
			},
			[contentWrapperWidth, setHorizontalScroll],
		);

		const onMouseMove = useCallback(
			(e: MouseEvent | React.MouseEvent) => {
				if (isDragging && mouseStart !== null) {
					const mouseCurrent = e.clientX;
					onScroll(initialOffset + mouseCurrent - mouseStart);
				}
			},
			[initialOffset, isDragging, mouseStart, onScroll],
		);

		const updateInnerViewPosition = useCallback(() => {
			if (innerViewRef.current) {
				innerViewRef.current.style.marginLeft = `${innerOffset}px`;
			}
		}, [innerOffset]);

		const handleMinimapRef = useCallback(
			(ref: HTMLElement | null) => {
				innerViewRef.current = ref;
				updateInnerViewPosition();
			},
			[updateInnerViewPosition],
		);

		useEffect(() => {
			const handleScroll = () => {
				setScrollLeft(scrollElement?.scrollLeft ?? 0);
			};

			const scrollElementResizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					setScrollWrapperWidth(entry.contentRect.width);
				}
			});

			if (scrollElement) {
				scrollElementResizeObserver.observe(scrollElement);
			}

			const boardContentElementResizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					setContentWrapperWidth(entry.contentRect.width);
				}
			});

			if (boardContentElement) {
				boardContentElementResizeObserver.observe(boardContentElement);
			}

			scrollElement?.addEventListener('scroll', handleScroll);

			return () => {
				scrollElement?.removeEventListener('scroll', handleScroll);
				scrollElementResizeObserver.disconnect();
				boardContentElementResizeObserver.disconnect();
			};
		}, [scrollElement, boardContentElement]);

		useEffect(() => {
			const cleanupListeners = () => {
				if (typeof document !== 'undefined') {
					document.removeEventListener('mousemove', onMouseMove);
					document.removeEventListener('mouseup', onMouseUp);
				}
			};

			if (isDragging) {
				if (typeof document !== 'undefined') {
					document.addEventListener('mousemove', onMouseMove);
					document.addEventListener('mouseup', onMouseUp);
				}
			} else {
				cleanupListeners();
			}

			return () => {
				cleanupListeners();
			};
		}, [isDragging, onMouseMove, onMouseUp]);

		if (!(contentWrapperWidth / scrollWrapperWidth > SHOW_MAP_RATIO)) {
			return null;
		}

		return (
			<UFOSegment name="business-board-minimap">
				<JiraBottomRightCornerOutlet orderFromRight={2} id="business-board-minimap">
					<div css={styles.root}>
						<div css={styles.innerViewWrapper}>
							{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
							<div
								css={[styles.innerView, isDragging && styles.innerViewDragging]}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{ width: `${innerWidth}px` }}
								onMouseDown={onMouseDown}
								onMouseMove={onMouseMove}
								onMouseUp={onMouseUp}
								ref={handleMinimapRef}
							/>
						</div>
						<div
							css={styles.outerView}
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{ width: `${outerWidth}px` }}
						>
							{columns.edges.map(
								(edge) =>
									edge && (
										<div
											key={edge.node.id}
											css={[styles.column, edge.node.collapsed && styles.columnCollapsed]}
										/>
									),
							)}
						</div>
					</div>
				</JiraBottomRightCornerOutlet>
			</UFOSegment>
		);
	},
);

const styles = cssMap({
	root: {
		backgroundColor: token('elevation.surface.overlay'),
		borderRadius: token('border.radius.050'),
		boxShadow: token('elevation.shadow.overlay'),
		display: 'inline-flex',
		paddingTop: token('space.075'),
		paddingRight: token('space.075'),
		paddingBottom: token('space.075'),
		paddingLeft: token('space.075'),
	},
	innerViewWrapper: {
		position: 'relative',
	},
	innerView: {
		borderWidth: '2px',
		borderStyle: 'solid',
		borderColor: token('color.border.brand'),
		borderRadius: '3px',
		cursor: 'move',
		height: `${MINIMAP_MAP_HEIGHT}px`,
		left: '-1px',
		position: 'absolute',
		top: '-1px',
	},
	innerViewDragging: {
		borderColor: token('color.border.focused'),
	},
	outerView: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: token('elevation.surface.overlay'),
		borderRadius: '3px',
		display: 'flex',
		gap: token('space.025'),
		justifyContent: 'space-between',
		height: `${MINIMAP_MAP_HEIGHT}px`,
	},
	column: {
		flex: 4,
		borderRadius: '3px',
		backgroundColor: token('color.background.neutral'),
	},
	columnCollapsed: {
		flex: 1,
	},
});
