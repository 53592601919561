/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/ColumnDeleteModalQuery$parameters';
import { getUrlSettingsInput } from '../../../../../../common/url-settings-input.tsx';

type Props = {
	viewId: string;
};

export const columnDeleteModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "business-board-column-delete-modal-entrypoint" */ './ColumnDeleteModal'
			),
	),
	getPreloadProps: ({ viewId }: Props) => ({
		queries: {
			columnDeleteModalQuery: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					viewInput: {
						jiraBoardViewQueryInput: {
							viewId,
						},
						settings: getUrlSettingsInput(),
					},
				},
			},
		},
	}),
});
