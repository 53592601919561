// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

type State = { confettiColumns: Set<string> };

const actions = {
	addConfettiColumn:
		(columnId: string) =>
		({ setState, getState }: StoreActionApi<State>) => {
			const currentState = getState();
			const confettiColumns = new Set(currentState.confettiColumns);
			confettiColumns.add(columnId);
			setState({ confettiColumns });
		},
	removeConfettiColumn:
		(columnId: string) =>
		({ setState, getState }: StoreActionApi<State>) => {
			const currentState = getState();
			const confettiColumns = new Set(currentState.confettiColumns);
			confettiColumns.delete(columnId);
			setState({ confettiColumns });
		},
};

type Actions = typeof actions;

export const ConfettiColumnsProvider = createContainer();

const store = createStore<State, Actions>({
	name: 'JWMConfettiColumnsStore',
	containedBy: ConfettiColumnsProvider,
	initialState: { confettiColumns: new Set() },
	actions,
});

const useConfettiColumnsStore = createHook(store);

export const useConfettiColumnsActions = createActionsHook(store);

export const useConfettiColumns = () => {
	const [{ confettiColumns }, { removeConfettiColumn }] = useConfettiColumnsStore();
	return { confettiColumns, removeConfettiColumn };
};
