/**
 * @generated SignedSource<<7a0e0591c6bea19d695bce41aa5cbd74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_view$data = {
  readonly columns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly statuses?: ReadonlyArray<{
          readonly name: string;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_view" | "StatusColumnHeader_view">;
  readonly " $fragmentType": "BoardColumn_view";
};
export type BoardColumn_view$key = {
  readonly " $data"?: BoardColumn_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "columns"
        ]
      }
    ]
  },
  "name": "BoardColumn_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_view"
    },
    {
      "kind": "FragmentSpread",
      "name": "StatusColumnHeader_view"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "columns",
        "concreteType": "JiraBoardViewColumnConnection",
        "kind": "LinkedField",
        "name": "__business_board_columns_connection",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraBoardViewColumnEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "concreteType": "JiraStatus",
                          "kind": "LinkedField",
                          "name": "statuses",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              "action": "THROW",
                              "path": "columns.edges.node.statuses.name"
                            }
                          ]
                        }
                      ],
                      "type": "JiraBoardViewStatusColumn"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                }
              ]
            },
            "action": "THROW",
            "path": "columns.edges"
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "columns"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "a1c380fa667a8758c75127e17757f5cc";

export default node;
