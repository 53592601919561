/** @jsx jsx */
import React, { memo, type RefObject } from 'react';
import { css, jsx, cssMap } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import CollapseIcon from '@atlaskit/icon/core/migration/shrink-horizontal--editor-collapse';
import ExpandIcon from '@atlaskit/icon/core/migration/grow-horizontal--editor-expand';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { PriorityColumnHeader_column$key } from '@atlassian/jira-relay/src/__generated__/PriorityColumnHeader_column.graphql';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { Z_INDEX, EMPTY_BOARD_HEIGHT } from '../../../../common/constants.tsx';
import RestrictedIcon from '../../../../common/ui/restricted-icon/index.tsx';
import IssueCount from './header-content/issue-count/index.tsx';
import messages from './messages.tsx';

export type Props = {
	dragHandleRef?: RefObject<HTMLElement>;
	priorityColumnFragment: PriorityColumnHeader_column$key;
	issueCount: number;
	isDropDisabled: boolean;
	onToggle: () => void;
	testId?: string;
};

const PriorityColumnHeader = ({
	dragHandleRef,
	priorityColumnFragment,
	isDropDisabled,
	issueCount,
	onToggle,
	testId,
}: Props) => {
	const { formatMessage } = useIntl();

	const column = useFragment(
		graphql`
			fragment PriorityColumnHeader_column on JiraBoardViewPriorityColumn {
				collapsed @required(action: THROW)
				priority @required(action: THROW) {
					name @required(action: THROW)
					iconUrl
				}
			}
		`,
		priorityColumnFragment,
	);

	const isCollapsed = column.collapsed;
	const isCollapsedDropNotAllowed = isDropDisabled && isCollapsed;

	return (
		<div
			css={[
				containerStylesMap.root,
				isCollapsed &&
					isVisualRefreshEnabled() &&
					fg('visual-refresh-headers-buttons') &&
					containerStylesMap.collapsed,
				isCollapsed &&
					!(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')) &&
					containerStylesMap.collapsedOld,
			]}
			data-testid={testId}
		>
			<div css={[headerTitleWrapperStyles, isCollapsed && headerTitleWrapperCollapsedStyles]}>
				{isCollapsedDropNotAllowed ? (
					<RestrictedIcon />
				) : (
					<Box
						aria-hidden
						xcss={dragHandleStyles}
						ref={dragHandleRef}
						testId="work-management-board.ui.board.column.column-header.drag-handle"
					>
						⠿
					</Box>
				)}

				<div css={[headerContentWrapperStyles, isCollapsed && headerContentWrapperCollapsedStyles]}>
					{column.priority?.iconUrl && (
						<img css={imageStyles} alt="" draggable={false} src={column.priority.iconUrl} />
					)}
					<div css={[columnNameStyles, isCollapsed && columnNameVerticalStyle]}>
						{column.priority.name}
					</div>
					<IssueCount isCollapsed={isCollapsed} issueCount={issueCount} />
				</div>

				{isCollapsed ? (
					<div
						css={expandCollapseButtonWrapperStyles}
						data-component-selector="work-management-board.ui.board.column.column-header.expand-button-wrapper"
					>
						<Button
							appearance="subtle"
							iconBefore={
								<ExpandIcon
									label={formatMessage(messages.expandButton)}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							}
							onClick={onToggle}
							spacing="none"
						/>
					</div>
				) : (
					<div css={menuWrapperStyles}>
						<div
							css={expandCollapseButtonWrapperStyles}
							data-component-selector="work-management-board.ui.board.column.column-header.collapse-button-wrapper"
						>
							<Button
								appearance="subtle"
								iconBefore={
									<CollapseIcon
										label={formatMessage(messages.collapseButton)}
										LEGACY_size="medium"
										spacing="spacious"
									/>
								}
								onClick={onToggle}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(PriorityColumnHeader);

const headerTitleWrapperStyles = css({
	alignItems: 'center',
	boxSizing: 'border-box',
	color: token('color.background.neutral.bold'),
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.050'),
	justifyContent: 'space-between',
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.200'),
	width: '100%',
});

const headerTitleWrapperCollapsedStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '14px',
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
	flexDirection: 'column',
});

const expandCollapseButtonWrapperStyles = css({
	display: 'flex',
	height: 'var(--expand-collapse-button-height)',
	opacity: 'var(--expand-collapse-button-opacity)',
	width: 'var(--expand-collapse-button-width)',
});

const containerStylesMap = cssMap({
	root: {
		display: 'flex',
		position: 'relative',
		userSelect: 'none',
		'--expand-collapse-button-height': '0px',
		'--expand-collapse-button-opacity': 0,
		'--expand-collapse-button-width': '0px',
		'&:hover, &:focus-within': {
			'--expand-collapse-button-height': 'auto',
			'--expand-collapse-button-opacity': 1,
			'--expand-collapse-button-width': 'auto',
		},
		flexShrink: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.stickyHeaders,
		height: '48px',
	},
	collapsed: {
		minHeight: 0,
		flexShrink: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.collapsedStickyHeaders,
		height: 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		maxHeight: `${EMPTY_BOARD_HEIGHT}px`,
	},
	collapsedOld: {
		minHeight: 0,
		flexShrink: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: Z_INDEX.collapsedStickyHeaders,
		height: 'auto',
	},
});

const menuWrapperStyles = css({
	display: 'flex',
	flex: 'none',
});

const dragHandleStyles = xcss({
	color: 'color.text.subtle',
	cursor: 'grab',
});

const columnNameStyles = css({
	fontWeight: token('font.weight.semibold'),
	minWidth: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const columnNameVerticalStyle = css({
	writingMode: 'vertical-lr',
});

const headerContentWrapperStyles = css({
	alignItems: 'center',
	cursor: 'inherit',
	display: 'flex',
	flexDirection: 'row',
	flexGrow: 1,
	gap: token('space.100'),
	minWidth: 0,
	minHeight: 0,
	padding: '0px',
});

const headerContentWrapperCollapsedStyles = css({
	paddingTop: token('space.050'),
	paddingRight: '0px',
	paddingBottom: token('space.100'),
	paddingLeft: '0px',
	flexDirection: 'column',
});

const imageStyles = css({
	width: token('space.200'),
	height: token('space.200'),
	verticalAlign: 'text-bottom',
});
