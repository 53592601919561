/**
 * @generated SignedSource<<404e3072d4be50bf8d28b6e2562f0a68>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardFiltersProvider$data = {
  readonly filterConfig: {
    readonly jql: string;
  };
  readonly id: string;
  readonly " $fragmentType": "BoardFiltersProvider";
};
export type BoardFiltersProvider$key = {
  readonly " $data"?: BoardFiltersProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardFiltersProvider">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardFiltersProvider",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraViewFilterConfig",
        "kind": "LinkedField",
        "name": "filterConfig",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "jql"
            },
            "action": "THROW",
            "path": "filterConfig.jql"
          }
        ]
      },
      "action": "THROW",
      "path": "filterConfig"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "97474569d53798a2b7137f574cc8a349";

export default node;
