/**
 * @generated SignedSource<<9fe430c8a55e5a2bf4ac501600305518>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardMinimap_columns$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly collapsed: boolean;
      readonly id: string;
    };
  } | null | undefined>;
  readonly " $fragmentType": "BoardMinimap_columns";
};
export type BoardMinimap_columns$key = {
  readonly " $data"?: BoardMinimap_columns$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMinimap_columns">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardMinimap_columns",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraBoardViewColumnEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  "action": "THROW",
                  "path": "edges.node.id"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "collapsed"
                  },
                  "action": "THROW",
                  "path": "edges.node.collapsed"
                }
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    }
  ],
  "type": "JiraBoardViewColumnConnection"
};

(node as any).hash = "2d03302d48be438c0b6d91e3f86f983b";

export default node;
