import { useEffect, useCallback, useRef, useReducer, useMemo } from 'react';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import { useBusinessEntity } from '@atlassian/jira-business-entity-common/src/controllers/business-entity-context/index.tsx';
import { UserResolver } from '@atlassian/jira-business-fetch-users/src/services/get-users/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	SPRINT_TYPE,
	CATEGORY_TYPE,
	FIX_VERSIONS_TYPE,
	PARENT_FIELD_TYPE,
	GOALS_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { FieldConfig } from '../../common/types.tsx';
import { useFetchFieldValues } from '../../services/field-values/index.tsx';
import { useFilterFields } from '../filter-fields/index.tsx';
import { useProjectFilter } from '../filters-context/index.tsx';
import { type FetchTeamsById, useFetchTeamsById } from '../../services/fetch-teams/index.tsx';
import type { FieldConfigProps, Resolvers } from '../types.tsx';
import type { InitialFilterValueDataType, FieldConfigState } from './types.tsx';
import { getFilterFieldConfigAndResolvers } from './utils.tsx';

const EMPTY_FIELD_DATA = {
	totalCount: 0,
	options: [],
};

const EMPTY_FILTER_DATA: InitialFilterValueDataType = {
	initialUsers: EMPTY_FIELD_DATA,
	initialLabels: EMPTY_FIELD_DATA,
	initialComponents: EMPTY_FIELD_DATA,
	initialPriorities: EMPTY_FIELD_DATA,
	initialIssueTypes: EMPTY_FIELD_DATA,
	initialStatuses: EMPTY_FIELD_DATA,
	initialCategories: EMPTY_FIELD_DATA,
	initialSprints: EMPTY_FIELD_DATA,
	initialFixVersions: EMPTY_FIELD_DATA,
	initialParents: EMPTY_FIELD_DATA,
	initialGoals: EMPTY_FIELD_DATA,
	initialTeams: EMPTY_FIELD_DATA,
};

const initialState = {
	data: undefined,
	loadingData: true,
} as const;

type Action =
	| { type: 'init' }
	| { type: 'fetch-data' }
	| { type: 'fetch-data-success'; value: InitialFilterValueDataType }
	| { type: 'fetch-data-error' };

const reducer = (state: FieldConfigState, action: Action): FieldConfigState => {
	switch (action.type) {
		case 'init':
			return initialState;
		case 'fetch-data-success':
			return { ...state, data: action.value, loadingData: false };
		case 'fetch-data-error':
			return { ...state, data: EMPTY_FILTER_DATA, loadingData: false };
		default:
			return state;
	}
};

/**
 * useFieldConfig hook that is used to populate the field and field values for JWM Filters.
 * It can be used for JWM Overviews which includes multiple Jira Projects.
 */
const emptyFieldValue = { totalCount: 0, options: [] };

export const useFieldConfigV2 = ({
	view,
	allowedFieldTypes,
}: FieldConfigProps & { view?: JWMView }): {
	fieldConfig: FieldConfig;
	resolvers: Resolvers;
	loading: boolean;
} => {
	const { formatMessage } = useIntl();
	const projectFieldData = useProjectFilter();
	const { data: filterFields, loading: loadingFilterFields } = useFilterFields();
	const [{ data, loadingData }, dispatch] = useReducer(reducer, initialState);

	const userResolver = useRef(new UserResolver());
	const fetchTeamsById: FetchTeamsById = useFetchTeamsById();

	const {
		fetchLabels,
		fetchComponents,
		fetchUsers,
		fetchCategories,
		fetchInitialData,
		...fetchOptions
	} = useFetchFieldValues({
		view,
		allowedFieldTypes,
		filterFields,
	});

	const { data: entity } = useBusinessEntity();
	const businessEntityId = entity.id;

	const initialiseDefaultData = useCallback(() => {
		fetchInitialData()
			// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
			// eslint-disable-next-line complexity
			.then((initialData) => {
				dispatch({
					type: 'fetch-data-success',
					value: {
						initialUsers: initialData?.users ?? emptyFieldValue,
						initialLabels: initialData?.labels ?? emptyFieldValue,
						initialComponents: initialData?.components ?? emptyFieldValue,
						initialPriorities: initialData?.priority ?? emptyFieldValue,
						initialIssueTypes: initialData?.issuetype ?? emptyFieldValue,
						initialStatuses: initialData?.status ?? emptyFieldValue,
						initialCategories: initialData?.[CATEGORY_TYPE] ?? emptyFieldValue,
						initialSprints: initialData?.[SPRINT_TYPE] ?? emptyFieldValue,
						initialFixVersions: initialData?.[FIX_VERSIONS_TYPE] ?? emptyFieldValue,
						initialParents: initialData?.[PARENT_FIELD_TYPE] ?? emptyFieldValue,
						initialTeams: initialData?.[TEAMS_PLATFORM_CF_TYPE] ?? emptyFieldValue,
						initialGoals: initialData?.[GOALS_CF_TYPE] ?? emptyFieldValue,
					},
				});
			})
			.catch(() => {
				dispatch({
					type: 'fetch-data-error',
				});
			});
	}, [fetchInitialData]);

	useEffect(() => {
		if (!loadingFilterFields) {
			dispatch({ type: 'init' });
			initialiseDefaultData();
		}
	}, [initialiseDefaultData, loadingFilterFields, businessEntityId]);

	// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
	// eslint-disable-next-line complexity
	const { fieldConfig, resolvers } = useMemo(() => {
		const fieldOptionsData = {
			priority: data?.initialPriorities ?? emptyFieldValue,
			issuetype: data?.initialIssueTypes ?? emptyFieldValue,
			status: data?.initialStatuses ?? emptyFieldValue,
			users: data?.initialUsers ?? emptyFieldValue,
			labels: data?.initialLabels ?? emptyFieldValue,
			components: data?.initialComponents ?? emptyFieldValue,
			[CATEGORY_TYPE]: data?.initialCategories ?? emptyFieldValue,
			[SPRINT_TYPE]: data?.initialSprints ?? emptyFieldValue,
			project: projectFieldData,
			[FIX_VERSIONS_TYPE]: data?.initialFixVersions ?? emptyFieldValue,
			[PARENT_FIELD_TYPE]: data?.initialParents ?? emptyFieldValue,
			[TEAMS_PLATFORM_CF_TYPE]: data?.initialTeams ?? emptyFieldValue,
			[GOALS_CF_TYPE]: data?.initialGoals ?? emptyFieldValue,
		};

		return getFilterFieldConfigAndResolvers({
			view,
			formatMessage,
			allFields: filterFields,
			allowedFieldTypes,
			fieldOptionsData,
			fetchUsers,
			fetchLabels,
			fetchComponents,
			fetchCategories,
			fetchTeamsById,
			...fetchOptions,
			userResolver: userResolver.current,
		});
	}, [
		data?.initialPriorities,
		data?.initialIssueTypes,
		data?.initialStatuses,
		data?.initialUsers,
		data?.initialLabels,
		data?.initialComponents,
		data?.initialCategories,
		data?.initialSprints,
		data?.initialFixVersions,
		data?.initialParents,
		data?.initialGoals,
		data?.initialTeams,
		projectFieldData,
		view,
		formatMessage,
		filterFields,
		allowedFieldTypes,
		fetchUsers,
		fetchLabels,
		fetchComponents,
		fetchCategories,
		fetchOptions,
		fetchTeamsById,
	]);

	return {
		fieldConfig,
		resolvers,
		loading: loadingData || loadingFilterFields || data == null,
	};
};
