import React, { memo } from 'react';
import { styled } from '@compiled/react';
import LoadingButton from '@atlaskit/button/loading-button';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import SubtaskIcon from '@atlaskit/icon/core/migration/subtasks--subtask';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { SubtaskMetadata } from '../../../../../../../common/types.tsx';
import messages from './messages.tsx';

type Props = {
	isExpanded: boolean;
	isLoading: boolean;
	onToggle: () => void;
	subtaskMetadata: SubtaskMetadata;
};

const SubtasksExpandCollapse = ({ isExpanded, isLoading, onToggle, subtaskMetadata }: Props) => {
	const { formatMessage } = useIntl();

	const tooltipContent = formatMessage(
		isExpanded ? messages.hideSubtasksTooltip : messages.showSubtasksTooltip,
	);

	const ariaLabelContent = formatMessage(
		isExpanded ? messages.hideSubtasksAriaLabel : messages.showSubtasksAriaLabel,
		{
			done: subtaskMetadata.done,
			total: subtaskMetadata.total,
		},
	);

	return (
		<ToolTip content={tooltipContent} hideTooltipOnMouseDown>
			<LoadingButton
				isLoading={isLoading}
				onClick={onToggle}
				aria-label={ariaLabelContent}
				aria-expanded={isExpanded}
			>
				<SubtaskContainer>
					<SubtaskIcon LEGACY_size="small" label="" />
					<span>
						{subtaskMetadata.done}/{subtaskMetadata.total}
					</span>
					<SubtaskExpandCollapse expanded={isExpanded}>
						<ChevronRightIcon spacing="spacious" label="" />
					</SubtaskExpandCollapse>
				</SubtaskContainer>
			</LoadingButton>
		</ToolTip>
	);
};

export default memo(SubtasksExpandCollapse);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskExpandCollapse = styled.div<{ expanded: boolean }>({
	display: 'flex',
	alignItems: 'center',
	transition: 'transform 0.1s ease-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ expanded }) => (expanded ? 'rotate(90deg)' : 'rotate(0)'),
});
