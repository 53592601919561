import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	collapseButton: {
		id: 'work-management-board.board.column.column-header.collapse-button',
		defaultMessage: 'Collapse',
		description: 'Label for expand column button',
	},
	expandButton: {
		id: 'work-management-board.board.column.column-header.expand-button',
		defaultMessage: 'Expand',
		description: 'Label for collapse column button',
	},
	noAssignee: {
		id: 'work-management-board.board.column.column-header.no-assignee',
		defaultMessage: 'Unassigned',
		description: 'Label for board group column for items without assignee',
	},
	noCategory: {
		id: 'work-management-board.board.column.column-header.no-category',
		defaultMessage: 'Uncategorized',
		description: 'Label for board group column for items without category',
	},
});
