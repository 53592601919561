/**
 * @generated SignedSource<<05805dacdb6cc979c37fb575cf1789a9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cardCover_workManagementBoard_queryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_CardCoverInner_queryFragment">;
  readonly " $fragmentType": "cardCover_workManagementBoard_queryFragment";
};
export type cardCover_workManagementBoard_queryFragment$key = {
  readonly " $data"?: cardCover_workManagementBoard_queryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cardCover_workManagementBoard_queryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cardCover_workManagementBoard_queryFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cardCover_workManagementBoard_CardCoverInner_queryFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "d148051529bb139ea401e21c47ccf9d9";

export default node;
