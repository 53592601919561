/**
 * @generated SignedSource<<890865cff20256671600b35ef369ed61>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardDataProvider_view$data = {
  readonly selectedWorkflowId: string;
  readonly " $fragmentType": "BoardDataProvider_view";
};
export type BoardDataProvider_view$key = {
  readonly " $data"?: BoardDataProvider_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardDataProvider_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardDataProvider_view",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "selectedWorkflowId"
      },
      "action": "THROW",
      "path": "selectedWorkflowId"
    }
  ],
  "type": "JiraBoardView"
};

(node as any).hash = "b582c4f5731e80bf7608d7fd0b8771b0";

export default node;
