/** @jsx jsx */
import React, { useEffect, useMemo, useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { useRegisterNudgeTarget } from '@atlassiansox/nudge-tooltip';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import {
	ERROR_BOUNDARY_ID_BUSINESS_SAMPLE_DATA_NUDGE,
	BUSINESS_SAMPLE_DATA_NUDGE_ID,
	LIST_VIEW,
} from '../../common/constants.tsx';
import type { WrapperProps } from '../../common/types.tsx';
import { personalizedSampleDataNudgeEntryPoint } from './entrypoint.tsx';

const entryPointParams = {};

const listViewCss = css({
	width: '75%',
	height: '75%',
});

export const BusinessPersonalizedSampleDataAsync = ({ children, attributes }: WrapperProps) => {
	const runtimeProps = useMemo(() => ({ view: attributes?.view }), [attributes?.view]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		personalizedSampleDataNudgeEntryPoint,
		entryPointParams,
	);

	const firstLoadRef = useRef(true);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (firstLoadRef.current) {
			entryPointActions.load();
			firstLoadRef.current = false;
		}
	}, [entryPointActions]);

	const { ref: jwmSampleDataColumnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		BUSINESS_SAMPLE_DATA_NUDGE_ID,
		true,
	);

	return (
		<>
			<div
				css={[attributes?.view === LIST_VIEW ? listViewCss : null]}
				ref={jwmSampleDataColumnsNudgeRef}
			>
				{children}
			</div>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_BUSINESS_SAMPLE_DATA_NUDGE}
				packageName="jiraBoardOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};
